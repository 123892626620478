import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x31_9_x5F_project_x5F_documentation'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<g>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='7,22 7,5 19,5 
							'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='53,30 53,63 
				7,63 7,36 			'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='41,5 53,5 53,7 
							'
					/>

					<rect
						x='19'
						y='1'
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						width='22'
						height='10'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='13,22 13,9 
				16,9 			'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='44,9 47,9 
				47,57 13,57 13,36 			'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='18'
						y1='41'
						x2='42'
						y2='41'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='18'
						y1='45'
						x2='42'
						y2='45'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='18'
						y1='37'
						x2='42'
						y2='37'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='18'
						y1='17'
						x2='42'
						y2='17'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='18'
						y1='21'
						x2='42'
						y2='21'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='18'
						y1='49'
						x2='31'
						y2='49'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='23,4 23,7 37,7 
				37,4 			'
					/>
					<g>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M19,32c0,0.553-0.447,1-1,1H4
					c-0.553,0-1-0.447-1-1v-6c0-0.553,0.447-1,1-1h14c0.553,0,1,0.447,1,1V32z'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M39,32c0,0.553-0.447,1-1,1H24
					c-0.553,0-1-0.447-1-1v-6c0-0.553,0.447-1,1-1h14c0.553,0,1,0.447,1,1V32z'
						/>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='19'
							y1='29'
							x2='23'
							y2='29'
						/>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='3'
							y1='27'
							x2='0'
							y2='27'
						/>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='39'
							y1='27'
							x2='42'
							y2='27'
						/>
					</g>
					<g>
						<g>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='63'
								y1='53'
								x2='63'
								y2='10'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='57'
								y1='10'
								x2='57'
								y2='53'
							/>
							<rect
								x='57'
								y='5'
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								width='6'
								height='5'
							/>

							<polygon
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeLinecap: 'square',
									strokeLinejoin: 'round',
									strokeMiterlimit: 10,
								}}
								points='
						57,51 57,57 60,63 63,57 63,51 					'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='63'
								y1='37'
								x2='57'
								y2='37'
							/>
						</g>
					</g>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='53,28 53,10 57,10 			'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
