import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_5_x5F_workforce_x5F_organization'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<g>
					<g>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M8,36l-7,4c0,0,0,0.344,0,2c0,1.657,1,3,2,3h3'
						/>
						<polyline
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							points='29,36 21,40 21,40 14,36 				'
						/>
						<polyline
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							points='50,36 43,40 43,40 35,36 				'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M58,45h3c1,0,2-1.343,2-3c0-1.656,0-2,0-2l-7-4
					'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M16,30.5c0,3.5-1.963,6.5-5,6.5
					c-3.037,0-5-3-5-6.5S7,25,11,25S16,27,16,30.5z'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M37,30.5c0,3.5-1.963,6.5-5,6.5
					c-3.037,0-5-3-5-6.5s1-5.5,5-5.5S37,27,37,30.5z'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M58,30.5c0,3.5-1.963,6.5-5,6.5
					c-3.037,0-5-3-5-6.5s1-5.5,5-5.5S58,27,58,30.5z'
						/>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='21'
							y1='40'
							x2='21'
							y2='43'
						/>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='43'
							y1='40'
							x2='43'
							y2='43'
						/>
					</g>
					<g>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M37,6.5c0,3.5-1.963,6.5-5,6.5
					c-3.037,0-5-3-5-6.5S28,1,32,1S37,3,37,6.5z'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M35,12l7,4c0,0,0,0.344,0,2c0,1.657-1,3-2,3H24
					c-1,0-2-1.343-2-3c0-1.656,0-2,0-2l7-4'
						/>
					</g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='11'
						y1='20'
						x2='11'
						y2='5'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='16,10 11,5 
				6,10 			'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='53'
						y1='20'
						x2='53'
						y2='5'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='58,10 53,5 
				48,10 			'
					/>
					<g>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M56,54l7,4c0,0,0,0.344,0,2c0,1.657-1,3-2,3H3
					c-1,0-2-1.343-2-3c0-1.656,0-2,0-2l7-4'
						/>
						<polyline
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							points='35,54 43,58 43,58 50,54 				'
						/>
						<polyline
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							points='14,54 21,58 21,58 29,54 				'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M16,48.5c0,3.5-1.963,6.5-5,6.5
					c-3.037,0-5-3-5-6.5S7,43,11,43S16,45,16,48.5z'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M37,48.5c0,3.5-1.963,6.5-5,6.5
					c-3.037,0-5-3-5-6.5s1-5.5,5-5.5S37,45,37,48.5z'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M58,48.5c0,3.5-1.963,6.5-5,6.5
					c-3.037,0-5-3-5-6.5s1-5.5,5-5.5S58,45,58,48.5z'
						/>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='21'
							y1='58'
							x2='21'
							y2='61'
						/>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='43'
							y1='58'
							x2='43'
							y2='61'
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
