import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x34_2_x5F_logical_x5F_thinking'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<path
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				d='M22,1h14c11.598,0,21,9.402,21,21l6,13v2h-6v9
		c0,3.314-2.687,6-6,6h-6v12V52h-3'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='20'
				y1='1'
				x2='18'
				y2='1'
			/>
			<path
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				d='M20,12h-7v-2c1.214-0.912,2-2.364,2-4
		c0-2.761-2.238-5-5-5S5,3.239,5,6c0,1.636,0.786,3.088,2,4v2H1v10h2c0.912-1.213,2.365-2,4-2c2.762,0,5,2.239,5,5
		c0,2.762-2.238,5-5,5c-1.635,0-3.087-0.785-4-2H1v10h22V28h2c0.912,1.213,2.365,2,4,2c2.762,0,5-2.238,5-5c0-2.761-2.238-5-5-5
		c-1.635,0-3.087,0.787-4,2h-2V12h22v26h-6v2c1.214,0.914,2,2.365,2,4c0,2.762-2.238,5-5,5s-5-2.238-5-5c0-1.635,0.786-3.086,2-4v-2
		h-7'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='28'
				y1='44'
				x2='26'
				y2='44'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='24'
				y1='44'
				x2='22'
				y2='44'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='20'
				y1='44'
				x2='18'
				y2='44'
			/>
		</g>
	</svg>
)
export default IconSVG
