import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_5_x5F_brainstorming'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<g>
					<g>
						<polyline
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							points='14,54 21,58 21,58 29,54 				'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M56,54l7,4c0,0,0,0.344,0,2c0,1.657-1,3-2,3H3
					c-1,0-2-1.343-2-3c0-1.656,0-2,0-2l7-4'
						/>
						<polyline
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							points='35,54 43,58 43,58 50,54 				'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M16,48.5c0,3.5-1.963,6.5-5,6.5
					c-3.037,0-5-3-5-6.5S7,43,11,43S16,45,16,48.5z'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M37,48.5c0,3.5-1.963,6.5-5,6.5
					c-3.037,0-5-3-5-6.5s1-5.5,5-5.5S37,45,37,48.5z'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M58,48.5c0,3.5-1.963,6.5-5,6.5
					c-3.037,0-5-3-5-6.5s1-5.5,5-5.5S58,45,58,48.5z'
						/>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='21'
							y1='58'
							x2='21'
							y2='61'
						/>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='43'
							y1='58'
							x2='43'
							y2='61'
						/>
					</g>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M2,33h3l6,6l6-6h9l6,6
				l6-6h9l6,6l6-6h3c0.553,0,1-0.447,1-1V2c0-0.553-0.447-1-1-1H2C1.447,1,1,1.447,1,2v30C1,32.553,1.447,33,2,33z'
					/>
					<g>
						<polygon
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							points='35,6 32,15 39,15 39,16 31,28 29,28 
					32,19 24,19 24,18 33,6 				'
						/>
					</g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='8'
						y1='18'
						x2='10'
						y2='18'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='12'
						y1='18'
						x2='14'
						y2='18'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='16'
						y1='18'
						x2='18'
						y2='18'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='46'
						y1='18'
						x2='48'
						y2='18'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='50'
						y1='18'
						x2='52'
						y2='18'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='54'
						y1='18'
						x2='56'
						y2='18'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
