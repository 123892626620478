import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x32_7_x5F_brain_x5F_training'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<g>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M17.021,13
				C20.392,5.904,27.623,1,36,1c11.598,0,21,9.402,21,21l6,13v2h-6v9c0,3.313-2.687,6-6,6h-6v12'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M23,64V52
				c0-6.902-3.811-13.043-6.172-19.997'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='45'
						y1='52'
						x2='42'
						y2='52'
					/>
				</g>
				<g>
					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='29'
						y1='25'
						x2='13'
						y2='25'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='13'
						y1='19'
						x2='29'
						y2='19'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M33,15h3
				c0.553,0,1,0.447,1,1v12c0,0.553-0.447,1-1,1h-3'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M41,24
				c0,0.553-0.447,1-1,1h-2c-0.553,0-1-0.447-1-1v-4c0-0.553,0.447-1,1-1h2c0.553,0,1,0.447,1,1V24z'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M33,32
				c0,0.553-0.447,1-1,1h-2c-0.553,0-1-0.447-1-1V12c0-0.553,0.447-1,1-1h2c0.553,0,1,0.447,1,1V32z'
					/>
					<g>
						<path
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							d='M9,15H6
					c-0.553,0-1,0.447-1,1v12c0,0.553,0.447,1,1,1h3'
						/>
						<path
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							d='M1,24
					c0,0.553,0.447,1,1,1h2c0.553,0,1-0.447,1-1v-4c0-0.553-0.447-1-1-1H2c-0.553,0-1,0.447-1,1V24z'
						/>
						<path
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							d='M9,32
					c0,0.553,0.447,1,1,1h2c0.553,0,1-0.447,1-1V12c0-0.553-0.447-1-1-1h-2c-0.553,0-1,0.447-1,1V32z'
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
