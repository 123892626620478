import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_4_x5F_production_x5F_wheel'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='63.891px'
		viewBox='0 0 64 63.891'
		style={{ enableBackground: 'new 0 0 64 63.981' }}
	>
		<g>
			<g>
				<g>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M62.858,34.874
				c0.09-0.965,0.142-1.94,0.142-2.929c0-0.988-0.051-1.964-0.142-2.929l-4.147-1.005c-0.074-0.508-0.159-1.012-0.26-1.51
				l3.556-2.364c-0.496-1.911-1.171-3.75-2.004-5.499l-4.243,0.475c-0.244-0.451-0.502-0.891-0.77-1.325l2.531-3.435
				c-1.114-1.612-2.375-3.115-3.766-4.486l-3.825,1.898c-0.38-0.338-0.771-0.664-1.169-0.98l1.204-4.096
				c-1.586-1.131-3.282-2.114-5.07-2.934l-2.945,3.09c-0.473-0.188-0.952-0.361-1.438-0.523l-0.269-4.26
				c-1.859-0.513-3.789-0.854-5.771-1.01l-1.71,3.912c-0.254-0.008-0.506-0.02-0.762-0.02c-0.255,0-0.508,0.012-0.762,0.02
				l-1.711-3.912c-1.982,0.156-3.911,0.497-5.771,1.01l-0.269,4.26c-0.486,0.162-0.965,0.336-1.438,0.523l-2.945-3.09
				c-1.789,0.819-3.484,1.803-5.07,2.934l1.204,4.096c-0.398,0.316-0.789,0.643-1.169,0.98l-3.825-1.898
				c-1.392,1.371-2.652,2.874-3.766,4.486l2.531,3.435c-0.268,0.434-0.526,0.874-0.77,1.325l-4.243-0.475
				c-0.833,1.749-1.508,3.588-2.004,5.499l3.556,2.364c-0.102,0.498-0.187,1.002-0.26,1.51l-4.147,1.005
				C1.051,29.982,1,30.957,1,31.945c0,0.988,0.051,1.964,0.142,2.929l4.147,1.005c0.074,0.508,0.159,1.012,0.26,1.51l-3.556,2.364
				c0.496,1.911,1.171,3.75,2.004,5.499l4.243-0.475c0.244,0.451,0.502,0.891,0.77,1.325l-2.531,3.435
				c1.114,1.612,2.374,3.115,3.766,4.486l3.825-1.898c0.38,0.338,0.771,0.664,1.169,0.981l-1.204,4.096
				c1.586,1.131,3.282,2.114,5.07,2.934l2.945-3.09c0.473,0.188,0.952,0.361,1.438,0.523l0.269,4.26
				c1.859,0.513,3.789,0.854,5.771,1.01l1.711-3.912c0.254,0.008,0.506,0.019,0.762,0.019c0.255,0,0.508-0.012,0.762-0.019
				l1.71,3.912c1.982-0.156,3.911-0.497,5.771-1.01l0.269-4.26c0.486-0.162,0.965-0.336,1.438-0.523l2.945,3.09
				c1.789-0.819,3.484-1.803,5.07-2.934l-1.204-4.096c0.398-0.317,0.79-0.643,1.169-0.981l3.825,1.898
				c1.392-1.371,2.652-2.874,3.766-4.486l-2.531-3.435c0.268-0.434,0.526-0.874,0.77-1.325l4.243,0.475
				c0.833-1.749,1.508-3.588,2.004-5.499l-3.556-2.364c0.102-0.498,0.187-1.002,0.26-1.51L62.858,34.874z'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M11,31.945c0-11.598,9.402-21,21-21'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M53,31.945c0,11.598-9.402,21-21,21'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M46.869,33.828
				C46.947,33.21,47,32.584,47,31.945c0-0.639-0.053-1.265-0.131-1.883l-3.219-0.948c-0.144-0.596-0.325-1.177-0.555-1.734
				l2.048-2.661c-0.61-1.107-1.364-2.121-2.224-3.035l-3.154,1.122c-0.461-0.393-0.955-0.745-1.473-1.065l0.093-3.353
				c-1.125-0.531-2.321-0.933-3.578-1.171l-1.9,2.774c-0.3-0.023-0.6-0.046-0.907-0.046c-0.306,0-0.606,0.023-0.907,0.046
				l-1.9-2.774c-1.257,0.238-2.453,0.64-3.578,1.171l0.093,3.353c-0.517,0.319-1.011,0.672-1.473,1.065l-3.154-1.122
				c-0.86,0.914-1.613,1.928-2.224,3.035l2.047,2.66c-0.229,0.559-0.41,1.139-0.554,1.734l-3.219,0.949
				C17.053,30.681,17,31.307,17,31.945c0,0.639,0.053,1.265,0.131,1.883l3.219,0.949c0.144,0.596,0.325,1.176,0.554,1.734
				l-2.047,2.66c0.61,1.107,1.364,2.121,2.224,3.035l3.154-1.122c0.461,0.393,0.956,0.745,1.473,1.064l-0.093,3.354
				c1.125,0.531,2.321,0.933,3.578,1.171l1.9-2.774c0.3,0.022,0.601,0.046,0.907,0.046c0.306,0,0.606-0.023,0.907-0.046l1.9,2.774
				c1.257-0.238,2.453-0.64,3.578-1.171l-0.093-3.354c0.517-0.319,1.011-0.672,1.473-1.064l3.154,1.122
				c0.86-0.914,1.614-1.928,2.224-3.035l-2.048-2.661c0.229-0.558,0.41-1.139,0.555-1.734L46.869,33.828z'
					/>
					<circle
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						cx='32'
						cy='31.945'
						r='6'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
