import React from 'react'

const StarIcon = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='23' height='23' viewBox='0 0 23 23'>
			<path
				id='Polygon_1'
				dataname='Polygon 1'
				d='M11.5,0l3.45,7.721L23,8.785l-5.918,5.836L18.607,23,11.5,18.886,4.393,23l1.525-8.379L0,8.785,8.05,7.721Z'
				fill='currentColor'
			/>
		</svg>
	)
}
StarIcon.propTypes = {}
export default StarIcon
