import React from 'react'

const IconSVG = props => {
	return (
		<svg viewBox='0 0 64 56' {...props}>
			<g>
				<g>
					<g>
						<g>
							<g>
								<path fill='currentColor' d='M22 14H42V16H22z' />
								<path
									fill='currentColor'
									d='M48 39a1 1 0 01-.71-.29 1 1 0 010-1.42l3.71-3.7v-2l3.29-3.3a1 1 0 011.42 1.42L53 32.41v2l-4.29 4.3A1 1 0 0148 39zM44 51a4.46 4.46 0 01-2.71-1.29l-11-11a1 1 0 011.42-1.42l11 11A2.45 2.45 0 0044 49a2 2 0 002-2 2.51 2.51 0 00-.71-1.3l-11-11a1 1 0 011.42-1.42l11 11A4.46 4.46 0 0148 47a4 4 0 01-4 4z'
								/>
								<path
									fill='currentColor'
									d='M39 54a4.46 4.46 0 01-2.71-1.29l-10-10a1 1 0 011.42-1.42l10 10A2.45 2.45 0 0039 52a2 2 0 002-2 2.51 2.51 0 00-.71-1.3 1 1 0 011.42-1.41A4.46 4.46 0 0143 50a4 4 0 01-4 4z'
								/>
								<path
									fill='currentColor'
									d='M33 56a4.46 4.46 0 01-2.71-1.29L12 36.41v-3l-3.71-3.7a1 1 0 011.42-1.42l4.29 4.3v3l17.71 17.7A2.45 2.45 0 0033 54a2 2 0 002-2 2.51 2.51 0 00-.71-1.3 1 1 0 011.42-1.41A4.46 4.46 0 0137 52a4 4 0 01-4 4z'
								/>
								<path
									fill='currentColor'
									d='M16 44a4 4 0 01-4-4 4.46 4.46 0 011.29-2.71 1 1 0 011.42 1.42A2.45 2.45 0 0014 40a2 2 0 002 2 2.51 2.51 0 001.3-.71 1 1 0 011.41 1.42A4.46 4.46 0 0116 44z'
								/>
								<path
									fill='currentColor'
									d='M20 48a4 4 0 01-4-4 4.46 4.46 0 011.29-2.71 1 1 0 011.42 1.42A2.45 2.45 0 0018 44a2 2 0 002 2 2.51 2.51 0 001.3-.71 1 1 0 011.41 1.42A4.46 4.46 0 0120 48z'
								/>
								<path
									fill='currentColor'
									d='M24 52a4 4 0 01-4-4 4.46 4.46 0 011.29-2.71 1 1 0 011.42 1.42A2.45 2.45 0 0022 48a2 2 0 002 2 2.51 2.51 0 001.3-.71 1 1 0 011.41 1.42A4.46 4.46 0 0124 52z'
								/>
								<path
									fill='currentColor'
									d='M28 56a4 4 0 01-4-4 4.46 4.46 0 011.29-2.71 1 1 0 011.42 1.42A2.45 2.45 0 0026 52a2 2 0 002 2 2.51 2.51 0 001.3-.71 1 1 0 011.41 1.42A4.46 4.46 0 0128 56zM46 45a4.46 4.46 0 01-2.71-1.29 1 1 0 011.42-1.42A2.45 2.45 0 0046 43a2 2 0 002-2 2.51 2.51 0 00-.71-1.3L34 26.41V21h-1.59l-4.7 4.71A4.46 4.46 0 0125 27a4 4 0 01-4-4 4.46 4.46 0 011.29-2.71l6-6a1 1 0 011.42 1.42l-6 6A2.45 2.45 0 0023 23a2 2 0 002 2 2.51 2.51 0 001.3-.71L31.59 19H36v6.59l12.71 12.7A4.46 4.46 0 0150 41a4 4 0 01-4 4z'
								/>
								<path
									fill='currentColor'
									d='M40 29h-3v-2h3a2 2 0 002-2h2a4 4 0 01-4 4zM58 30h-2a1 1 0 01-.71-.29l-16-16A1 1 0 0139 13v-2a1 1 0 01.29-.71l10-10a1 1 0 111.42 1.42L41 11.41v1.18L56.41 28h1.18l4.7-4.71a1 1 0 011.42 1.42l-5 5A1 1 0 0158 30zM8 30H6a1 1 0 01-.71-.29l-5-5a1 1 0 011.42-1.42L6.41 28h1.18L23 12.59v-1.18l-9.71-9.7A1 1 0 0114.71.29l10 10A1 1 0 0125 11v2a1 1 0 01-.29.71l-16 16A1 1 0 018 30z'
								/>
								<path
									fill='currentColor'
									d='M57 23a1 1 0 01-.71-.29 1 1 0 010-1.42l2-2a1 1 0 011.42 1.42l-2 2A1 1 0 0157 23zM7 23a1 1 0 01-.71-.29l-2-2a1 1 0 011.42-1.42l2 2a1 1 0 010 1.42A1 1 0 017 23z'
								/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}
IconSVG.propTypes = {}
export default IconSVG
