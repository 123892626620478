import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_3_x5F_planning_x5F_clipboard'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='60px'
		height='64px'
		viewBox='0 0 60 64'
		style={{ enableBackground: '0 0 60 64' }}
	>
		<g>
			<g>
				<g>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='34,5 45,5 
				45,63 1,63 1,5 12,5 			'
					/>

					<rect
						x='12'
						y='1'
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						width='22'
						height='10'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='37,9 40,9 
				40,53 6,53 6,9 9,9 			'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='30,4 30,7 16,7 
				16,4 			'
					/>
				</g>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='35'
					y1='17'
					x2='25'
					y2='17'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='35'
					y1='22'
					x2='11'
					y2='22'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='35'
					y1='26'
					x2='11'
					y2='26'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='35'
					y1='30'
					x2='11'
					y2='30'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='35'
					y1='34'
					x2='11'
					y2='34'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='35'
					y1='38'
					x2='11'
					y2='38'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='25'
					y1='42'
					x2='11'
					y2='42'
				/>
				<g>
					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='51'
						y1='35'
						x2='59'
						y2='35'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='51'
						y1='55'
						x2='59'
						y2='55'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='55'
						y1='40'
						x2='55'
						y2='42'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='55'
						y1='44'
						x2='55'
						y2='46'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='55'
						y1='48'
						x2='55'
						y2='50'
					/>
					<polygon
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='51,5 59,5 59,55 
				55,63 51,55 			'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='53,5 53,1 57,1 
				57,5 			'
					/>
				</g>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='1'
					y1='59'
					x2='45'
					y2='59'
				/>
			</g>
		</g>
	</svg>
)
export default IconSVG
