import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x32_7_x5F_student_x5F_notes'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='60px'
		height='64px'
		viewBox='0 0 60 64'
		style={{ enableBackground: '0 0 60 64' }}
	>
		<g>
			<g>
				<g>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='43,18 43,63 
				5,63 5,11 10,11 			'
					/>
					<g>
						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='1'
							y1='8'
							x2='1'
							y2='52'
						/>
						<path
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							d='M8,1h32
					c3.866,0,7,3.134,7,7s-3.134,7-7,7H9c0,0,6-1,6-7C15,4.134,11.866,1,8,1C4.134,1,1,4.134,1,8'
						/>
					</g>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='10'
						y1='27'
						x2='38'
						y2='27'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='10'
						y1='31'
						x2='38'
						y2='31'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='10'
						y1='35'
						x2='38'
						y2='35'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='10'
						y1='39'
						x2='38'
						y2='39'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='10'
						y1='43'
						x2='38'
						y2='43'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='10'
						y1='47'
						x2='38'
						y2='47'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='10'
						y1='51'
						x2='38'
						y2='51'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='23'
						y1='21'
						x2='25'
						y2='21'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='27'
						y1='21'
						x2='29'
						y2='21'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='19'
						y1='21'
						x2='21'
						y2='21'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='38'
						y1='57'
						x2='28'
						y2='57'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='9'
						y1='15'
						x2='5'
						y2='15'
					/>
				</g>
				<g>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M59,51h-8
				c-1.105,0-2-0.896-2-2V32'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='53'
						y1='13'
						x2='59'
						y2='13'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='53'
						y1='55'
						x2='59'
						y2='55'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M59,60
				c0,1.656-1.344,3-3,3c-1.656,0-3-1.344-3-3V6l3-5l3,5V60z'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
