import Facebook from './icon-svgs/Facebook.icon'
import Instagram from './icon-svgs/Instagram.icon'
import Linkedin from './icon-svgs/Linkedin.icon'
import Twitter from './icon-svgs/Twitter.icon'

import Arrow from './icon-svgs/Arrow.right.icon'
import Chevron from './icon-svgs/Chevron.right.icon'
import People from './icon-svgs/People.icon'
import Hamburger from './icon-svgs/Hamburger.icon'
import XIcon from './icon-svgs/x.icon'
import Search from './icon-svgs/Search.icon'
import Star from './icon-svgs/Star.icon'
import Profile from './icon-svgs/ProfileLogin.icon'

// 1ST GROUP ICONS
import Appreciations from './icon-svgs/01_appreciations'
import BusinessCase from './icon-svgs/01_business_case'
import CompanyMeeting from './icon-svgs/01_company_meeting'
import FinancialData from './icon-svgs/01_financial_data'
import IdeaGeneration from './icon-svgs/01_idea_generation'
import LegalDocuments from './icon-svgs/01_legal_documents'
import MedalAward from './icon-svgs/01_medal_award'
import ProjectInbox from './icon-svgs/01_project_inbox'
import QualifiedCandidates from './icon-svgs/01_qualified_candidates'

// 2ND GROUP ICONS
import FeaturesList from './icon-svgs/02_features_list'
import FinancialReport from './icon-svgs/02_financial_report'
import FocusGroup from './icon-svgs/02_focus_group'
import GoalTarget from './icon-svgs/02_goal_target'
import InnovationResearch from './icon-svgs/02_innovation_research'
import StartupLaunch from './icon-svgs/02_startup_launch'
import VentureCapital from './icon-svgs/02_venture_capital'
import WorkflowPlanning from './icon-svgs/02_workflow_planning'

// 3RD GROUP ICONS
import AccountsStorage from './icon-svgs/03_accounts_storage'
import BusinessIdea from './icon-svgs/03_business_idea'
import BusinessNetwork from './icon-svgs/03_business_network'
import BusinessTravel from './icon-svgs/03_business_travel'
import DiagramChart from './icon-svgs/03_diagram_chart'
import EmailLetter from './icon-svgs/03_email_letter'
import OfficeBuilding from './icon-svgs/03_office_building'
import OnlineConference from './icon-svgs/03_online_conference'
import PlanningClipboard from './icon-svgs/03_planning_clipboard'
import ThumbsUp from './icon-svgs/03_thumbs_up'

// 4TH GROUP ICONS
import BlankPaperWrite from './icon-svgs/04_blank_paper_write'
import BusinessPortfolio from './icon-svgs/04_business_portfolio'
import EmptyFolder from './icon-svgs/04_empty_folder'
import GlobalBusiness from './icon-svgs/04_global_business'
import OfficeSupplies from './icon-svgs/04_office_supplies'
import ProductionWheel from './icon-svgs/04_production_wheel'
import SpeechBubble from './icon-svgs/04_speech_bubble'
import StickyNotes from './icon-svgs/04_sticky_notes'
import SupportHeadset from './icon-svgs/04_support_headset'

// 5TH GROUP ICONS
import BrainStorming from './icon-svgs/05_brainstorming'
import CareerAdvancement from './icon-svgs/05_career_advancement'
import HeadHunting from './icon-svgs/05_head_hunting'
import LeadershipGrowth from './icon-svgs/05_leadership_growth'
import OnlineConsulting from './icon-svgs/05_online_consulting'
import TeamEfforts from './icon-svgs/05_team_efforts'
import TrainingSeminar from './icon-svgs/05_training_seminar'
import WorkforceOrganization from './icon-svgs/05_workforce_organization'
import WorkgroupPeople from './icon-svgs/05_workgroup_people'

// 6TH GROUP ICONS
import BusinessGraph from './icon-svgs/06_business_graph'
import CompanyStructure from './icon-svgs/06_company_structure'
import ComputerDesk from './icon-svgs/06_computer_desk'
import GlobalManagement from './icon-svgs/06_global_management'
import OrganizationChart from './icon-svgs/06_organization_chart'
import ProfessionalSpeaker from './icon-svgs/06_professional_speaker'
import UserProfile from './icon-svgs/06_user_profile'
import WorkingHours from './icon-svgs/06_working_hours'
import CVResume from './icon-svgs/06_cv_resume'

// 9TH GROUP ICONS
import HandshakeAgreement from './icon-svgs/09_handshake_agreement'
import TeamworkCollaboration from './icon-svgs/09_teamwork_collaboration'

// 15TH GROUP ICONS
import BigDataAnalysis from './icon-svgs/15_big_data_analysis'
import CloudDatabase from './icon-svgs/15_cloud_database'
import CloudHosting from './icon-svgs/15_cloud_hosting'
import CloudManagement from './icon-svgs/15_cloud_management'
import CloudSearch from './icon-svgs/15_cloud_search'
import CloudSecurity from './icon-svgs/15_cloud_security'
import ComputerHardware from './icon-svgs/15_computer_hardware'
import DownloadFolder from './icon-svgs/15_download_folder'
import GlobalConnection from './icon-svgs/15_global_connection'
import NetworkConnection from './icon-svgs/15_network_connection'
import NetworkWorkflow from './icon-svgs/15_network_workflow'
import P2PConnection from './icon-svgs/15_p2p_connection'
import TouchscreenTechnology from './icon-svgs/15_touchscreen_technology'

// 16TH GROUP ICONS
import CloudAccess from './icon-svgs/16_cloud_access'
import CloudComputing from './icon-svgs/16_cloud_computing'
import CloudFileAccess from './icon-svgs/16_cloud_file_access'
import DesktopComputer from './icon-svgs/16_desktop_computer'
import GlobalNetwork from './icon-svgs/16_global_network'
import LanNetwork from './icon-svgs/16_lan_network'
import NetworkDatabase from './icon-svgs/16_network_database'
import NetworkFolder from './icon-svgs/16_network_folder'
import RackmountServer from './icon-svgs/16_rackmount_server'
import SecurityLock from './icon-svgs/16_security_lock'
import SystemMonitoring from './icon-svgs/16_system_monitoring'
import TechnicalTools from './icon-svgs/16_technical_tools'

// 17TH GROUP ICONS
import ChattingOnProject from './icon-svgs/17_chatting_on_project'

import MobileAppsDevelopment from './icon-svgs/19_mobile_apps_development'
import MobileDataSynchronization from './icon-svgs/19_mobile_data_synchronization'
import ProjectDocumentation from './icon-svgs/19_project_documentation'
import SecureLogin from './icon-svgs/19_secure_login'
import TestingChecklist from './icon-svgs/19_testing_checklist'
import UserInteraction from './icon-svgs/19_user_interaction'
import WebAnalytics from './icon-svgs/19_web_analytics'

// 20TH GROUP ICONS
import ComputerMontitor from './icon-svgs/20_computer_monitor'
import LaptopComputer from './icon-svgs/20_laptop_computer'
import SmartPhone from './icon-svgs/20_smart_phone'

// 27TH GROUP ICONS
import BrainTraining from './icon-svgs/27_brain_training'
import StudentNotes from './icon-svgs/27_student_notes'
import MasterYourSkills from './icon-svgs/27_master_your_skill'

// 28TH GROUP ICONS
import CertificateDegree from './icon-svgs/28_certificate_degree'
import GeographyGlobe from './icon-svgs/28_geography_globe'
import OnlineEducation from './icon-svgs/28_online_education'
import OnlineLibrary from './icon-svgs/28_online_library'
import OnlineTutorials from './icon-svgs/28_online_tutorials'

// 29TH GROUP ICONS
import InnerDialog from './icon-svgs/29_inner_dialog'
import StrategyThinking from './icon-svgs/29_strategy_thinking'

// 31ST GROUP ICONS
import EmployerAvatar from './icon-svgs/31_employer_avatar'
import GraduateScholarAvatar from './icon-svgs/31_graduate_scholar_avatar'
import OperatorAvatar from './icon-svgs/31_operator_avatar'
import SalesmanAvatar from './icon-svgs/31_salesman_avatar'

// 35TH GROUP ICONS
import DataScience from './icon-svgs/35_data_science'

// 36TH GROUP ICONS
import ComputerSecurity from './icon-svgs/36_computer_security'
import CyberSecurity from './icon-svgs/36_cyber_security'
import FingerprintScanning from './icon-svgs/36_fingerprint_scanning'
import GlobalProtection from './icon-svgs/36_global_protection'
import MobileSecurity from './icon-svgs/36_mobile_security'
import NetworkSecureVault from './icon-svgs/36_network_secure_vault'
import SecureDataFolder from './icon-svgs/36_secure_data_folder'
import SecurePayment from './icon-svgs/36_secure_payment'
import Security_access from './icon-svgs/36_security_access'
import SecurityPadlock from './icon-svgs/36_security_padlock'

// 39TH GROUP ICONS
import Personalization from './icon-svgs/39_personalization'

// 40TH GROUP ICONS
import IntelligentPersonalAssistant from './icon-svgs/40_intelligent_personal_assistant'

// 42ND GROUP ICONS
import DisputeAndArguments from './icon-svgs/42_dispute_and_arguments'
import LogicalThinking from './icon-svgs/42_logical_thinking'

// 44TH GROUP ICONS
import EscapeYourComfortZone from './icon-svgs/44_escape_your_comfort_zone'

// 45TH GROUP ICONS
import GlobalConnections from './icon-svgs/45_global_connections'
import GlobalServices from './icon-svgs/45_global_services'
import InternationalBusiness from './icon-svgs/45_international_business'

// 46TH GROUP ICONS
import DelegatingTasks from './icon-svgs/46_delegating_tasks'
import LaunchOptimization from './icon-svgs/46_launch_optimization'
import QualityControlChecklist from './icon-svgs/46_quality_control_checklist'

// 51ST GROUP ICONS
import DriversInstallation from './icon-svgs/51_drivers_installation'
import HardwareSecurity from './icon-svgs/51_hardware_security'

// 55TH GROUP ICONS
import BitcoinsTechnology from './icon-svgs/55_bitcoins_technology'
import EbankingSecurity from './icon-svgs/55_ebanking_security'
import ElectronicSignature from './icon-svgs/55_electronic_signature'
import FinancialDataAnalysis from './icon-svgs/55_financial_data_analytics'
import FintechIndustry from './icon-svgs/55_fintech_industry'
import FraudDetection from './icon-svgs/55_fraud_detection'
import PersonalDataProtection from './icon-svgs/55_personal_data_protection'

import CaretDownSVG from './icon-svgs/caret-down'

export default {
	'01_appreciations': Appreciations,
	'01_business_case': BusinessCase,
	'01_company_meeting': CompanyMeeting,
	'01_financial_data': FinancialData,
	'01_idea_generation': IdeaGeneration,
	'01_legal_documents': LegalDocuments,
	'01_medal_award': MedalAward,
	'01_project_inbox': ProjectInbox,
	'01_qualified_candidates': QualifiedCandidates,
	'02_features_list': FeaturesList,
	'02_financial_report': FinancialReport,
	'02_focus_group': FocusGroup,
	'02_goal_target': GoalTarget,
	'02_innovation_research': InnovationResearch,
	'02_startup_launch': StartupLaunch,
	'02_venture_capital': VentureCapital,
	'02_workflow_planning': WorkflowPlanning,
	'03_accounts_storage': AccountsStorage,
	'03_business_idea': BusinessIdea,
	'03_business_network': BusinessNetwork,
	'03_business_travel': BusinessTravel,
	'03_diagram_chart': DiagramChart,
	'03_email_letter': EmailLetter,
	'03_office_building': OfficeBuilding,
	'03_online_conference': OnlineConference,
	'03_planning_clipboard': PlanningClipboard,
	'03_thumbs_up': ThumbsUp,
	'04_blank_paper_write': BlankPaperWrite,
	'04_business_portfolio': BusinessPortfolio,
	'04_empty_folder': EmptyFolder,
	'04_global_business': GlobalBusiness,
	'04_office_supplies': OfficeSupplies,
	'04_production_wheel': ProductionWheel,
	'04_speech_bubble': SpeechBubble,
	'04_sticky_notes': StickyNotes,
	'04_support_headset': SupportHeadset,
	'05_brainstorming': BrainStorming,
	'05_career_advancement': CareerAdvancement,
	'05_head_hunting': HeadHunting,
	'05_leadership_growth': LeadershipGrowth,
	'05_online_consulting': OnlineConsulting,
	'05_team_efforts': TeamEfforts,
	'05_training_seminar': TrainingSeminar,
	'05_workforce_organization': WorkforceOrganization,
	'05_workgroup_people': WorkgroupPeople,
	'06_business_graph': BusinessGraph,
	'06_company_structure': CompanyStructure,
	'06_computer_desk': ComputerDesk,
	'06_global_management': GlobalManagement,
	'06_organization_chart': OrganizationChart,
	'06_professional_speaker': ProfessionalSpeaker,
	'06_user_profile': UserProfile,
	'06_working_hours': WorkingHours,
	'06_cv_resume': CVResume,
	'09_handshake_agreement': HandshakeAgreement,
	'09_teamwork_collaboration': TeamworkCollaboration,
	'15_big_data_analysis': BigDataAnalysis,
	'15_cloud_database': CloudDatabase,
	'15_cloud_hosting': CloudHosting,
	'15_cloud_management': CloudManagement,
	'15_cloud_search': CloudSearch,
	'15_cloud_security': CloudSecurity,
	'15_computer_hardware': ComputerHardware,
	'15_download_folder': DownloadFolder,
	'15_global_connection': GlobalConnection,
	'15_network_connection': NetworkConnection,
	'15_network_workflow': NetworkWorkflow,
	'15_p2p_connection': P2PConnection,
	'15_touchscreen_technology': TouchscreenTechnology,
	'16_cloud_access': CloudAccess,
	'16_cloud_computing': CloudComputing,
	'16_cloud_file_access': CloudFileAccess,
	'16_desktop_computer': DesktopComputer,
	'16_global_network': GlobalNetwork,
	'16_lan_network': LanNetwork,
	'16_network_database': NetworkDatabase,
	'16_network_folder': NetworkFolder,
	'16_rackmount_server': RackmountServer,
	'16_security_lock': SecurityLock,
	'16_system_monitoring': SystemMonitoring,
	'16_technical_tools': TechnicalTools,
	'17_chatting_on_project': ChattingOnProject,
	'19_mobile_apps_development': MobileAppsDevelopment,
	'19_mobile_data_synchronization': MobileDataSynchronization,
	'19_project_documentation': ProjectDocumentation,
	'19_secure_login': SecureLogin,
	'19_testing_checklist': TestingChecklist,
	'19_user_interaction': UserInteraction,
	'19_web_analytics': WebAnalytics,
	'20_computer_monitor': ComputerMontitor,
	'20_laptop_computer': LaptopComputer,
	'20_smart_phone': SmartPhone,
	'27_brain_training': BrainTraining,
	'27_student_notes': StudentNotes,
	'27_master_your_skill': MasterYourSkills,
	'28_certificate_degree': CertificateDegree,
	'28_geography_globe': GeographyGlobe,
	'28_online_education': OnlineEducation,
	'28_online_library': OnlineLibrary,
	'28_online_tutorials': OnlineTutorials,
	'29_inner_dialog': InnerDialog,
	'29_strategy_thinking': StrategyThinking,
	'31_employer_avatar': EmployerAvatar,
	'31_graduate_scholar_avatar': GraduateScholarAvatar,
	'31_operator_avatar': OperatorAvatar,
	'31_salesman_avatar': SalesmanAvatar,
	'35_data_science': DataScience,
	'36_computer_security': ComputerSecurity,
	'36_cyber_security': CyberSecurity,
	'36_fingerprint_scanning': FingerprintScanning,
	'36_global_protection': GlobalProtection,
	'36_mobile_security': MobileSecurity,
	'36_network_secure_vault': NetworkSecureVault,
	'36_secure_data_folder': SecureDataFolder,
	'36_secure_payment': SecurePayment,
	'36_security_access': Security_access,
	'36_security_padlock': SecurityPadlock,
	'39_personalization': Personalization,
	'40_intelligent_personal_assistant': IntelligentPersonalAssistant,
	'42_dispute_and_arguments': DisputeAndArguments,
	'42_logical_thinking': LogicalThinking,
	'44_escape_your_comfort_zone': EscapeYourComfortZone,
	'45_global_connections': GlobalConnections,
	'45_global_services': GlobalServices,
	'45_international_business': InternationalBusiness,
	'46_delegating_tasks': DelegatingTasks,
	'46_launch_optimization': LaunchOptimization,
	'46_quality_control_checklist': QualityControlChecklist,
	'51_drivers_installation': DriversInstallation,
	'51_hardware_security': HardwareSecurity,
	'55_bitcoins_technology': BitcoinsTechnology,
	'55_ebanking_security': EbankingSecurity,
	'55_electronic_signature': ElectronicSignature,
	'55_financial_data_analytics': FinancialDataAnalysis,
	'55_fintech_industry': FintechIndustry,
	'55_fraud_detection': FraudDetection,
	'55_personal_data_protection': PersonalDataProtection,
	'arrow': Arrow,
	'chevron': Chevron,
	'hamburger': Hamburger,
	'x': XIcon,
	'people': People,
	'search': Search,
	'star': Star,
	'profile': Profile,
	'facebook': Facebook,
	'instagram': Instagram,
	'linkedin': Linkedin,
	'twitter': Twitter,
	'caretDown': CaretDownSVG,
}
