import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { H4, P } from '../Typography'

const CardWrap = styled.div`
	flex-direction: column;
	align-items: stretch;
	border: solid 1px ${props => props.theme.colors.grey.light().val};
	width: 100%;
	height: 70vh;
	${props => props.theme.media.tablet} {
		width: 300px;
		height: 300px;
	}
	${props => props.theme.media.sdesk} {
		width: 400px;
		height: 400px;
	}
	header {
		min-height: 2em;
		padding: 1em;
		border-bottom: solid 1px;
		border-color: ${props => props.theme.colors.grey.light().val};
		align-items: center;
		.flex {
			flex-direction: column;
		}
		.icon {
			color: ${props => props.theme.colors.grey.val};
			height: 30px;
			margin-right: 0.5em;
			display: flex;
			align-items: center;
			${props => props.theme.media.tablet} {
				height: 50px;
			}
		}
		${H4}, ${P} {
			z-index: 1;
		}
		${H4} {
			margin-bottom: 0.3em;
		}
	}
	.img {
		background-image: url(${props => props.img});
		background-position: center;
		background-size: cover;
		z-index: 0;
	}
	.content {
		padding: 1em;
	}
	${props => (props.theme.css.card ? props.theme.css.card : '')}
`

const Card = ({ children, img, titleText, subTitle, ...props }) => {
	return (
		<CardWrap className='card flex' img={img} {...props}>
			{titleText || img ? (
				<header className='flex'>
					{img && <div className='img overlay' />}
					<div className='titles flex'>
						{titleText && <H4>{titleText}</H4>}
						{subTitle && <P>{subTitle}</P>}
					</div>
				</header>
			) : null}
			<main className='content'>{children}</main>
		</CardWrap>
	)
}
Card.propTypes = {
	img: PropTypes.string,
	titleText: PropTypes.string,
	subTitle: PropTypes.string,
}
export default Card
