import React from 'react'
const IconSVG = ({ props }) => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'>
		<defs></defs>
		<g id='Layer_2' data-name='Layer 2'>
			<g id='FUTURO_ICONS' data-name='FUTURO ICONS'>
				<g id='LINE_ICONS' data-name='LINE ICONS'>
					<g id='_27._MODERN_EDUCATION' data-name='27. MODERN EDUCATION'>
						<g id='_27_master_your_skill' data-name='27_master_your_skill'>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
									strokeLineJoin: 'round',
								}}
								x1='53'
								y1='22'
								x2='53'
								y2='28'
							/>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
									strokeLineJoin: 'round',
								}}
								d='M25,28H4a3,3,0,0,1,0-6H25'
							/>
							<polyline
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
									strokeLineJoin: 'round',
								}}
								points='41 22 58 22 63 25 58 28 41 28'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
									strokeLineJoin: 'round',
								}}
								x1='11'
								y1='22'
								x2='11'
								y2='28'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
									strokeLineJoin: 'round',
								}}
								x1='7'
								y1='22'
								x2='7'
								y2='28'
							/>
							<path
								style={{
									strokeLineJoin: 'round',
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
								}}
								d='M38,44V42l3-10V22a2,2,0,0,0-4,0V21a2,2,0,0,0-4,0V19a2,2,0,0,0-4,0v2a2,2,0,0,0-4,0v9'
							/>
							<line
								style={{
									strokeLineJoin: 'round',
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
								}}
								x1='29'
								y1='21'
								x2='29'
								y2='23'
							/>
							<line
								style={{
									strokeLineJoin: 'round',
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
								}}
								x1='33'
								y1='21'
								x2='33'
								y2='23'
							/>
							<line
								style={{
									strokeLineJoin: 'round',
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
								}}
								x1='37'
								y1='22'
								x2='37'
								y2='23'
							/>
							<polyline
								style={{
									strokeLineJoin: 'round',
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
								}}
								points='21 28 21 30 21 32 23 36 26 40 26 44'
							/>
							<line
								style={{
									strokeLineJoin: 'round',
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
								}}
								x1='30'
								y1='48'
								x2='28'
								y2='48'
							/>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
									strokeLineJoin: 'round',
								}}
								d='M25,64V45a1,1,0,0,1,1-1H38a1,1,0,0,1,1,1V64'
							/>
							<line
								style={{
									strokeLineJoin: 'round',
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
								}}
								x1='43'
								y1='64'
								x2='43'
								y2='62'
							/>
							<line
								style={{
									strokeLineJoin: 'round',
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
								}}
								x1='43'
								y1='60'
								x2='43'
								y2='58'
							/>
							<line
								style={{
									strokeLineJoin: 'round',
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
								}}
								x1='43'
								y1='56'
								x2='43'
								y2='54'
							/>
							<line
								style={{
									strokeLineJoin: 'round',
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
								}}
								x1='21'
								y1='64'
								x2='21'
								y2='62'
							/>
							<line
								style={{
									strokeLineJoin: 'round',
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
								}}
								x1='21'
								y1='60'
								x2='21'
								y2='58'
							/>
							<line
								style={{
									strokeLineJoin: 'round',
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
								}}
								x1='21'
								y1='56'
								x2='21'
								y2='54'
							/>
							<path
								style={{
									strokeLineJoin: 'round',
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
								}}
								d='M17.94,51.05A27,27,0,0,1,5.16,30.94'
							/>
							<path
								style={{
									strokeLineJoin: 'round',
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
								}}
								d='M58.84,31A27,27,0,0,1,46.2,51'
							/>
							<path
								style={{
									strokeLineJoin: 'round',
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
								}}
								d='M6.61,18.81a27,27,0,0,1,50.78,0'
							/>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
