import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_6_x5F_computer_x5F_desk'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='58px'
		viewBox='0 0 64 58'
		style={{ enableBackground: 'new 0 0 64 58' }}
	>
		<g>
			<g>
				<g>
					<g>
						<g>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								d='M49,4c0-1.657-1.344-3-3-3H4
						C2.344,1,1,2.343,1,4v28c0,1.657,1.344,3,3,3h42c1.656,0,3-1.343,3-3V14'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='30'
								y1='35'
								x2='31'
								y2='44'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='19'
								y1='44'
								x2='20'
								y2='35'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='15'
								y1='44'
								x2='35'
								y2='44'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='1'
								y1='29'
								x2='49'
								y2='29'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='24'
								y1='25'
								x2='26'
								y2='25'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='28'
								y1='25'
								x2='30'
								y2='25'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='32'
								y1='25'
								x2='34'
								y2='25'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='20'
								y1='25'
								x2='22'
								y2='25'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='16'
								y1='25'
								x2='18'
								y2='25'
							/>
						</g>
						<polyline
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							points='37,44 63,44 63,48 1,48 1,44 13,44 
									'
						/>
					</g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='59'
						y1='19'
						x2='45'
						y2='5'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='55'
						y1='40'
						x2='60'
						y2='25'
					/>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='50,40 60,40 60,44 44,44 44,40 48,40 
							'
					/>

					<rect
						x='25'
						y='5'
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						width='20'
						height='4'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='5'
						y1='48'
						x2='5'
						y2='58'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='9'
						y1='48'
						x2='9'
						y2='58'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='55'
						y1='48'
						x2='55'
						y2='58'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='59'
						y1='48'
						x2='59'
						y2='58'
					/>
					<circle
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						cx='60'
						cy='22'
						r='3'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
