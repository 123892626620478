import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_5_x5F_leadership_x5F_growth'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<g>
					<g>
						<polyline
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='1,64 1,59 
					21,59 21,64 				'
						/>
						<polyline
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='21,56 21,53 
					41,53 41,64 				'
						/>
						<polyline
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='41,50 41,47 
					63,47 63,64 				'
						/>
					</g>
					<g>
						<circle
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							cx='29'
							cy='5'
							r='4'
						/>
						<polyline
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='24,15 16,17 
					10,23 7,20 14,13 25,10 				'
						/>
						<polyline
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='25,30 33,32 
					39,42 43,40 38,29 28,24 30,17 39,16 46,9 43,6 37,12 30,12 				'
						/>
						<polyline
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='26,28 23,38 
					12,47 9,44 17,36 20,27 21.034,15.742 				'
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
