import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x31_6_x5F_security_x5F_lock'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='46px'
		height='64px'
		viewBox='0 0 46 64'
		style={{ enableBackground: 'new 0 0 46 64' }}
	>
		<g>
			<g>
				<g>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M45,60c0,1.656-1.344,3-3,3H4
				c-1.656,0-3-1.344-3-3V32c0-1.656,1.344-3,3-3h38c1.656,0,3,1.344,3,3V60z'
					/>
					<g>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='22'
							y1='35'
							x2='24'
							y2='35'
						/>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='26'
							y1='35'
							x2='28'
							y2='35'
						/>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='18'
							y1='35'
							x2='20'
							y2='35'
						/>
					</g>
					<g>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='8'
							y1='51'
							x2='14'
							y2='45'
						/>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='16'
							y1='51'
							x2='22'
							y2='45'
						/>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='24'
							y1='51'
							x2='30'
							y2='45'
						/>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='32'
							y1='51'
							x2='38'
							y2='45'
						/>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='6'
							y1='45'
							x2='40'
							y2='45'
						/>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='6'
							y1='51'
							x2='40'
							y2='51'
						/>
					</g>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M39,29V17c0-8.837-7.163-16-16-16
				C14.164,1,7,8.163,7,17v12'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M13,26v-9c0-5.523,4.477-10,10-10
				c5.523,0,10,4.477,10,10v9'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='1'
						y1='57'
						x2='45'
						y2='57'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
