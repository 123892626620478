import React from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'
import V from 'react-vimeo-embed'
import ErrorBoundary from '../ErrorBoundary'
import { useState } from '../../../utils'
import { H2, P, Strong } from '../Typography'

const ErrWrap = styled.div`
	text-align: center;
	min-height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: ${props => props.theme.colors.grey.light(5).val};
	h2 {
		font-weight: 600;
		color: ${props => props.theme.colors.err.val};
		margin-bottom: 1em;
	}
	.error-message {
		margin-top: 1em;
	}
`

const msg = 'Unable to render video (most likely vimeo video ID is invalid)'
const Vimeo = ({ vimeoId }) => {
	const [error, setError] = useState(null, 'error')
	return error ? (
		<ErrWrap>
			<H2>Unable to load video...</H2>
			<P className='error-message'>
				<span className='lable'>Error: </span>
				<Strong>{error}</Strong>
			</P>
		</ErrWrap>
	) : (
		<ErrorBoundary msg={msg}>
			<V
				video={vimeoId}
				className='vimeo'
				responsive
				onError={err => {
					setError(err.message)
				}}
			/>
		</ErrorBoundary>
	)
}
Vimeo.propTypes = {}
export default Vimeo
