import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_2_x5F_focus_x5F_group'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='62px'
		viewBox='0 0 64 62'
		style={{ enableBackground: 'new 0 0 64 62' }}
	>
		<g>
			<g>
				<g>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M19,34.5c0,3.5-1.963,6.5-5,6.5
				c-3.037,0-5-3-5-6.5s1-5.5,5-5.5S19,31,19,34.5z'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M17,40l6,4c0,0,0,0.344,0,2c0,1.657-1,3-2,3H7
				c-1,0-2-1.343-2-3c0-1.656,0-2,0-2l6-4'
					/>
				</g>
				<path
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					d='M27,51
			c0,1.104-0.896,2-2,2h-5l4,8L8,53H3c-1.105,0-2-0.896-2-2V27c0-1.104,0.895-2,2-2h22c1.104,0,2,0.896,2,2V51z'
				/>
				<g>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M55,24.5c0,3.5-1.963,6.5-5,6.5
				c-3.037,0-5-3-5-6.5s1-5.5,5-5.5S55,21,55,24.5z'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M53,30l6,4c0,0,0,0.344,0,2c0,1.657-1,3-2,3H43
				c-1,0-2-1.343-2-3c0-1.656,0-2,0-2l6-4'
					/>
				</g>
				<path
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					d='M44,15h17
			c1.104,0,2,0.896,2,2v24c0,1.104-0.896,2-2,2h-5l-16,8l4-8h-5c-1.105,0-2-0.896-2-2v-9'
				/>
				<g>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M33,10.5c0,3.5-1.963,6.5-5,6.5
				c-3.037,0-5-3-5-6.5S24,5,28,5S33,7,33,10.5z'
					/>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='19,22 19,20 25,16 			'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M31,16l6,4c0,0,0,0.344,0,2c0,1.657-1,3-2,3h-5'
					/>
				</g>
				<path
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					d='M15,22V3
			c0-1.105,0.895-2,2-2h22c1.104,0,2,0.895,2,2v24c0,1.104-0.896,2-2,2h-5l-3.999,6.001'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='39'
					y1='57'
					x2='41'
					y2='57'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='35'
					y1='57'
					x2='37'
					y2='57'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='31'
					y1='57'
					x2='33'
					y2='57'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='43'
					y1='57'
					x2='45'
					y2='57'
				/>
			</g>
		</g>
	</svg>
)
export default IconSVG
