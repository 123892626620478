import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x31_6_x5F_technical_x5F_tools'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<g>
					<g>
						<path
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							d='M35,42l7,7v1
					c0,9,4,13,12,13l2-1l-6-6l6-6l6,6l1-2c0-8-4-12-13-12h-1L39,32'
						/>
						<path
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							d='M32,25L22,15v-1
					c0-9-4-13-12-13L8,2l6,6l-6,6L2,8l-1,2c0,8,4,12,13,12h1l7,7'
						/>

						<polygon
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinecap: 'round',
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='
					24,31 1,54 1,56 8,63 10,63 33,40 				'
						/>

						<polyline
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinecap: 'round',
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='
					26,32 29,29 35,35 32,38 				'
						/>

						<polyline
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinecap: 'round',
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='
					31,30 53,8 53,5 61,1 63,3 59,11 56,11 34,33 				'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinecap: 'round',
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='24'
							y1='37'
							x2='6'
							y2='55'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinecap: 'round',
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='27'
							y1='40'
							x2='9'
							y2='58'
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
