import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x33_6_x5F_network_x5F_secure_x5F_vault'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<path
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				d='M4,49H3c-1.105,0-2-0.896-2-2V3
		c0-1.105,0.895-2,2-2h58c1.104,0,2,0.895,2,2v44c0,1.104-0.896,2-2,2h-1'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='47'
				y1='49'
				x2='17'
				y2='49'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='57'
				y1='17'
				x2='57'
				y2='33'
			/>
			<rect
				x='7'
				y='49'
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				width='10'
				height='4'
			/>
			<rect
				x='47'
				y='49'
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				width='10'
				height='4'
			/>
			<rect
				x='55'
				y='7'
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				width='4'
				height='10'
			/>
			<rect
				x='55'
				y='33'
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				width='4'
				height='10'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='32'
				y1='49'
				x2='32'
				y2='57'
			/>
			<circle
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='32'
				cy='60'
				r='3'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='35'
				y1='60'
				x2='64'
				y2='60'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='29'
				y1='60'
				x2='0'
				y2='60'
			/>
			<polyline
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				points='52,7 7,7 7,43 52,43 	'
			/>
			<circle
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='32'
				cy='25'
				r='12'
			/>
			<circle
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='32'
				cy='25'
				r='8'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='24'
				y1='25'
				x2='20'
				y2='25'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='32'
				y1='17'
				x2='32'
				y2='13'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='40'
				y1='25'
				x2='44'
				y2='25'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='32'
				y1='33'
				x2='32'
				y2='37'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='26'
				y1='19'
				x2='24'
				y2='17'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='38'
				y1='19'
				x2='40'
				y2='17'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='38'
				y1='31'
				x2='40'
				y2='33'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='26'
				y1='31'
				x2='24'
				y2='33'
			/>
		</g>
	</svg>
)
export default IconSVG
