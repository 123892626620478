import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x33_1_x5F_graduate_x5F_scholar_x5F_avatar'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='54px'
		height='64px'
		viewBox='0 0 54 64'
		style={{ enableBackground: 'new 0 0 54 64' }}
	>
		<g>
			<g>
				<g>
					<g>
						<path
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							d='M38,41l4,2
					c6,2,11,3.925,11,10v11'
						/>
						<path
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							d='M16,41l-4,2
					C6,45,1,46.925,1,53v11'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='11'
							y1='56'
							x2='11'
							y2='64'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='43'
							y1='56'
							x2='43'
							y2='64'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='21'
							y1='37'
							x2='21'
							y2='32'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='33'
							y1='37'
							x2='33'
							y2='32'
						/>
						<polygon
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='27,39 35,37 
					40,41 31,46 27,42 23,46 14,41 19,37 				'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='27'
							y1='50'
							x2='27'
							y2='64'
						/>
						<polygon
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='45,7 27,13 9,7 
					27,1 				'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M38,13c0,2.209-4.925,4-11,4
					c-6.075,0-11-1.791-11-4v8l1,1c0,5,3,12,10,12s10-7,10-12l1-1V13z'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='45'
							y1='7'
							x2='45'
							y2='18'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='45'
							y1='20'
							x2='45'
							y2='22'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='27'
							y1='46'
							x2='27'
							y2='48'
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
