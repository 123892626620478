import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x31_5_x5F_cloud_x5F_management'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<g>
					<g>
						<polyline
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='27,49 23,45 
					19,49 				'
						/>
						<polyline
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='45,49 41,45 
					37,49 				'
						/>
						<polyline
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='36,59 32,63 
					28,59 				'
						/>
						<polyline
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='23,45 23,56 
					0,56 				'
						/>
						<polyline
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='41,45 41,56 
					64,56 				'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='32'
							y1='44'
							x2='32'
							y2='63'
						/>
					</g>
					<g>
						<g>
							<circle
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeLinejoin: 'round',
									strokeMiterlimit: 10,
								}}
								cx='30'
								cy='15'
								r='7'
							/>
						</g>
					</g>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M44,6c5,0,10,6,10,12c5,0,9,5.053,9,11
				c0,6.627-5.373,12-12,12H13C6.373,41,1,35.627,1,29c0-6.627,5.373-12,12-12l0,0'
					/>
					<circle
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						cx='30'
						cy='15'
						r='3'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='25'
						y1='36'
						x2='23'
						y2='36'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='37'
						y1='36'
						x2='35'
						y2='36'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='33'
						y1='36'
						x2='31'
						y2='36'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='29'
						y1='36'
						x2='27'
						y2='36'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='41'
						y1='36'
						x2='39'
						y2='36'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M41,15c0-0.165-0.018-0.327-0.025-0.49
				l2.803-1.921c-0.229-1.319-1.215-3.737-1.215-3.737L39.18,8.945c-0.183-0.276-0.38-0.543-0.586-0.802l1.137-3.196
				c-0.941-0.911-2.005-1.692-3.172-2.312l-2.697,2.076c-0.307-0.115-0.619-0.218-0.938-0.306l-0.958-3.251
				C31.322,1.064,30.669,1,30,1c-0.669,0-1.322,0.064-1.966,0.154l-0.958,3.251c-0.319,0.088-0.632,0.19-0.938,0.306l-2.697-2.076
				c-1.167,0.62-2.231,1.401-3.172,2.312l1.137,3.196C21.2,8.402,21.003,8.669,20.82,8.945l-3.383-0.094
				c-0.572,1.165-0.986,2.418-1.215,3.737l2.803,1.921C19.018,14.673,19,14.835,19,15c0,0.166,0.018,0.327,0.025,0.49l-2.803,1.921
				c0.229,1.319,0.643,2.572,1.215,3.737l3.383-0.094c0.183,0.276,0.38,0.543,0.586,0.802l-1.137,3.196
				c0.941,0.911,2.005,1.692,3.172,2.312l2.697-2.076c0.307,0.115,0.619,0.218,0.938,0.306l0.958,3.251
				C28.678,28.937,29.331,29,30,29c0.669,0,1.322-0.063,1.966-0.154l0.958-3.251c0.319-0.088,0.632-0.19,0.938-0.306l2.697,2.076
				c1.167-0.62,2.231-1.401,3.172-2.312l-1.137-3.196c0.206-0.259,0.403-0.525,0.586-0.802l3.383,0.094
				c0.572-1.165,0.986-2.418,1.215-3.737l-2.803-1.921C40.982,15.327,41,15.166,41,15z'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
