import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x32_9_x5F_strategy_x5F_thinking'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<g>
					<g>
						<polyline
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							points='30,64 30,17 63,17 				'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='26'
							y1='64'
							x2='26'
							y2='14'
						/>
						<polyline
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							points='38,37 38,23 44,23 				'
						/>
						<polyline
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							points='34,14 34,11 1,11 				'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='34'
							y1='52'
							x2='34'
							y2='20'
						/>
						<polyline
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							points='22,44 22,19 4,19 				'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='37'
							y1='11'
							x2='39'
							y2='11'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='41'
							y1='11'
							x2='43'
							y2='11'
						/>
						<polyline
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='59,21 63,17 
					59,13 				'
						/>
						<polyline
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='5,15 1,11 5,7 
									'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='23'
							y1='15'
							x2='21'
							y2='15'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='19'
							y1='15'
							x2='17'
							y2='15'
						/>
					</g>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M17,64V52
				c0-10-7-18.402-7-30S19.402,1,31,1s21,9.402,21,21l6,13v2h-6v9c0,3.313-2.687,6-6,6h-6v12'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='40'
						y1='52'
						x2='37'
						y2='52'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
