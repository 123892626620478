import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { RT } from '..'

const UL = styled.ul`
	margin: 0 auto;
	list-style-type: square;
	display: block;
	${props => props.theme.media.sdesk} {
		max-width: 80%;
	}
	li {
		font-size: 1.4rem;
		margin: 20px 0;
		font-weight: 300;
		display: list-item;
		h4 {
			font-size: 1rem;
			letter-spacing: 0.5px;
			font-weight: 400;
			${props => props.theme.media.sdesk} {
				font-size: 1.3rem;
			}
		}
		p {
			font-size: 1.3rem;
			line-height: 1.5rem;
			margin: 0 0 0.5em 0;
		}
	}
`

const BulletList = ({ items }) => {
	return (
		<UL>
			{items.map(({ title, content, richText, id }) => (
				<li key={id}>
					<h4>{title}</h4>
					{content ? <p>{content.text}</p> : null}
					{richText ? <RT content={richText} /> : null}
				</li>
			))}
		</UL>
	)
}
BulletList.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			content: PropTypes.shape({
				text: PropTypes.string.isRequired,
			}).isRequired,
		})
	).isRequired,
}
export default BulletList
