import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_4_x5F_blank_x5F_paper_x5F_write'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='50px'
		height='64px'
		viewBox='0 0 50 64'
		style={{ enableBackground: 'new 0 0 50 64' }}
	>
		<g>
			<g>
				<g>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='45,36 45,59 22,59 			'
					/>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='37,1 37,9 45,9 			'
					/>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='7,59 1,59 1,1 37,1 45,9 45,20 			'
					/>
					<g>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='17'
							y1='60'
							x2='12'
							y2='55'
						/>

						<polyline
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinecap: 'round',
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='
					27,34 37,24 45,32 				'
						/>

						<polygon
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinecap: 'round',
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='
					44,23 12,55 9,63 17,60 49,28 				'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinecap: 'round',
								strokeMiterlimit: 10,
							}}
							x1='22'
							y1='45'
							x2='27'
							y2='50'
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
