import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x31_6_x5F_network_x5F_database'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='32'
						y1='51'
						x2='32'
						y2='57'
					/>
					<circle
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						cx='32'
						cy='60'
						r='3'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='29'
						y1='60'
						x2='0'
						y2='60'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='64'
						y1='60'
						x2='35'
						y2='60'
					/>
					<ellipse
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						cx='32'
						cy='8'
						rx='23'
						ry='7'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M55,13v7c0,3.866-10.297,7-23,7
				c-12.703,0-23-3.134-23-7v-7'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M55,25v7c0,3.866-10.297,7-23,7
				c-12.703,0-23-3.134-23-7v-7'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M55,37v7c0,3.866-10.297,7-23,7
				c-12.703,0-23-3.134-23-7v-7'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='31'
						y1='45'
						x2='33'
						y2='45'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='27'
						y1='45'
						x2='29'
						y2='45'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='35'
						y1='45'
						x2='37'
						y2='45'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='31'
						y1='33'
						x2='33'
						y2='33'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='27'
						y1='33'
						x2='29'
						y2='33'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='35'
						y1='33'
						x2='37'
						y2='33'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='31'
						y1='21'
						x2='33'
						y2='21'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='27'
						y1='21'
						x2='29'
						y2='21'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='35'
						y1='21'
						x2='37'
						y2='21'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
