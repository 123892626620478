import React, { createContext, useContext } from 'react'
import PropTypes, { oneOfType } from 'prop-types'
import styled from 'styled-components'
import isNil from 'lodash/isNil'
import { Btn, Icon } from '../../UI'
import { useState } from '../../../utils'

const Wrap = styled.div`
	.ctrl-target {
		overflow: hidden;
		transition: all ${props => props.theme.times.tranM};
	}
	.ctrl {
		display: flex;
		text-align: left;
		width: 100%;
		padding: 0;
		margin: 10px 0;
		color: ${props => props.theme.colors.white.val};
		justify-content: flex-start;
		align-items: center;
	}
	.toggle {
		height: ${props => props.plusSize}px;
		min-width: ${props => props.plusSize}px;
		max-width: ${props => props.plusSize}px;
		margin-right: 0.5em;
		/* top: ${props => props.plusSize * 0.25}px; */
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		${props => props.theme.media.sdesk} {
		}
		span {
			background: ${props => props.theme.colors.slate.val};
			flex: 1;
			position: absolute;
			transition: all 0.2s ease-in;
			transform-origin: center;
			height: 2px;
			width: ${props => props.plusSize}px;
			&:nth-of-type(2) {
				transform: rotate(90deg);
			}
		}
	}
	.icon.chevron {
		margin-left: auto;
	}
	.accordion-content {
		padding: 1em 0;
	}
`
const AccordionContext = createContext(null)

export const AccordionProvider = ({ children }) => {
	const [currentBellow, setBellow] = useState(null, 'currentBellow')
	const Provider = AccordionContext.Provider
	return <Provider value={{ currentBellow, setBellow }}>{children}</Provider>
}

const Accordion = ({
	title,
	showPlus,
	useChevrons,
	children,
	className,
	id,
	plusSize = 20,
	...props
}) => {
	const { currentBellow, setBellow } = useContext(AccordionContext) || {}

	const [showBellow, setShowBellow] = useState(
		!isNil(currentBellow) && currentBellow === id,
		'showBellow'
	)

	if (setBellow && typeof id === 'undefined')
		console.warn(
			'Accordion id is undefined - When using AccordionProvider a unique id must be passed to each Accordion'
		)

	const handleClick = () => {
		if (setBellow && typeof id !== 'undefined') {
			if (currentBellow === id) setBellow(null)
			else setBellow(id)
		} else setShowBellow(s => !s)
	}

	const show = !isNil(currentBellow) ? currentBellow === id : showBellow

	return (
		<Wrap className={`accordion${className ? ` ${className}` : ''}`} plusSize={plusSize} {...props}>
			<Btn className='ctrl' onClick={handleClick}>
				{showPlus ? (
					<div className={`toggle`}>
						<span></span>
						<span style={{ width: show ? 0 : plusSize }}></span>
					</div>
				) : null}
				{title}
				{useChevrons ? <Icon type='chevron' rotate={'90deg'} flipV={show} /> : null}
			</Btn>
			<div className='ctrl-target' style={{ maxHeight: show ? 700 : 0 }}>
				<div className='accordion-content'>{children}</div>
			</div>
		</Wrap>
	)
}

Accordion.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node,
	id: oneOfType([PropTypes.number, PropTypes.string]),
}
export default Accordion
