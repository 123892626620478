import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_4_x5F_sticky_x5F_notes'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='60px'
		viewBox='0 0 64 60'
		style={{ enableBackground: 'new 0 0 64 60' }}
	>
		<g>
			<g>
				<g>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='8,33 8,26 1,26 			'
					/>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='35,8 35,1 1,1 1,26 8,33 14,33 			'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='1'
						y1='5'
						x2='35'
						y2='5'
					/>
				</g>
				<g>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='54,43 63,43 63,11 29,11 29,24 			'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='29'
						y1='15'
						x2='63'
						y2='15'
					/>
				</g>
				<g>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='24,59 24,52 17,52 			'
					/>
					<polygon
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='17,52 24,59 51,59 51,27 17,27 			'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='17'
						y1='31'
						x2='51'
						y2='31'
					/>
				</g>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='12'
					y1='11'
					x2='24'
					y2='11'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='6'
					y1='11'
					x2='10'
					y2='11'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='20'
					y1='15'
					x2='24'
					y2='15'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='6'
					y1='15'
					x2='18'
					y2='15'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='6'
					y1='19'
					x2='24'
					y2='19'
				/>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M34,20c1.999,0,2.001,2,4,2c1.998,0,2.002-2,4-2
			s2.001,2,4,2s2.001-2,4-2c2.002,0,1.998,2,4,2s1.998-2,4-2'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='26'
					y1='37'
					x2='46'
					y2='37'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='24'
					y1='37'
					x2='22'
					y2='37'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='26'
					y1='41'
					x2='46'
					y2='41'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='24'
					y1='41'
					x2='22'
					y2='41'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='26'
					y1='45'
					x2='46'
					y2='45'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='24'
					y1='45'
					x2='22'
					y2='45'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='46'
					y1='49'
					x2='38'
					y2='49'
				/>
			</g>
		</g>
	</svg>
)
export default IconSVG
