import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'
import get from 'lodash/get'

import BG from './BG'

const Pic = styled.div`
	background-image: url(${props => props.pic});
	background-size: cover;
	background-position: center;
	background-repeat: none;
`
const Wrap = styled(BG)``

const BGPic = ({ pic, alt = '', className }) => {
	return (
		<Wrap className={`bg-type-pic${className ? ` ${className}` : ''}`}>
			{get(pic, 'fluid') ? (
				<Img
					fluid={pic.fluid}
					objectFit='cover'
					alt={get(pic, 'title', alt)}
					className='bg-pic-gatsby'
				/>
			) : get(pic, 'fixed') ? (
				<Img
					fixed={pic.fixed}
					objectFit='cover'
					alt={get(pic, 'title', alt)}
					className='bg-pic-gatsby'
				/>
			) : (
				<Pic className='bg-pic' pic={pic} />
			)}
		</Wrap>
	)
}
BGPic.propTypes = {
	pic: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape({
			fluid: PropTypes.string,
		}),
		PropTypes.shape({
			fixed: PropTypes.string,
		}),
	]).isRequired,
}
export default BGPic
