import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import HeadRow from './Table.row.head'
import Body from './Table.body'

const T = styled.table`
	text-align: left;
	width: 100%;
	max-width: ${props => (props.maxWidth ? props.maxWidth : 'initial')};
	${props =>
		props.isCentered
			? `
		margin-left: auto;
		margin-right: auto;
	`
			: ''};
	th,
	td {
		padding-left: ${props => props.theme.sizes.font.px};
		padding-right: ${props => props.theme.sizes.font.px};
		border: solid 1px ${props => props.theme.colors.grey.light().val};
		&:last-child {
		}
	}
	th {
		padding-top: 0.5em;
		padding-bottom: 0.5em;
		font-size: 1.1em;
		color: ${props => props.theme.colors.grey.val};
		border: none;
	}
	tfoot {
		th {
			border-top: solid 2px;
			font-size: 0.9em;
			text-align: right;
		}
	}

	${props => (props.theme.css.table ? props.theme.css.table : '')}
`

const Table = ({ title, head, body, foot, Err, Empty, colsQty = 1, ...props }) => {
	return (
		<T {...props}>
			{head && (
				<thead>
					<HeadRow {...head} colsQty={colsQty} />
				</thead>
			)}
			<Body {...body} Err={Err} Empty={Empty} colsQty={colsQty} />
			{foot && (
				<tfoot>
					<HeadRow {...foot} colsQty={colsQty} />
				</tfoot>
			)}
		</T>
	)
}
Table.propTypes = {
	body: PropTypes.shape({}).isRequired,
	head: PropTypes.shape({}),
	foot: PropTypes.shape({}),
}
export default Table
