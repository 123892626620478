import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'
import ReactModal from 'react-modal'

import { Btn, H3, EM } from '../Typography'
import Icon from '../Icons'

ReactModal.setAppElement('#___gatsby')

const ModalInnerWrap = styled.div`
	width: 100%;
	height: 100%;
	overflow-y: auto;
	padding: 1em;
	${props => props.theme.media.tablet} {
		padding: 1em 2em;
		max-width: 80vw;
		max-height: 70vh;
	}
	&.pic {
		figure {
			img {
				display: block;
				max-width: 100%;
				max-height: 60vh;
			}
			figcaption {
				width: 100%;
				padding: 0.5em 1em;
				background: ${props => props.theme.colors.black.val};
				color: ${props => props.theme.colors.white.val};
				box-sizing: border-box;
				span,
				em {
					display: block;
					${props => props.theme.media.tablet} {
						display: initial;
					}
				}
				em {
					margin-left: 1.5em;
				}
			}
		}
	}
	.modal-footer {
		justify-content: center;
		width: 100%;
		.alert-btns {
		}
	}
`

const Modal = ({
	isOpen,
	children,
	closeHandler,
	titleText,
	size,
	canBgClose = true,
	confirm,
	type,
	file,
	caption,
	...props
}) => {
	return (
		<ReactModal
			isOpen={isOpen}
			shouldCloseOnOverlayClick={canBgClose}
			onRequestClose={closeHandler}
			{...props}
			className={`modal ${type || ''} ${size || 'small'}`}
			overlayClassName={`flex overlay modal-overlay ${type || ''}`}
		>
			<header className='flex modal-header'>
				{titleText && <H3>{titleText}</H3>}
				<Btn
					className='modal-close-btn'
					onClick={e => {
						e.preventDefault()
						closeHandler()
					}}
				>
					<Icon type='x' />
				</Btn>
			</header>
			<ModalInnerWrap className={type || ''}>
				{type === 'pic' && file ? (
					<figure>
						<img src={get(file, 'full.url')} alt={get(file, 'description')} />
						<figcaption>
							<span className='name'>{get(file, 'name')}</span>
							{get(file, 'description') && <EM>{file.description}</EM>}
						</figcaption>
					</figure>
				) : (
					<>
						{children && <div className='content'>{children}</div>}
						<footer className='flex modal-footer'>
							{type === 'alert' && (
								<div className='flex alert-btns'>
									<Btn
										className='alert-confirm-btn'
										onClick={e => {
											e.preventDefault()
											confirm()
										}}
										isCta
									>
										Okay
									</Btn>
									<Btn
										className='alert-cancel-btn'
										onClick={e => {
											e.preventDefault()
											closeHandler()
										}}
									>
										Cancel
									</Btn>
								</div>
							)}
						</footer>
					</>
				)}
			</ModalInnerWrap>
		</ReactModal>
	)
}
Modal.propTypes = {
	type: PropTypes.string,
	closeHandler: PropTypes.func.isRequired,
	confirm: PropTypes.func,
}
export default React.memo(Modal)
