import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from './Icons'
import { H2, Link } from './Typography'

const RatingView = styled.div`
	text-align: center;

	.rating-stars {
		display: flex;
		justify-content: center;
		.star {
			height: 25px;
			width: 25px;
			margin: 20px 10px;
			color: #f49164;
			${props => props.theme.media.sdesk} {
				margin: 20px;
				height: 50px;
				width: 50px;
			}
			svg {
				width: 100%;
				height: auto;
			}
		}
	}
	.link {
		font-weight: 600;
		font-size: 2em;
		line-height: 1.1em;
		margin: 30px 0;
		display: block;
		color: ${props => (!props.isWhite ? 'white' : props.theme.colors.orange.val)};
	}
	h2 {
		font-size: 1.8rem;
		font-weight: 400;
		color: ${props => props.theme.colors.text.val};
		padding-bottom: 20px;
	}

	blockquote,
	cite {
		font-size: 1.2rem;
		font-weight: 400;
		font-style: ${props => (props.isWhite ? 'normal' : 'italic')};
		letter-spacing: 0.45px;
		line-height: 1.4rem;
		color: ${props => props.theme.colors.slate.val};
		text-align: center;
		display: block;
		clear: both;
		${props => props.theme.media.sdesk} {
			font-size: 1.4rem;
			line-height: 1.6rem;
		}
	}
	blockquote {
		margin-bottom: 20px;
		/* font-style: italic; */
		&:before {
			content: '“';
		}
		&:after {
			content: '”';
		}
	}
	cite {
		font-style: ${props => (props.isWhite ? 'normal' : 'italic')};
		font-size: 1rem;
		${props => props.theme.media.sdesk} {
			font-size: 1.2rem;
		}
	}
	.rating-cta {
		color: ${props => props.theme.colors.orange.val};
	}
`

const Rating = ({ rating, testimonial, cta, heading, className }) => {
	const ratings = new Array(rating).fill('')

	return (
		<RatingView className={`rating${className ? ` ${className}` : ''}`}>
			<div className={`rating-stars`}>
				{ratings.map((_, idx) => (
					<Icon type='star' className='star' key={idx} />
				))}
			</div>

			{heading && <H2>{heading}</H2>}
			{testimonial.content ? <blockquote>{testimonial.content.text}</blockquote> : null}
			{testimonial.cite ? <cite>{testimonial.cite}</cite> : null}
			{testimonial.citeTitle ? <cite>{testimonial.citeTitle}</cite> : null}
			{cta?.link && (
				<Link noStyles to={cta.link} className='rating-cta'>
					{cta.title || cta.link} <Icon type='arrow' />
				</Link>
			)}
		</RatingView>
	)
}
Rating.propTypes = {
	rating: PropTypes.number.isRequired,
	testimonial: PropTypes.shape({
		cite: PropTypes.string,
		citeTitle: PropTypes.string,
		content: PropTypes.shape({
			text: PropTypes.string,
		}),
	}),
}
export default Rating
