import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_4_x5F_empty_x5F_folder'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='52px'
		viewBox='0 0 64 52'
		style={{ enableBackground: 'new 0 0 64 52' }}
	>
		<g>
			<g>
				<g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='58'
						y1='17'
						x2='40'
						y2='17'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='58'
						y1='21'
						x2='40'
						y2='21'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='12'
						y1='17'
						x2='14'
						y2='17'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='16'
						y1='17'
						x2='18'
						y2='17'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='20'
						y1='17'
						x2='22'
						y2='17'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M59,8V5H23l-4-4H1v47c0,1.657,1.343,3,3,3
				c1.657,0,3-1.343,3-3V11h56v40H4'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='12'
						y1='45'
						x2='58'
						y2='45'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
