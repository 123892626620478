import React from 'react'
import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'
import BlocksSVG from '../../../images/Blocks'
import BG from './BG'

const Wrap = styled(BG)`
	svg {
		fill: ${props => (props.color ? props.color : props.theme.colors.aux2.dark(0).val)};
	}
`

const BGBlocks = ({ isOrange }) => {
	const { colors } = useTheme()

	const color = isOrange ? colors.primary.dark(0).val : null
	return (
		<Wrap color={color} className='bg-type-blocks'>
			<BlocksSVG />
		</Wrap>
	)
}
BGBlocks.propTypes = {
	isOrange: PropTypes.bool,
}
export default BGBlocks
