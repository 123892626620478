import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x31_5_x5F_global_x5F_connection'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='44'
						y1='58'
						x2='35'
						y2='60'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='60'
						y1='35'
						x2='58'
						y2='43'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='32'
						y1='49'
						x2='32'
						y2='57'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M53,44'
					/>
					<g>
						<circle
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							cx='32'
							cy='32'
							r='3'
						/>
					</g>
					<g>
						<circle
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							cx='32'
							cy='18'
							r='3'
						/>
					</g>
					<g>
						<circle
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							cx='32'
							cy='4'
							r='3'
						/>
					</g>
					<g>
						<circle
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							cx='32'
							cy='46'
							r='3'
						/>
					</g>
					<g>
						<circle
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							cx='32'
							cy='60'
							r='3'
						/>
					</g>
					<g>
						<circle
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							cx='19'
							cy='24'
							r='3'
						/>
					</g>
					<g>
						<circle
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							cx='45'
							cy='24'
							r='3'
						/>
					</g>
					<g>
						<circle
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							cx='57'
							cy='46'
							r='3'
						/>
					</g>
					<g>
						<circle
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							cx='47'
							cy='57'
							r='3'
						/>
					</g>
					<g>
						<circle
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							cx='19'
							cy='40'
							r='3'
						/>
					</g>
					<g>
						<circle
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							cx='45'
							cy='40'
							r='3'
						/>
					</g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='21'
						y1='15'
						x2='23'
						y2='15'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='21'
						y1='49'
						x2='23'
						y2='49'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='41'
						y1='49'
						x2='43'
						y2='49'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='12'
						y1='32'
						x2='14'
						y2='32'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='41'
						y1='15'
						x2='43'
						y2='15'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='50'
						y1='32'
						x2='52'
						y2='32'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='49'
						y1='55'
						x2='55'
						y2='48'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='44'
						y1='6'
						x2='35'
						y2='4'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='60'
						y1='29'
						x2='58'
						y2='21'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M53,20'
					/>
					<g>
						<circle
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							cx='57'
							cy='18'
							r='3'
						/>
					</g>
					<g>
						<circle
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							cx='47'
							cy='7'
							r='3'
						/>
					</g>
					<g>
						<circle
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							cx='60'
							cy='32'
							r='3'
						/>
					</g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='49'
						y1='9'
						x2='55'
						y2='16'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='20'
						y1='58'
						x2='29'
						y2='60'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='4'
						y1='35'
						x2='6'
						y2='43'
					/>
					<g>
						<circle
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							cx='7'
							cy='46'
							r='3'
						/>
					</g>
					<g>
						<circle
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							cx='17'
							cy='57'
							r='3'
						/>
					</g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='15'
						y1='55'
						x2='9'
						y2='48'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='20'
						y1='6'
						x2='29'
						y2='4'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='4'
						y1='29'
						x2='6'
						y2='21'
					/>
					<g>
						<circle
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							cx='7'
							cy='18'
							r='3'
						/>
					</g>
					<g>
						<circle
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							cx='17'
							cy='7'
							r='3'
						/>
					</g>
					<g>
						<circle
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							cx='4'
							cy='32'
							r='3'
						/>
					</g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='15'
						y1='9'
						x2='9'
						y2='16'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='29'
						y1='46'
						x2='22'
						y2='42'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='19'
						y1='37'
						x2='19'
						y2='27'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='22'
						y1='26'
						x2='29'
						y2='31'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='35'
						y1='31'
						x2='42'
						y2='26'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='32'
						y1='15'
						x2='32'
						y2='7'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='35'
						y1='19'
						x2='42'
						y2='22'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='35'
						y1='46'
						x2='42'
						y2='42'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='48'
						y1='42'
						x2='54'
						y2='45'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='16'
						y1='42'
						x2='10'
						y2='45'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='45'
						y1='27'
						x2='45'
						y2='37'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='16'
						y1='23'
						x2='10'
						y2='20'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
