import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x32_0_x5F_laptop_x5F_computer'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='44px'
		viewBox='0 0 64 44'
		style={{ enableBackground: 'new 0 0 64 44' }}
	>
		<g>
			<g>
				<g>
					<polygon
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='59,43 5,43 1,39 1,37 25,37 27,39 
				37,39 39,37 63,37 63,39 			'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M59,34V4c0-1.657-1.344-3-3-3H8
				C6.344,1,5,2.343,5,4v30'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='27'
						y1='31'
						x2='29'
						y2='31'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='23'
						y1='31'
						x2='25'
						y2='31'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='31'
						y1='31'
						x2='33'
						y2='31'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='35'
						y1='31'
						x2='37'
						y2='31'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='39'
						y1='31'
						x2='41'
						y2='31'
					/>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='21,31 9,31 9,5 55,5 55,31 43,31 			
				'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
