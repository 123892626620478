import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x33_6_x5F_security_x5F_access'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='48px'
		height='64px'
		viewBox='0 0 48 64'
		style={{ enableBackground: 'new 0 0 48 64' }}
	>
		<g>
			<polygon
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				points='47,46 24,63 1,46 
		1,1 11,6 14,1 34,1 37,6 47,1 	'
			/>
			<polyline
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				points='5,3 5,44 24,58 
		43,44 43,3 	'
			/>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='11'
				y1='6'
				x2='11'
				y2='11'
			/>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='37'
				y1='6'
				x2='37'
				y2='11'
			/>
			<g>
				<circle
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					cx='24'
					cy='19'
					r='7'
				/>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='26,26 26,35 29,35 		'
				/>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='25,39 28,39 28,43 26,43 26,46 22,46 
			22,26 		'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='26'
					y1='16'
					x2='22'
					y2='16'
				/>
			</g>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='10'
				y1='14'
				x2='12'
				y2='14'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='10'
				y1='18'
				x2='12'
				y2='18'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='10'
				y1='22'
				x2='12'
				y2='22'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='36'
				y1='14'
				x2='38'
				y2='14'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='36'
				y1='18'
				x2='38'
				y2='18'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='36'
				y1='22'
				x2='38'
				y2='22'
			/>
		</g>
	</svg>
)
export default IconSVG
