import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x34_0_x5F_intelligent_x5F_personal_x5F_assistant'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<g>
					<polygon
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='59,63 5,63 1,59 1,57 25,57 27,59 
				37,59 39,57 63,57 63,59 			'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M22,21H8c-1.656,0-3,1.343-3,3v30'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M59,54V24c0-1.657-1.344-3-3-3H42'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='27'
						y1='51'
						x2='29'
						y2='51'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='31'
						y1='51'
						x2='33'
						y2='51'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='35'
						y1='51'
						x2='37'
						y2='51'
					/>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='42,25 55,25 55,51 39,51 			'
					/>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='25,51 9,51 9,25 22,25 			'
					/>
				</g>
			</g>
			<g>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='32'
					cy='13'
					r='4'
				/>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M36,48V36h3V23c0-1.104-0.896-2-2-2h-2l-3,3l-3-3
			h-2c-1.105,0-2,0.896-2,2v13h3v12'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='41'
					y1='14'
					x2='45'
					y2='14'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='39'
					y1='7'
					x2='42'
					y2='4'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='23'
					y1='14'
					x2='19'
					y2='14'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='25'
					y1='7'
					x2='22'
					y2='4'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='32'
					y1='4'
					x2='32'
					y2='0'
				/>
			</g>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='32'
				y1='27'
				x2='32'
				y2='29'
			/>
		</g>
	</svg>
)
export default IconSVG
