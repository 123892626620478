import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { model, createStore, StoreProvider } from '../store'
import { createTheme, createThemeGlobalStyles } from '@swfsoft/swf-theme'
import { SkeletonTheme } from '../components/UI'
import ProjectStyles from '../styles/globalStyles'
import colors from '../styles/colors'
import BuildIndexes from '../components/buildIndexes'

const isBrowser = typeof window !== 'undefined'
const isDev = process.env.NODE_ENV !== 'production'

const theme = createTheme({
	printLog: isBrowser,
	colors,
	breaks: {
		ldesk: {
			px: '1845px',
			num: 1845,
		},
	},
	sizes: {
		font: {
			base: {
				num: 16,
				px: '16px',
			},
		},
		gutter: {
			mobile: {
				num: 34,
				px: '5vw',
			},
			sdesk: {
				num: 138,
				px: '138px',
			},
			ldesk: {
				num: 138,
				px: '138px',
			},
		},
		header: {
			mobile: {
				num: 30,
				px: '30px',
			},
			sdesk: {
				num: 81,
				px: '81px',
			},
		},
	},
	fonts: {
		textFamily: `'Fira Sans', Helvetica, Arial, sans-serif`,
		titleFamily: `'Fira Sans', Helvetica, Arial, sans-serif`,
	},
})

Bugsnag.start({
	apiKey: '770d2a868953cb7a7ef0768133659478',
	plugins: [new BugsnagPluginReact()],
	onError: event => {
		// Disable for dev
		if (isDev) {
			console.log(
				`%c BUGSNAG - report that would have sent:`,
				`color: ${theme.colors.white.val}; background-color: ${theme.colors.err.val}; padding: 4px;`,
				event
			)
			return false
		}

		// NOTE: You can mutate error report sent to bugsnag
		// see: https://docs.bugsnag.com/platforms/react-native/expo/customizing-error-reports/
	},
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const themeGlobalStyles = createThemeGlobalStyles(theme)

const GlobalStyles = createGlobalStyle`
	${themeGlobalStyles}
`

const store = createStore(model)

const Entry = ({ children }) => (
	<ErrorBoundary>
		<ThemeProvider theme={theme}>
			<GlobalStyles />
			<ProjectStyles />
			<StoreProvider store={store}>
				<BuildIndexes />
				<SkeletonTheme>{children}</SkeletonTheme>
			</StoreProvider>
		</ThemeProvider>
	</ErrorBoundary>
)
export default Entry
