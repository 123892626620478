import React from 'react'
const IconSVG = ({ props }) => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'>
		<defs>
			<style></style>
		</defs>
		<g id='Layer_2' data-name='Layer 2'>
			<g id='FUTURO_ICONS' data-name='FUTURO ICONS'>
				<g id='LINE_ICONS' data-name='LINE ICONS'>
					<g id='_09._BUSINESS_ELEMENTS' data-name='09. BUSINESS ELEMENTS'>
						<g id='_09_teamwork_collaboration' data-name='09_teamwork_collaboration'>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								x1='10'
								y1='32'
								x2='12'
								y2='32'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								x1='7'
								y1='5'
								x2='7'
							/>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								d='M21,22V35a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V21'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								x1='21'
								x2='21'
								y2='6'
							/>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								d='M28,8H26L16,5H7A2,2,0,0,0,7,9H5a2,2,0,0,0,0,4H3a2,2,0,0,0,0,4H5a2,2,0,0,0,0,4h9'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								x1='5'
								y1='17'
								x2='11'
								y2='17'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								x1='5'
								y1='13'
								x2='11'
								y2='13'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								x1='7'
								y1='9'
								x2='11'
								y2='9'
							/>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								d='M10,23a2,2,0,0,0,2,2h4l4-2,4-3h4'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								x1='32'
								y1='10'
								x2='32'
								y2='12'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								x1='59'
								y1='7'
								x2='64'
								y2='7'
							/>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								d='M42,21H29a1,1,0,0,1-1-1V8a1,1,0,0,1,1-1H43'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								x1='64'
								y1='21'
								x2='58'
								y2='21'
							/>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								d='M56,28V26l3-10V7a2,2,0,0,0-4,0V5a2,2,0,0,0-4,0V3a2,2,0,0,0-4,0V5a2,2,0,0,0-4,0v9'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								x1='47'
								y1='5'
								x2='47'
								y2='11'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								x1='51'
								y1='5'
								x2='51'
								y2='11'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								x1='55'
								y1='7'
								x2='55'
								y2='11'
							/>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								d='M41,10a2,2,0,0,0-2,2v4l2,4,3,4v4'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								x1='52'
								y1='32'
								x2='54'
								y2='32'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								x1='43'
								y1='58'
								x2='43'
								y2='64'
							/>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								d='M57,41V29a1,1,0,0,0-1-1H44a1,1,0,0,0-1,1V42'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								x1='57'
								y1='64'
								x2='57'
								y2='57'
							/>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								d='M36,44h2l10-3h9a2,2,0,0,1,0,4h2a2,2,0,0,1,0,4h2a2,2,0,0,1,0,4H59a2,2,0,0,1,0,4H50'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								x1='59'
								y1='53'
								x2='53'
								y2='53'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								x1='59'
								y1='49'
								x2='53'
								y2='49'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								x1='57'
								y1='45'
								x2='53'
								y2='45'
							/>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								d='M54,59a2,2,0,0,1-2,2H48l-4-2-4-3H36'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								x1='32'
								y1='46'
								x2='32'
								y2='48'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								x1='7'
								y1='43'
								y2='43'
							/>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								d='M21,57H35a1,1,0,0,0,1-1V44a1,1,0,0,0-1-1H22'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								y1='57'
								x2='5'
								y2='57'
							/>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								d='M8,36v2L5,48v9a2,2,0,0,0,4,0v2a2,2,0,0,0,4,0v2a2,2,0,0,0,4,0V59a2,2,0,0,0,4,0V50'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								x1='17'
								y1='59'
								x2='17'
								y2='53'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								x1='13'
								y1='59'
								x2='13'
								y2='53'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								x1='9'
								y1='57'
								x2='9'
								y2='53'
							/>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								d='M23,54a2,2,0,0,0,2-2V48l-2-4-3-4V36'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeMiterlimit: '10',
									strokeWidth: '2px',
								}}
								x1='7'
								y1='36'
								x2='21'
								y2='36'
							/>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
