/*
 =================================================
  Uses: https://github.com/tjallingt/react-youtube
 =================================================
* */
/* eslint-disable no-useless-escape */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import YT from 'react-youtube'

import { Block } from '../UI'

const Wrap = styled.div``

const parseURL = url => {
	const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
	const match = url.match(regExp)
	return match && match[7].length === 11 ? match[7] : null
}

const YouTube = ({ url }) => {
	const videoId = url && parseURL(url)
	// console.log('YouTube - url: ', url, ' | videoId: ', videoId)
	const opts = {
		videoId,
		className: 'yt-wrap',
	}
	return videoId ? (
		<Wrap className='youtube'>
			<YT {...opts} />
		</Wrap>
	) : (
		<Block>Unable to parse Youtube URL</Block>
	)
}
YouTube.propTypes = {
	url: PropTypes.string,
}
export default YouTube
