import React from 'react'
import styled, { css } from 'styled-components'
import { Link as ReachLink } from '@reach/router'
import { isExternalLink, isAnchorLink } from '../../../utils'
import Icon from '../Icons'

export const P = styled.p`
	font-size: 1rem;
	line-height: 1.4rem;
	color: ${props => props.theme.colors.text.val};
`

export const Small = styled.small`
	font-size: 0.8rem;
	font-weight: 300;
	${props => props.theme.media.sdesk} {
		font-weight: 200;
		font-size: 1rem;
	}
`

export const H1 = styled.h1`
	font-size: 1.5rem;
`
export const H2 = styled.h2`
	font-size: 1.5rem;
`
export const H3 = styled.h3`
	font-weight: 500;
`

export const H4 = styled.h4`
	font-weight: 500;
`
export const H5 = styled.h5`
	font-weight: 500;
`
export const H6 = styled.h6`
	font-weight: 500;
`

export const HR = styled.hr`
	border-top: solid 1px rgb(179, 165, 157);
`

const listStyles = css`
	li {
		font-size: 0.9em;
	}
`
export const UL = styled.ul`
	${listStyles}
`

export const OL = styled.ol`
	${listStyles}
`

export const EM = styled.em``

export const Strong = styled.strong``

export const ctaStyles = css`
	text-transform: uppercase;
	font-weight: 500;
	border: none;
	outline: none;
	border-width: initial;
	border-style: none;
	border-color: initial;
	border-image: initial;
	transition: 0.3s;
	width: auto;
	display: inline-flex;
	white-space: nowrap;
	text-align: center;
	padding: 1em 1.3em;
	font-size: 1rem;
	&:hover {
		cursor: pointer;
	}
	${props => props.theme.media.sdesk} {
	}
	&.white {
		background: ${props => props.theme.colors.white.val};
		color: ${props => props.theme.colors.lightCta.val};
		&:hover {
			color: ${props => props.theme.colors.lightCta.val};
			background: ${props => props.theme.colors.whiteCtaBg.val};
		}
	}
	&.light {
		background: ${props => props.theme.colors.lightCtaBg.val};
		color: ${props => props.theme.colors.lightCta.val};
		&:hover {
			background: ${props => props.theme.colors.darkCtaBg.val};
			color: ${props => props.theme.colors.white.val};
		}
	}
	&.dark {
		background: ${props => props.theme.colors.darkCtaBg.val};
		color: ${props => props.theme.colors.white.val};
		&:hover {
			background: ${props => props.theme.colors.lightCtaBg.val};
			color: ${props => props.theme.colors.lightCta.val};
		}
	}
`

export const btnStyles = css`
	${props => (props.isCta ? ctaStyles : '')}

	&.cta {
		${ctaStyles}
	}
	.icon {
		transition: none;
	}
`

const Button = styled.button`
	border: none;
	outline: none;
	color: ${props => props.theme.colors.white.val};
	&:hover,
	&:active,
	&:focus {
		outline: none;
		border: none;
	}
	&:hover {
		color: ${props => props.theme.colors.orange.val};
	}
	${btnStyles}
`
export const Btn = ({ isCta, isDark, isLight, className, ...props }) => (
	<Button
		className={`button${className ? ` ${className}` : ''}${isCta ? ' cta' : ''}${
			isDark ? ' dark' : isLight ? ' light' : ''
		}`}
		{...props}
	/>
)

const linkStyles = css`
	outline: none;
	color: ${props => props.theme.colors.black.val};
	border: none;
	&:hover,
	&:active,
	&:focus {
		outline: none;
		border: none;
	}
	&:hover {
		color: ${props => props.theme.colors.slate.val};
	}
	&.styled {
		color: ${props => props.theme.colors.brown.val};
		border-bottom: solid 0.08em ${props => props.theme.colors.brown.val};
		&:hover {
			border-bottom: none;
			border-bottom: solid 0.08em transparent;
			color: ${props => props.theme.colors.brown.val};
		}
	}
	.icon {
		transition: none;
	}
`

const arrowCtaStyles = css`
	color: ${props => props.theme.colors.orange.val};
	font-size: 1em;
	font-weight: 500;
	display: block;
	border-bottom: none;
	&:hover {
		border: none;
		color: ${props => props.theme.colors.orange.val};
	}
	.icon {
		margin-left: 0.3em;
	}
`

const InternalLink = styled(ReachLink)`
	${linkStyles}
	&.cta {
		${ctaStyles}
	}
	&.cta-arrow {
		${arrowCtaStyles}
	}
`
const ExternalLink = styled.a`
	${linkStyles}
	&.cta {
		${ctaStyles}
	}
	&.cta-arrow {
		${arrowCtaStyles}
	}
`
export const Link = ({
	to,
	isInternal,
	isBlankTab,
	isPlain,
	isCta,
	isDark,
	isLight = true,
	isWhite,
	noStyles,
	className,
	...props
}) =>
	isAnchorLink(to) ? (
		<ExternalLink href={to} className='anchor-link' {...props} />
	) : isPlain ? (
		<ExternalLink href={to} className='plain-link' {...props} />
	) : isInternal ? (
		<InternalLink
			to={to || '#'}
			target={isBlankTab && '_blank'}
			className={`link internal-link${className ? ` ${className}` : ''}${isCta ? ' cta' : ''}${
				isDark ? ' dark' : isWhite ? ' white' : isLight ? ' light' : ''
			}${noStyles || isCta ? '' : ' styled'}`}
			{...props}
		/>
	) : (
		<ExternalLink
			href={isExternalLink(to) ? to : `https://acilearning.com${to}`}
			target={isBlankTab ? '_blank' : '_self'}
			rel='noopener noreferrer'
			className={`link external-link${className ? ` ${className}` : ''}${isCta ? ' cta' : ''}${
				isDark ? ' dark' : isWhite ? ' white' : isLight ? ' light' : ''
			}${noStyles || isCta ? '' : ' styled'}`}
			{...props}
		/>
	)

export const CTALink = ({ className, children, ...props }) => (
	<Link className={`cta-arrow${className ? ` ${className}` : ''}`} {...props}>
		{children}
		<Icon type='arrow' />
	</Link>
)
