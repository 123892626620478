import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x34_2_x5F_dispute_x5F_and_x5F_arguments'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='56px'
		viewBox='0 0 64 56'
		style={{ enableBackground: 'new 0 0 64 56' }}
	>
		<g>
			<g>
				<g>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M13,53h17
				c1.104,0,2-0.895,2-2v-1'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M10,35h3l6-9l1-4
				c0,0,0-3,3-3c2,0,2,3,2,3v3l-2,8h9c1.104,0,2,0.896,2,2v1c0,1.105-0.896,2-2,2h-4'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M33,45v1
				c0,1.105-0.896,2-2,2h-3'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M34,40v1
				c0,1.105-0.896,2-2,2h-4'
					/>
				</g>
				<polyline
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					points='0,35 10,35 
			10,55 0,55 		'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='7'
					y1='51'
					x2='5'
					y2='51'
				/>
			</g>
			<g>
				<g>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M51,7H34
				c-1.104,0-2,0.897-2,2v1'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M54,25h-3l-6,9l-1,4
				c0,0,0,3-3,3c-2,0-2-3-2-3v-3l2-8h-9c-1.104,0-2-0.895-2-2v-1c0-1.104,0.896-2,2-2h4'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M31,15v-1
				c0-1.104,0.896-2,2-2h3'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M30,20v-1
				c0-1.104,0.896-2,2-2h4'
					/>
				</g>
				<polyline
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					points='64,25 54,25 
			54,5 64,5 		'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='57'
					y1='9'
					x2='59'
					y2='9'
				/>
			</g>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='22'
				y1='8'
				x2='17'
				y2='3'
			/>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='26'
				y1='6'
				x2='26'
				y2='0'
			/>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='20'
				y1='12'
				x2='14'
				y2='12'
			/>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='47'
				y1='46'
				x2='52'
				y2='51'
			/>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='43'
				y1='48'
				x2='43'
				y2='54'
			/>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='49'
				y1='42'
				x2='55'
				y2='42'
			/>
		</g>
	</svg>
)
export default IconSVG
