import isString from 'lodash/isString'
import isInteger from 'lodash/isInteger'
import round from 'lodash/round'
import trim from 'lodash/trim'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Trunc = styled.span`
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
`


const Truncate = ({ children, chars, midDots }) => {
  let str = isString(children) ? children : ''
  if (isInteger(chars)) {
    if (midDots) {
      const newChars = round((chars * 0.5) - (midDots === 2 ? 7 : 3))
      str = str.length > chars ? `${trim(str.slice(0, newChars))}${midDots === 2 ? '... ...' : '...'}${trim(str.slice(-newChars))}` : str
    } else {
      str = str.length > chars ? `${trim(str.slice(0, chars))}...` : str
    }
  }

  return isString(children) && isInteger(chars) ? str : <Trunc className='truncated'>{str}</Trunc>
}
Truncate.propTypes = {
  children: PropTypes.string,
  chars: PropTypes.number,
  midDots: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
}
export default Truncate