import styled from 'styled-components'

export default styled.div`
	.block-content.badge {
		display: ${props => (props.hasIcon ? 'inline-flex' : 'inline-block')};
		width: auto;
		background: ${props => props.color || props.theme.colors.grey.val};
		color: ${props => props.theme.colors.white.val};
		padding: 1em;
		&.err {
			background: ${props => props.theme.colors.err.val};
		}
		&.ok {
			background: ${props => props.theme.colors.ok.val};
		}
		&.warn {
			background: ${props => props.theme.colors.warn.val};
		}
	}
`
