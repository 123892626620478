import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_5_x5F_head_x5F_hunting'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='58px'
		height='64px'
		viewBox='0 0 58 64'
		style={{ enableBackground: 'new 0 0 58 64' }}
	>
		<g>
			<g>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='37,55 5,55 5,1 45,1 45,25 		'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='45'
					y1='30'
					x2='45'
					y2='37'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='49'
					y1='32'
					x2='49'
					y2='37'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='53'
					y1='34'
					x2='53'
					y2='37'
				/>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='37,36 37,43 39,47 42,51 42,55 		'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='45'
					y1='59'
					x2='43'
					y2='59'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='36'
					y1='29'
					x2='41.001'
					y2='34.002'
				/>
				<path
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					d='M54,55v-2l3-10v-9
			c0-1.104-0.896-2-2-2c-1.105,0-2,0.896-2,2v-2c0-1.104-0.896-2-2-2c-1.105,0-2,0.896-2,2l0,0c0-1.104-0.896-2-2-2
			c-1.105,0-2,0.896-2,2v-2c0-1.104-0.896-2-2-2c-1.105,0-2,0.896-2,2v10l-8-8'
				/>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='25'
					cy='21'
					r='14'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='10'
					y1='41'
					x2='18'
					y2='41'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='10'
					y1='45'
					x2='24'
					y2='45'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='10'
					y1='49'
					x2='34'
					y2='49'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='20'
					y1='41'
					x2='34'
					y2='41'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='26'
					y1='45'
					x2='34'
					y2='45'
				/>
				<path
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					d='M40,64v-8
			c0-0.553,0.447-1,1-1h14c0.553,0,1,0.447,1,1v8'
				/>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='35,59 1,59 1,4 		'
				/>
				<g>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M16,32c0-6,6-8,6-8'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M34,32c0-6-6-8-6-8'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M30,18.5c0,3.5-1.963,6.5-5,6.5
				c-3.037,0-5-3-5-6.5s1-5.5,5-5.5S30,15,30,18.5z'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
