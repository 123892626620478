import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x34_6_x5F_quality_x5F_control_x5F_checklist'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64.001px'
		viewBox='0 0 64 64.001'
		style={{ enableBackground: 'new 0 0 64 64.001' }}
	>
		<g>
			<g>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='13,38.001 13,8.001 20,8.001 		'
				/>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='46,8.001 53,8.001 53,63.001 44,63.001 
					'
				/>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M38,6.001c0-3-2.238-5-5-5s-5,2-5,5h-5v4h20v-4
			H38z'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='32'
					y1='6.001'
					x2='34'
					y2='6.001'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='17'
					y1='36.001'
					x2='17'
					y2='8.001'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='49'
					y1='8.001'
					x2='49'
					y2='45.001'
				/>
				<g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='22'
						y1='21.001'
						x2='24'
						y2='21.001'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='26'
						y1='21.001'
						x2='44'
						y2='21.001'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='22'
						y1='25.001'
						x2='24'
						y2='25.001'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='26'
						y1='25.001'
						x2='44'
						y2='25.001'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='22'
						y1='17.001'
						x2='24'
						y2='17.001'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='26'
						y1='17.001'
						x2='44'
						y2='17.001'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='22'
						y1='29.001'
						x2='24'
						y2='29.001'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='26'
						y1='29.001'
						x2='44'
						y2='29.001'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='22'
						y1='33.001'
						x2='24'
						y2='33.001'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='26'
						y1='33.001'
						x2='44'
						y2='33.001'
					/>
				</g>
			</g>
			<g>
				<polygon
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='63,63.001 59,63.001 59,10 60,8.001 
			62,8.001 63,10 		'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='61'
					y1='8.001'
					x2='61'
					y2='5.001'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='63'
					y1='16.001'
					x2='59'
					y2='16.001'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='63'
					y1='20.001'
					x2='59'
					y2='20.001'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='63'
					y1='24.001'
					x2='59'
					y2='24.001'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='63'
					y1='59.001'
					x2='59'
					y2='59.001'
				/>
			</g>
			<path
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				d='M49,51.001
		c0,0-10.745,12-24,12s-24-12-24-12s10.745-12,24-12S49,51.001,49,51.001z'
			/>

			<circle
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				cx='25'
				cy='51.001'
				r='12'
			/>
			<path
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				d='M33,51.001
		c0,4.418-3.582,8-8,8s-8-3.582-8-8c0-4.418,3.582-8,8-8'
			/>
			<polyline
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				points='31,47.001 
		25,53.001 22,50.001 	'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='42'
				y1='63.001'
				x2='40'
				y2='63.001'
			/>
		</g>
	</svg>
)
export default IconSVG
