import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x33_6_x5F_mobile_x5F_security'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='60px'
		height='64px'
		viewBox='0 0 60 64'
		style={{ enableBackground: '0 0 60 64' }}
	>
		<g>
			<path
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				d='M37,43v15c0,2.762-2.238,5-5,5H6
		c-2.762,0-5-2.238-5-5V6c0-2.762,2.238-5,5-5h26c2.762,0,5,2.238,5,5v17'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='17'
				y1='59'
				x2='21'
				y2='59'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='14'
				y1='5'
				x2='12'
				y2='5'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='16'
				y1='5'
				x2='26'
				y2='5'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='1'
				y1='9'
				x2='37'
				y2='9'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='1'
				y1='55'
				x2='37'
				y2='55'
			/>
			<polyline
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				points='33,39 33,51 5,51 5,13 33,13 33,27 	'
			/>
			<g>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='48'
					cy='33'
					r='11'
				/>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='52'
					cy='33'
					r='3'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='22'
					y1='30'
					x2='37'
					y2='30'
				/>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='23,27 23,24 19,24 19,40 23,40 23,36 
			37,36 		'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='15'
					y1='23'
					x2='15'
					y2='25'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='15'
					y1='27'
					x2='15'
					y2='29'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='15'
					y1='31'
					x2='15'
					y2='33'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='15'
					y1='35'
					x2='15'
					y2='37'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='15'
					y1='39'
					x2='15'
					y2='41'
				/>
			</g>
		</g>
	</svg>
)
export default IconSVG
