import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x35_5_x5F_financial_x5F_data_x5F_analytics'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<path
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				d='M15,6.073C19.881,2.866,25.723,1,32,1
		c17.121,0,31,13.879,31,31S49.121,63,32,63c-3.873,0-7.58-0.71-10.998-2.007'
			/>
			<g>
				<rect
					x='12'
					y='40.999'
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					width='4'
					height='22'
				/>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M14,41v-4c7.18,0,13-5.82,13-13s-5.82-13-13-13
			S1,16.82,1,24c0,5.407,3.302,10.044,7.999,12.003'
				/>
			</g>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='32'
				y1='1'
				x2='32'
				y2='32'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='32'
				y1='32'
				x2='20'
				y2='44'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='32'
				y1='32'
				x2='44'
				y2='32'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='32'
				y1='32'
				x2='54'
				y2='54'
			/>
			<path
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				d='M32,5c14.912,0,27,12.088,27,27S46.912,59,32,59'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='46'
				y1='32'
				x2='48'
				y2='32'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='50'
				y1='32'
				x2='52'
				y2='32'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='54'
				y1='32'
				x2='56'
				y2='32'
			/>
			<g>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='11,28 16,28 16,25 12,23 12,20 17,20 		
			'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='14'
					y1='28'
					x2='14'
					y2='31'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='14'
					y1='17'
					x2='14'
					y2='20'
				/>
			</g>
		</g>
	</svg>
)
export default IconSVG
