import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x31_6_x5F_cloud_x5F_computing'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='60px'
		viewBox='0 0 64 60'
		style={{ enableBackground: 'new 0 0 64 60' }}
	>
		<g>
			<g>
				<g>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M51,41c6.627,0,12-5.373,12-12
				c0-5.947-4-11-10-11c0-5-3-9-9-9h-1c-2.728-4.924-7.972-8-14-8c-8.836,0-16,7.164-16,16C6.373,17,1,22.373,1,29
				c0,6.627,5.373,12,12,12'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M19,18c0-6.075,5-11,10-11'
					/>
				</g>
				<path
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					d='M48.15,33.09l-5.471,0.151
			l1.836-5.161c-1.212-1.176-2.585-2.183-4.088-2.98l-4.349,3.346l-1.546-5.246C33.703,23.082,32.862,23,32,23
			s-1.703,0.082-2.533,0.199l-1.546,5.246L23.572,25.1c-1.503,0.798-2.876,1.805-4.088,2.98l1.836,5.161L15.85,33.09
			c-0.736,1.501-1.269,3.115-1.565,4.816L18.8,41l-4.516,3.094c0.296,1.701,0.829,3.315,1.565,4.816l5.471-0.151l-1.836,5.161
			c1.212,1.176,2.585,2.183,4.088,2.98l4.349-3.346l1.546,5.246C30.297,58.918,31.138,59,32,59s1.703-0.082,2.533-0.199l1.546-5.246
			l4.349,3.346c1.503-0.798,2.876-1.805,4.088-2.98l-1.836-5.161l5.471,0.151c0.736-1.501,1.27-3.115,1.565-4.816L45.2,41
			l4.516-3.094C49.42,36.205,48.887,34.591,48.15,33.09z'
				/>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='32'
					cy='41'
					r='9'
				/>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='32'
					cy='41'
					r='5'
				/>
			</g>
		</g>
	</svg>
)
export default IconSVG
