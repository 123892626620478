import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_6_x5F_professional_x5F_speaker'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<g>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='1,64 1,57 63,57 63,64 			'
					/>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='51,32 61,42 61,57 			'
					/>
					<circle
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						cx='49'
						cy='30'
						r='3'
					/>
					<g>
						<g>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='25'
								y1='35'
								x2='25'
								y2='29'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='37'
								y1='35'
								x2='37'
								y2='29'
							/>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								d='M42,15h-1c-1.105,0-2-0.895-2-2v-2
						c0-1.104-0.896-2-2-2V7l-4,2h-8c-1.105,0-2,0.896-2,2v2c0,1.105-0.896,2-2,2'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='21'
								y1='15'
								x2='20'
								y2='15'
							/>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								d='M20,7v11l1,1c0,5,3,12,10,12s10-7,10-12l1-1V5
						c0-2.209-1.791-4-4-4H27c-1.656,0-3,1.344-3,3h-1C21.344,4,20,5.344,20,7z'
							/>
							<polygon
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								points='27,43 18,39 23,35 31,37 31,39 					
						'
							/>
							<polygon
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								points='35,43 44,39 39,35 31,37 31,39 					
						'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='31'
								y1='48'
								x2='31'
								y2='50'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='31'
								y1='52'
								x2='31'
								y2='54'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='31'
								y1='44'
								x2='31'
								y2='46'
							/>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								d='M18,39l-3,1C9,42,5,43.925,5,50v7'
							/>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								d='M44,39l3,1c6,2,10,3.925,10,10v7'
							/>
						</g>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='47'
							y1='57'
							x2='47'
							y2='52'
						/>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='15'
							y1='57'
							x2='15'
							y2='52'
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
