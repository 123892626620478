import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_5_x5F_online_x5F_consulting'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<g>
					<g>
						<g>
							<polygon
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								points='59,63 5,63 1,59 1,57 25,57 27,59 
						37,59 39,57 63,57 63,59 					'
							/>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								d='M9,23c-1.656,0-3,1.343-3,3v31'
							/>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								d='M58,57V26c0-1.657-1.344-3-3-3'
							/>
						</g>
					</g>
					<g>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M28,35.5c0,3.5-1.963,6.5-5,6.5
					c-3.037,0-5-3-5-6.5s1-5.5,5-5.5S28,32,28,35.5z'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M26,41l7,4c0,0,0,0.344,0,2c0,1.657-1,3-2,3H15
					c-1,0-2-1.343-2-3c0-1.656,0-2,0-2l7-4'
						/>
					</g>
					<g>
						<path
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							d='M52,21
					c0,1.104-0.896,2-2,2h-4L36,33V23H14c-1.105,0-2-0.896-2-2V3c0-1.105,0.895-2,2-2h36c1.104,0,2,0.895,2,2V21z'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='31'
							y1='12'
							x2='33'
							y2='12'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='35'
							y1='12'
							x2='37'
							y2='12'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='27'
							y1='12'
							x2='29'
							y2='12'
						/>
					</g>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='51,35 51,50 37,50 			'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='48'
						y1='38'
						x2='39'
						y2='38'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='48'
						y1='42'
						x2='39'
						y2='42'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='48'
						y1='46'
						x2='39'
						y2='46'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
