import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x33_5_x5F_data_x5F_science'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<ellipse
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='32'
				cy='32'
				rx='15'
				ry='31'
			/>
			<ellipse
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='32'
				cy='32'
				rx='31'
				ry='15'
			/>

			<ellipse
				transform='matrix(0.7071 0.7071 -0.7071 0.7071 32 -13.2548)'
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='32'
				cy='32'
				rx='15'
				ry='30.999'
			/>

			<ellipse
				transform='matrix(-0.7071 0.7071 -0.7071 -0.7071 77.2548 32)'
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='32'
				cy='32'
				rx='15'
				ry='30.999'
			/>
			<ellipse
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='32'
				cy='26'
				rx='7'
				ry='3'
			/>
			<path
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				d='M39,30c0,1.656-3.134,3-7,3c-3.866,0-7-1.344-7-3'
			/>
			<path
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				d='M39,34c0,1.656-3.134,3-7,3c-3.866,0-7-1.344-7-3'
			/>
			<path
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				d='M39,26v12c0,1.656-3.134,3-7,3
		c-3.866,0-7-1.344-7-3V26'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='49'
				y1='14'
				x2='51'
				y2='14'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='49'
				y1='50'
				x2='51'
				y2='50'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='13'
				y1='50'
				x2='15'
				y2='50'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='13'
				y1='14'
				x2='15'
				y2='14'
			/>
		</g>
	</svg>
)
export default IconSVG
