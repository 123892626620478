import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_3_x5F_business_x5F_idea'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='46px'
		height='64px'
		viewBox='0 0 46 64'
		style={{ enableBackground: 'new 0 0 46 64' }}
	>
		<g>
			<g>
				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='9'
					y1='51'
					x2='37'
					y2='51'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='9'
					y1='55'
					x2='37'
					y2='55'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='9'
					y1='59'
					x2='37'
					y2='59'
				/>
				<polyline
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					points='27,59 27,63 
			19,63 19,59 		'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='19'
					y1='48'
					x2='19'
					y2='21'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='27'
					y1='48'
					x2='27'
					y2='21'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='16'
					y1='21'
					x2='30'
					y2='21'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='19'
					y1='37'
					x2='27'
					y2='37'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='23'
					y1='34'
					x2='23'
					y2='32'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='23'
					y1='30'
					x2='23'
					y2='28'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='23'
					y1='26'
					x2='23'
					y2='24'
				/>
				<path
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					d='M12,59l-0.001-16.986
			C5.424,38.014,1,31.129,1,22.986C1,10.836,10.85,1,23,1c12.15,0,22,9.828,22,21.979c0,8.143-4,15.035-11,19.035V59'
				/>
				<path
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					d='M14.998,31.943
			C12.544,29.746,11,26.553,11,23c0-6.627,5.373-12,12-12c6.627,0,12,5.373,12,12c0,3.554-1.545,6.748-4,8.945'
				/>
			</g>
		</g>
	</svg>
)
export default IconSVG
