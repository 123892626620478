import React from 'react'
import PropTypes from 'prop-types'
import HSForm from 'react-hubspot-form'
import { BGBlocks } from '../BG'

import View from './Hubspot.Form.view'
import { Spinner } from '../Loaders'
import { useScript, useState } from '../../../utils'

const HubspotForm = ({ portalId, formId, children, className }) => {
	useScript('//js.chilipiper.com/marketing.js')
	const [isReady, setIsReady] = useState(false, 'isReady')
	return !portalId || !formId ? null : (
		<View className={`hubspot${isReady ? '' : '__loading'}${className ? ` ${className}` : ''}`}>
			<BGBlocks />
			{children ? <div className='content'>{children}</div> : null}
			<div className='hs-render-target'>
				<HSForm
					portalId={portalId}
					formId={formId}
					onSubmit={e => {
						return e
					}}
					onReady={() => setIsReady(true)}
					loading={<Spinner />}
				/>
			</div>
		</View>
	)
}
HubspotForm.propTypes = {
	portalId: PropTypes.string,
	formId: PropTypes.string,
}
export default HubspotForm
