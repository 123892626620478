import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import Icon from '../Icons'

const styles = css`
	display: inline-block;
	padding: 1px 0.5em;
	margin: 2px 0.3em;
	background: ${props => props.theme.colors.white.val};
	border: solid 1px ${props => props.theme.colors.skyBlue.desat().light().val};
	color: ${props => props.theme.colors.black.val};
	font-size: 0.8em;
	${props => props.theme.media.sdesk} {
		font-size: 16px;
	}
	.icon {
		color: ${props => props.theme.colors.skyBlue.desat().light().val};
	}
	&.btn {
		&:hover {
			color: ${props => props.theme.colors.black.val};
			border-color: ${props => props.theme.colors.skyBlue.desat().val};
		}
	}
`

const Btn = styled.button`
	${styles}
`

const StaticTag = styled.div`
	${styles}
`

const Tag = ({ children, onClick, isRemove }) => {
	return onClick ? (
		<Btn onClick={onClick} className='btn tag'>
			{isRemove ? <Icon type='x' /> : null}
			{children}
		</Btn>
	) : (
		<StaticTag className='tag'>{children}</StaticTag>
	)
}
Tag.propTypes = {
	onClick: PropTypes.func,
	isRemove: PropTypes.bool,
}
export default Tag
