import { css } from 'styled-components'

import fira100 from './FiraSans-Thin.ttf'
import fira200 from './FiraSans-ExtraLight.ttf'
import fira300 from './FiraSans-Light.ttf'
import fira400 from './FiraSans-Regular.ttf'
import fira500 from './FiraSans-Medium.ttf'
import fira600 from './FiraSans-SemiBold.ttf'
import fira700 from './FiraSans-Bold.ttf'
import fira800 from './FiraSans-ExtraBold.ttf'
import fira900 from './FiraSans-Black.ttf'

// import firaItalic from './FiraSans-Italic.ttf'

const fonts = css`
	@font-face {
		font-family: 'Fira Sans';
		src: url(${fira100}) format('truetype'),
			url('https://fonts.gstatic.com/s/firasans/v10/va9C4kDNxMZdWfMOD5Vn9LjJYTLVdlTO.woff2')
				format('woff2');
		font-weight: 100;
		font-style: normal;
		font-display: swap;
	}
	@font-face {
		font-family: 'Fira Sans';
		src: url(${fira200}) format('truetype'),
			url('https://fonts.gstatic.com/s/firasans/v10/va9B4kDNxMZdWfMOD5VnWKneRhf6Xl7Glw.woff2')
				format('woff2');
		font-weight: 200;
		font-style: normal;
		font-display: swap;
	}
	@font-face {
		font-family: 'Fira Sans';
		src: url(${fira300}) format('truetype'),
			url('https://fonts.gstatic.com/s/firasans/v10/va9B4kDNxMZdWfMOD5VnPKreRhf6Xl7Glw.woff2')
				format('woff2');
		font-weight: 300;
		font-style: normal;
		font-display: swap;
	}
	@font-face {
		font-family: 'Fira Sans';
		src: url(${fira400}) format('truetype'),
			url('https://fonts.gstatic.com/s/firasans/v10/va9E4kDNxMZdWfMOD5Vvl4jLazX3dA.woff2')
				format('woff2');
		font-weight: 400;
		font-style: normal;
		font-display: swap;
	}
	@font-face {
		font-family: 'Fira Sans';
		src: url(${fira500}) format('truetype'),
			url('https://fonts.gstatic.com/s/firasans/v10/va9B4kDNxMZdWfMOD5VnZKveRhf6Xl7Glw.woff2')
				format('woff2');
		font-weight: 500;
		font-style: normal;
		font-display: swap;
	}
	@font-face {
		font-family: 'Fira Sans';
		src: url(${fira600}) format('truetype'),
			url('https://fonts.gstatic.com/s/firasans/v10/va9B4kDNxMZdWfMOD5VnSKzeRhf6Xl7Glw.woff2')
				format('woff2');
		font-weight: 600;
		font-style: normal;
		font-display: swap;
	}
	@font-face {
		font-family: 'Fira Sans';
		src: url(${fira700}) format('truetype'),
			url('https://fonts.gstatic.com/s/firasans/v10/va9B4kDNxMZdWfMOD5VnLK3eRhf6Xl7Glw.woff2')
				format('woff2');
		font-weight: 700;
		font-style: normal;
		font-display: swap;
	}
	@font-face {
		font-family: 'Fira Sans';
		src: url(${fira800}) format('truetype'),
			url('https://fonts.gstatic.com/s/firasans/v10/va9B4kDNxMZdWfMOD5VnMK7eRhf6Xl7Glw.woff2')
				format('woff2');
		font-weight: 800;
		font-style: normal;
		font-display: swap;
	}
	@font-face {
		font-family: 'Fira Sans';
		src: url(${fira900}) format('truetype'),
			url('https://fonts.gstatic.com/s/firasans/v10/va9B4kDNxMZdWfMOD5VnFK_eRhf6Xl7Glw.woff2')
				format('woff2');
		font-weight: 900;
		font-style: normal;
		font-display: swap;
	}
`

export default fonts
