import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_4_x5F_global_x5F_business'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='58px'
		height='64px'
		viewBox='0 0 58 64'
		style={{ enableBackground: 'new 0 0 58 64' }}
	>
		<g>
			<g>
				<g>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M10.021,23C13.392,15.905,20.623,11,29,11
				s15.608,4.905,18.978,12'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M47.978,41C44.608,48.095,37.377,53,29,53
				c-8.376,0-15.606-4.903-18.978-11.996'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M32,4c14.056,1.494,25,13.548,25,28
				c0,9.515-4.746,17.921-12,22.981'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M26,60C11.943,58.506,1,46.453,1,32
				c0-9.516,4.746-17.922,12-22.981'
					/>
					<circle
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						cx='29'
						cy='4'
						r='3'
					/>
					<circle
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						cx='29'
						cy='60'
						r='3'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='11'
						y1='32'
						x2='47'
						y2='32'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M44,17.139C40.523,19.488,35.098,21,29,21
				c-6.098,0-11.523-1.512-15-3.861'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M14,46.861C17.477,44.512,22.902,43,29,43
				c6.098,0,11.523,1.512,15,3.861'
					/>
					<g>
						<circle
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							cx='8'
							cy='32'
							r='3'
						/>
					</g>
					<g>
						<circle
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							cx='50'
							cy='32'
							r='3'
						/>
					</g>
					<ellipse
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						cx='29'
						cy='32'
						rx='7'
						ry='21'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
