import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_5_x5F_team_x5F_efforts'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='63'
						y1='12'
						x2='63'
						y2='42'
					/>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='56,1 63,1 63,8 			'
					/>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='1,15 1,1 52,1 			'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='4'
						y1='37'
						x2='60'
						y2='37'
					/>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='1,42 1,21 9,13 20,24 34,10 44,20 
				63,1 			'
					/>
					<g>
						<polyline
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							points='14,54 21,58 21,58 29,54 				'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M56,54l7,4c0,0,0,0.344,0,2c0,1.657-1,3-2,3H3
					c-1,0-2-1.343-2-3c0-1.656,0-2,0-2l7-4'
						/>
						<polyline
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							points='35,54 43,58 43,58 50,54 				'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M16,48.5c0,3.5-1.963,6.5-5,6.5
					c-3.037,0-5-3-5-6.5S7,43,11,43S16,45,16,48.5z'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M37,48.5c0,3.5-1.963,6.5-5,6.5
					c-3.037,0-5-3-5-6.5s1-5.5,5-5.5S37,45,37,48.5z'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M58,48.5c0,3.5-1.963,6.5-5,6.5
					c-3.037,0-5-3-5-6.5s1-5.5,5-5.5S58,45,58,48.5z'
						/>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='21'
							y1='58'
							x2='21'
							y2='61'
						/>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='43'
							y1='58'
							x2='43'
							y2='61'
						/>
					</g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='8'
						y1='33'
						x2='16'
						y2='33'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='8'
						y1='29'
						x2='16'
						y2='29'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='18'
						y1='33'
						x2='26'
						y2='33'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='18'
						y1='29'
						x2='26'
						y2='29'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='28'
						y1='33'
						x2='36'
						y2='33'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='28'
						y1='29'
						x2='36'
						y2='29'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='28'
						y1='25'
						x2='36'
						y2='25'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='28'
						y1='21'
						x2='36'
						y2='21'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='38'
						y1='33'
						x2='46'
						y2='33'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='38'
						y1='29'
						x2='46'
						y2='29'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='38'
						y1='25'
						x2='46'
						y2='25'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='48'
						y1='33'
						x2='56'
						y2='33'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='48'
						y1='29'
						x2='56'
						y2='29'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='48'
						y1='25'
						x2='56'
						y2='25'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='48'
						y1='21'
						x2='56'
						y2='21'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='8'
						y1='25'
						x2='16'
						y2='25'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
