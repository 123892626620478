import styled from 'styled-components'

export default styled.div`
	padding: 1em;
	padding-bottom: 50px;
	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: -1;
		opacity: 0.8;
		background: linear-gradient(
			to bottom,
			rgba(178, 226, 242, 1) 0%,
			rgba(220, 242, 248, 0.84) 16%,
			rgba(255, 255, 255, 0) 100%
		);
		${props => props.theme.media.sdesk} {
			background: linear-gradient(
				to bottom,
				rgba(220, 242, 248, 1) 0%,
				rgba(255, 255, 255, 0) 100%
			);
		}
	}

	.hs-render-target {
		padding: 0;
		max-width: 500px;
		margin: 0 auto;
		padding-bottom: 93px;
		z-index: 1;
		@media (min-width: 1100px) {
			background: ${props => props.theme.colors.white.val};
			max-width: 600px;
			padding: 2em 50px;
			box-shadow: 0px 1px 3px ${props => props.theme.colors.grey.tint(50).val};
			padding-bottom: 123px;
		}
		&.__loading {
			/* form is still loading */
		}
		label,
		span,
		p,
		legend {
			color: ${props => props.theme.colors.slate.val};
			font-family: 'Fira Sans', 'Helvetica', 'Arial', sans-serif;
			line-height: 1.3rem;
		}
		p,
		label,
		span {
			font-weight: 500;
		}
		h1,
		h2 {
			text-align: left;
			width: 100%;
			margin: 0;
			padding: 0;
			margin: 0;
			margin-bottom: 1em;
			font-size: 25px;
			color: ${props => props.theme.colors.slate.val};
			font-weight: 500;
			${props => props.theme.media.tablet} {
				font-size: 30px;
			}
			${props => props.theme.media.sdesk} {
				font-weight: 600;
				line-height: 1.1em;
			}
		}
		input,
		select {
			box-shadow: none;
			border: solid 1px ${props => props.theme.colors.grey.light().val};
			padding: 1em;
			background: ${props => props.theme.colors.white.val};
			font-size: 15px;
			width: 100%;
			&:focus {
				outline: none;
				border-color: ${props => props.theme.colors.skyBlue.val};
			}
			@media (min-width: 1100px) {
				padding: 0.5em;
			}
		}
		input,
		.hs-input {
			width: 100%;
		}
		em {
			color: inherit;
		}
		ul {
			list-style-type: none;
			margin-left: 0;
			li {
				margin: 0.4em 0;
				font-weight: 500;
			}
		}
		ul.multi-container {
			display: flex;
			flex-wrap: wrap;
			li {
				width: 50%;
				${props => props.theme.media.tablet} {
					width: 33.33%;
				}
			}
		}
		.form-columns-2 {
			.hs-form-field {
				${props => props.theme.media.sdesk} {
					padding-right: 8px;
					&:last-child {
						padding-right: 0;
					}
				}
			}
		}
		.hs-form-field {
			margin: 1em 0;
			.input {
				margin-right: 0;
			}
			textarea {
				background-color: #ffffff;
			}
		}
		.hs-form-booleancheckbox,
		.hs-form-checkbox {
			padding: 1em 0;
			label {
				display: flex;
			}
			input {
				width: auto !important;
			}
			span {
				display: inline;
				margin-left: 0.5em;
			}
		}

    .hs-recaptcha {
			/* position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			z-index: 1;
			margin: 0;
			@media (min-width: 1100px) {
				bottom: 25px;
			}
			*/
			& > .input {
				display: flex;
				justify-content: center;
				background: ${props => props.theme.colors.white.val};
				&:before,
				&:after {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					bottom: 0;
					width: 50%;
					z-index: 0;
				}
				&:before {
					left: 0;
					background: rgb(74, 144, 226);
				}
				&:after {
					right: 0;
					background: rgb(249, 249, 249);
				}
				.grecaptcha-badge {
					box-shadow: none !important;
					position: relative;
					z-index: 1;
				}
			}
		}
	
		.hs-submit {
		}
		.hs-button {
			width: 100%;
			color: ${props => props.theme.colors.white.val};
			background: ${props => props.theme.colors.slate.val};
			text-transform: uppercase;
			border: none;
			font-weight: 500;
			margin-top: 20px;
			padding: 1em;
			&:hover {
				background: ${props => props.theme.colors.slate.light().val};
			}
		}
		.hs-error-msg,
		.hs-error-msgs {
			span,
			label,
			legend {
				color: ${props => props.theme.colors.err.val};
			}
		}
	}
	.progress-bar {
		position: absolute;
		top: 0;
		left: 0;
	}
	.content {
		z-index: 1;
		max-width: 600px;
		margin: 0 auto;
		h2 {
			max-width: 100%;
		}
	}
	.bg {
		width: 50%;
	}
`
