import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Simple from './Block.simple'
import Banner from './Block.banner'
import Overlay from './Block.overlay'
import Badge from './Block.badge'

const ContentWrap = styled.div`
	text-align: left;
	display: ${props => (!props.hasHeader && props.hasIcon ? 'flex' : 'block')};
	align-items: center;
	border: ${props => (props.hasBorder ? `solid 1px` : 'none')};
	margin-bottom: 1em;
	header {
		display: flex;
		align-items: center;
		padding: 1em;
		/* border-bottom: solid 1px ${props => props.theme.colors.grey.val}; */
		h3 {
			font-size: 1.5em;
			font-weight: 400;
		}
	}
	&.err {
		color: ${props => (props.hasHeader ? props.theme.colors.white.val : props.theme.colors.err.val)};
		border-color: ${props => props.theme.colors.err.val};
		header {
			border: none;
			background: ${props => props.theme.colors.err.val};
		}
	}
	&.ok {
		color: ${props => props.theme.colors.ok.val};
		border-color: ${props => props.theme.colors.ok.val};
	}
	&.warn {
		color: ${props => props.theme.colors.warn.val};
		border-color: ${props => props.theme.colors.warn.val};
	}
	&.helper, &.info {
		color: ${props => props.theme.colors.grey.val};
	}
	.icon {
		padding-right: 0.5em;
		padding-left: ${props => (props.hasBorder ? '0.5em' : '0')};
		height: 1.5em;
	}
	.content-wrap {
		${props =>
		props.hasHeader &&
		`
			padding: 1em;
		`}
	}

	${props => (props.theme.css.block ? props.theme.css.block : '')}
`

const B = ({ usage = 'simple', children, ...props }) => (
	<React.Fragment>
		{usage === 'banner' && <Banner {...props}>{children}</Banner>}
		{usage === 'badge' && <Badge {...props}>{children}</Badge>}
		{usage === 'overlay' && <Overlay {...props}>{children}</Overlay>}
		{usage === 'simple' && <Simple {...props}>{children}</Simple>}
	</React.Fragment>
)

const Block = ({ usage, type, children, hasBorder, title, ...props }) => {
	return (
		<B usage={usage} className={type} {...props}>
			<ContentWrap
				hasHeader={!!title}
				className={`block-content ${type || ''} ${usage}`}
				hasBorder={hasBorder}
			>
				{title && (
					<header>
						<h3>{title}</h3>
					</header>
				)}
				<div className='content-wrap'>{children}</div>
			</ContentWrap>
		</B>
	)
}
Block.propTypes = {
	usage: PropTypes.string,
	type: PropTypes.string,
}
export default Block
