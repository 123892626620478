import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_5_x5F_career_x5F_advancement'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<g>
					<g>
						<polyline
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='8,26 12,30 
					16,26 				'
						/>
						<path
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							d='M38,11H19
					c-3.866,0-7,3.134-7,7v12'
						/>
					</g>
					<g>
						<polyline
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='56,36 52,32 
					48,36 				'
						/>
						<path
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							d='M26,51h19
					c3.866,0,7-3.134,7-7V32'
						/>
					</g>
					<g>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M27,26.5c0,3.5,1.963,6.5,5,6.5
					c3.037,0,5-3,5-6.5S36,21,32,21S27,23,27,26.5z'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M29,32l-7,4c0,0,0,0.344,0,2c0,1.657,1,3,2,3
					h16c1,0,2-1.343,2-3c0-1.656,0-2,0-2l-7-4'
						/>
					</g>
					<g>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M6,48.5C6,52,7.963,55,11,55c3.037,0,5-3,5-6.5
					S15,43,11,43S6,45,6,48.5z'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M8,54l-7,4c0,0,0,0.344,0,2c0,1.657,1,3,2,3h16
					c1,0,2-1.343,2-3c0-1.656,0-2,0-2l-7-4'
						/>
					</g>
					<g>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M48,6.5c0,3.5,1.963,6.5,5,6.5
					c3.037,0,5-3,5-6.5S57,1,53,1S48,3,48,6.5z'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M50,12l-7,4c0,0,0,0.344,0,2c0,1.657,1,3,2,3
					h16c1,0,2-1.343,2-3c0-1.656,0-2,0-2l-7-4'
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
