import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_3_x5F_online_x5F_conference'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<g>
					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='15'
						y1='29'
						x2='49'
						y2='29'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M19,21h-7
				C5.925,21,1,24.925,1,31v4c0,1.105,0.895,2,2,2h13c1.104,0,2-0.895,2-2v-6'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M46,29v6
				c0,1.105,0.895,2,2,2h13c1.104,0,2-0.895,2-2v-4c0-6.075-4.925-10-11-10h-7'
					/>
					<g>
						<path
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							d='M37,6.5
					c0,3.5-1.963,6.5-5,6.5c-3.037,0-5-3-5-6.5S28,1,32,1S37,3,37,6.5z'
						/>
						<path
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							d='M35,12l7,4
					c0,0,0,0.344,0,2c0,1.657-1,3-2,3H24c-1,0-2-1.343-2-3c0-1.656,0-2,0-2l7-4'
						/>
					</g>
					<g>
						<path
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							d='M16,48.5
					c0,3.5-1.963,6.5-5,6.5c-3.037,0-5-3-5-6.5S7,43,11,43S16,45,16,48.5z'
						/>
						<path
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							d='M14,54l7,4
					c0,0,0,0.344,0,2c0,1.657-1,3-2,3H3c-1,0-2-1.343-2-3c0-1.656,0-2,0-2l7-4'
						/>
					</g>
					<g>
						<path
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							d='M58,48.5
					c0,3.5-1.963,6.5-5,6.5c-3.037,0-5-3-5-6.5s1-5.5,5-5.5S58,45,58,48.5z'
						/>
						<path
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							d='M56,54l7,4
					c0,0,0,0.344,0,2c0,1.657-1,3-2,3H45c-1,0-2-1.343-2-3c0-1.656,0-2,0-2l7-4'
						/>
					</g>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='27'
						y1='33'
						x2='29'
						y2='33'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='31'
						y1='33'
						x2='33'
						y2='33'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='35'
						y1='33'
						x2='37'
						y2='33'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M28,36v12
				c0,0.553-0.447,1-1,1h-8'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M36,36v12
				c0,0.553,0.447,1,1,1h8'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='32'
						y1='36'
						x2='32'
						y2='50'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='1'
						y1='33'
						x2='18'
						y2='33'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='46'
						y1='33'
						x2='63'
						y2='33'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='19'
						y1='63'
						x2='45'
						y2='63'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
