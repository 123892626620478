import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_4_x5F_office_x5F_supplies'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='48px'
		height='64px'
		viewBox='0 0 48 64'
		style={{ enableBackground: 'new 0 0 48 64' }}
	>
		<g>
			<g>
				<g>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M37,61
				c0,1.105-0.896,2-2,2H13c-1.104,0-2-0.895-2-2L7,25c0-1.104,0.896-2,2-2h30c1.104,0,2,0.896,2,2L37,61z'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='11'
						y1='59'
						x2='37'
						y2='59'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='7'
						y1='27'
						x2='41'
						y2='27'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='33.105,10 35,3 
				39,1 38.105,10 			'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='31,20 32,10 
				39,10 38,20 			'
					/>
					<g>
						<polyline
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='20,20 20,1 
					26,1 26,20 				'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='20'
							y1='5'
							x2='23'
							y2='5'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='20'
							y1='9'
							x2='23'
							y2='9'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='20'
							y1='13'
							x2='23'
							y2='13'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='20'
							y1='17'
							x2='23'
							y2='17'
						/>
					</g>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='15,20 13,2 
				12,1 9,1 8,2 10,20 			'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='38'
						y1='14'
						x2='36'
						y2='14'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='9'
						y1='7'
						x2='13'
						y2='7'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='23'
						y1='31'
						x2='25'
						y2='31'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='19'
						y1='31'
						x2='21'
						y2='31'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='27'
						y1='31'
						x2='29'
						y2='31'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='0'
						y1='63'
						x2='48'
						y2='63'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
