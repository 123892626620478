import React from 'react'
const IconSVG = ({ props }) => (
	<svg xmlns='http://www.w3.org/2000/svg' width='824' height='299.574' viewBox='0 0 824 299.574'>
		<g id='Group_267' dataname='Group 267' transform='translate(-133 -1743.429)'>
			<g
				id='_31_business_leader_avatar'
				dataname='31_business_leader_avatar'
				transform='translate(138 1748.429)'
			>
				<g id='Group_131' dataname='Group 131' transform='translate(0 0)'>
					<g id='Group_130' dataname='Group 130'>
						<g id='Group_128' dataname='Group 128'>
							<path
								id='Path_408'
								dataname='Path 408'
								d='M359,10212l14.027,4.679c28.054,9.349,51.433,23.025,51.433,51.433v70.135'
								transform='translate(-181.322 -10043.674)'
								fill='none'
								stroke='currentColor'
								strokeLinejoin='round'
								strokeWidth='10'
							/>
							<line
								id='Line_121'
								dataname='Line 121'
								y2='23.525'
								transform='translate(150.062 130.92)'
								fill='none'
								stroke='currentColor'
								strokeLinejoin='round'
								strokeWidth='10'
							/>
							<path
								id='Path_409'
								dataname='Path 409'
								d='M386.46,10212l-14.027,4.679c-28.054,9.349-51.433,23.025-51.433,51.433v70.135'
								transform='translate(-321 -10043.674)'
								fill='none'
								stroke='currentColor'
								strokeLinejoin='round'
								strokeWidth='10'
							/>
							<line
								id='Line_122'
								dataname='Line 122'
								y2='23.525'
								transform='translate(93.807 130.92)'
								fill='none'
								stroke='currentColor'
								strokeLinejoin='round'
								strokeWidth='10'
							/>
							<line
								id='Line_123'
								dataname='Line 123'
								y2='37.844'
								transform='translate(46.757 256.727)'
								fill='none'
								stroke='currentColor'
								strokeLinejoin='round'
								strokeWidth='10'
							/>
							<line
								id='Line_124'
								dataname='Line 124'
								y2='37.844'
								transform='translate(196.088 256.727)'
								fill='none'
								stroke='currentColor'
								strokeLinejoin='round'
								strokeWidth='10'
							/>
							<path
								id='Path_410'
								dataname='Path 410'
								d='M336,10207.381l14.027-9.354h32.73L396.784,10184l28.054,14.027,14.027,9.354v18.7l-4.676,4.675c0,23.381-14.027,56.11-46.757,56.11s-46.757-32.729-46.757-56.11l-4.676-4.675Z'
								transform='translate(-265.864 -10146.595)'
								fill='none'
								stroke='currentColor'
								strokeLinejoin='round'
								strokeWidth='10'
							/>
							<path
								id='Path_411'
								dataname='Path 411'
								d='M339.676,10236.789,335,10232.11v-32.729L349.027,10176h0l9.351,9.353,18.7-9.353,18.7,9.353h28.054a23.375,23.375,0,0,1,23.379,23.377v23.377l-4.676,4.679'
								transform='translate(-269.54 -10176.004)'
								fill='none'
								stroke='currentColor'
								strokeLinejoin='round'
								strokeWidth='10'
							/>
							<path
								id='Path_412'
								dataname='Path 412'
								d='M371.406,10209l23.379,32.729L418.163,10209h18.7l18.7,9.353-46.757,46.758H380.757L334,10218.353l18.7-9.353Z'
								transform='translate(-273.216 -10054.7)'
								fill='none'
								stroke='currentColor'
								strokeLinejoin='round'
								strokeWidth='10'
							/>
						</g>
					</g>
				</g>
			</g>
			<g
				id='_31_manager_avatar'
				dataname='31_manager_avatar'
				transform='translate(534.561 1748.429)'
			>
				<g id='Group_134' dataname='Group 134' transform='translate(0 0)'>
					<g id='Group_133' dataname='Group 133'>
						<g id='Group_132' dataname='Group 132'>
							<line
								id='Line_125'
								dataname='Line 125'
								y2='37.844'
								transform='translate(47.05 256.727)'
								fill='none'
								stroke='currentColor'
								strokeLinejoin='round'
								strokeWidth='10'
							/>
							<line
								id='Line_126'
								dataname='Line 126'
								y2='37.844'
								transform='translate(196.381 256.727)'
								fill='none'
								stroke='currentColor'
								strokeLinejoin='round'
								strokeWidth='10'
							/>
							<path
								id='Path_413'
								dataname='Path 413'
								d='M667.569,10363.027v-140.27A46.756,46.756,0,0,0,620.812,10176v9.349L592.758,10176A46.755,46.755,0,0,0,546,10222.758v140.27'
								transform='translate(-485.216 -10176)'
								fill='none'
								stroke='currentColor'
								strokeLinejoin='round'
								strokeWidth='10'
							/>
							<path
								id='Path_414'
								dataname='Path 414'
								d='M550,10211.052v14.027c0,33.573,14.027,65.461,42.082,65.461s42.082-31.888,42.082-65.461v-14.027a14.029,14.029,0,0,0-14.027-14.023H592.082L564.027,10183v14.028A14.029,14.029,0,0,0,550,10211.052Z'
								transform='translate(-470.512 -10150.27)'
								fill='none'
								stroke='currentColor'
								strokeLinejoin='round'
								strokeWidth='10'
							/>
							<path
								id='Path_415'
								dataname='Path 415'
								d='M586.406,10233.377,549,10247.405,563.027,10210l23.379,23.377h18.7L628.488,10210l14.027,37.405-37.406-14.028'
								transform='translate(-474.188 -10051.025)'
								fill='none'
								stroke='currentColor'
								strokeLinejoin='round'
								strokeWidth='10'
							/>
							<line
								id='Line_127'
								dataname='Line 127'
								y2='9.205'
								transform='translate(121.715 214.791)'
								fill='none'
								stroke='currentColor'
								strokeLinejoin='round'
								strokeWidth='10'
							/>
							<line
								id='Line_128'
								dataname='Line 128'
								y2='9.205'
								transform='translate(121.715 196.381)'
								fill='none'
								stroke='currentColor'
								strokeLinejoin='round'
								strokeWidth='10'
							/>
							<line
								id='Line_129'
								dataname='Line 129'
								y2='9.205'
								transform='translate(121.715 234.225)'
								fill='none'
								stroke='currentColor'
								strokeLinejoin='round'
								strokeWidth='10'
							/>
							<line
								id='Line_130'
								dataname='Line 130'
								y2='9.205'
								transform='translate(121.715 252.635)'
								fill='none'
								stroke='currentColor'
								strokeLinejoin='round'
								strokeWidth='10'
							/>
							<line
								id='Line_131'
								dataname='Line 131'
								y2='9.205'
								transform='translate(121.715 271.046)'
								fill='none'
								stroke='currentColor'
								strokeLinejoin='round'
								strokeWidth='10'
							/>
							<line
								id='Line_132'
								dataname='Line 132'
								x2='36.821'
								transform='translate(145.24 238.316)'
								fill='none'
								stroke='currentColor'
								strokeLinejoin='round'
								strokeWidth='10'
							/>
							<path
								id='Path_416'
								dataname='Path 416'
								d='M533,10334.567v-51.433c0-28.407,14.027-37.408,42.082-46.758L617.163,10213'
								transform='translate(-533 -10039.993)'
								fill='none'
								stroke='currentColor'
								strokeMiterlimit='10'
								strokeWidth='10'
							/>
							<path
								id='Path_417'
								dataname='Path 417'
								d='M651.163,10334.567v-51.433c0-28.407-14.027-37.408-42.082-46.758L567,10213'
								transform='translate(-408.025 -10039.993)'
								fill='none'
								stroke='currentColor'
								strokeMiterlimit='10'
								strokeWidth='10'
							/>
							<line
								id='Line_133'
								dataname='Line 133'
								y2='37.844'
								transform='translate(93.076 125.806)'
								fill='none'
								stroke='currentColor'
								strokeLinejoin='round'
								strokeWidth='10'
							/>
							<line
								id='Line_134'
								dataname='Line 134'
								y2='37.844'
								transform='translate(149.331 125.806)'
								fill='none'
								stroke='currentColor'
								strokeLinejoin='round'
								strokeWidth='10'
							/>
						</g>
					</g>
				</g>
			</g>
			<g id='Group_141' dataname='Group 141' transform='translate(375.64 1825.14)'>
				<line
					id='Line_136'
					dataname='Line 136'
					y2='27.616'
					transform='translate(100.619 190.244)'
					fill='none'
					stroke='currentColor'
					strokeLinejoin='round'
					strokeWidth='10'
				/>
				<line
					id='Line_137'
					dataname='Line 137'
					y1='16.365'
					transform='translate(23.908 97.167)'
					fill='none'
					stroke='currentColor'
					strokeLinejoin='round'
					strokeWidth='10'
				/>
				<line
					id='Line_138'
					dataname='Line 138'
					y1='16.365'
					transform='translate(65.844 97.167)'
					fill='none'
					stroke='currentColor'
					strokeLinejoin='round'
					strokeWidth='10'
				/>
				<path
					id='Path_418'
					dataname='Path 418'
					d='M548,10013.328v-27.616a10.359,10.359,0,0,1,10.356-10.355h3.452A10.359,10.359,0,0,1,572.164,9965h37.972a13.808,13.808,0,0,1,13.808,13.809v34.52h-3.452a6.9,6.9,0,0,1-6.9-6.9v-6.9a6.9,6.9,0,0,0-6.9-6.9v-6.9l-13.808,6.9H565.26a6.9,6.9,0,0,0-6.9,6.9v6.9a6.9,6.9,0,0,1-6.9,6.9Z'
					transform='translate(-541.096 -9965)'
					fill='none'
					stroke='currentColor'
					strokeLinejoin='round'
					strokeWidth='10'
				/>
				<path
					id='Path_419'
					dataname='Path 419'
					d='M623.944,9979v10.356l-3.452,3.452c0,17.26-10.356,41.424-34.52,41.424s-34.52-24.164-34.52-41.424L548,9989.356V9979'
					transform='translate(-541.096 -9930.672)'
					fill='none'
					stroke='currentColor'
					strokeLinejoin='round'
					strokeWidth='10'
				/>
				<path
					id='Path_420'
					dataname='Path 420'
					d='M577.068,10025.616,546,10008.356,563.26,9998l27.616,6.9v6.9Z'
					transform='translate(-546 -9884.084)'
					fill='none'
					stroke='currentColor'
					strokeLinejoin='round'
					strokeWidth='10'
				/>
				<path
					id='Path_421'
					dataname='Path 421'
					d='M572.808,10025.616l31.068-17.26L586.616,9998,559,10004.9v6.9Z'
					transform='translate(-514.124 -9884.084)'
					fill='none'
					stroke='currentColor'
					strokeLinejoin='round'
					strokeWidth='10'
				/>
				<line
					id='Line_139'
					dataname='Line 139'
					y2='6.137'
					transform='translate(45.387 162.628)'
					fill='none'
					stroke='currentColor'
					strokeLinejoin='round'
					strokeWidth='10'
				/>
				<line
					id='Line_140'
					dataname='Line 140'
					y2='7.16'
					transform='translate(45.387 148.308)'
					fill='none'
					stroke='currentColor'
					strokeLinejoin='round'
					strokeWidth='10'
				/>
				<line
					id='Line_141'
					dataname='Line 141'
					y2='7.16'
					transform='translate(45.387 175.924)'
					fill='none'
					stroke='currentColor'
					strokeLinejoin='round'
					strokeWidth='10'
				/>
				<line
					id='Line_142'
					dataname='Line 142'
					y2='6.137'
					transform='translate(45.387 190.244)'
					fill='none'
					stroke='currentColor'
					strokeLinejoin='round'
					strokeWidth='10'
				/>
				<path
					id='Path_422'
					dataname='Path 422'
					d='M571,10002l17.26,10.355c20.712,6.9,37.972,17,37.972,37.972v41.425'
					transform='translate(-484.7 -9874.276)'
					fill='none'
					stroke='currentColor'
					strokeLinejoin='round'
					strokeWidth='10'
				/>
			</g>
			<g id='Group_142' dataname='Group 142' transform='translate(776.242 1806.158)'>
				<line
					id='Line_143'
					dataname='Line 143'
					y2='29.662'
					transform='translate(18.114 207.18)'
					fill='none'
					stroke='currentColor'
					strokeLinejoin='round'
					strokeWidth='10'
				/>
				<line
					id='Line_144'
					dataname='Line 144'
					y2='29.662'
					transform='translate(137.783 207.18)'
					fill='none'
					stroke='currentColor'
					strokeLinejoin='round'
					strokeWidth='10'
				/>
				<path
					id='Path_424'
					dataname='Path 424'
					d='M353,10119.8V10101'
					transform='translate(-252.43 -9988.214)'
					fill='none'
					stroke='currentColor'
					strokeMiterlimit='10'
					strokeWidth='10'
				/>
				<path
					id='Path_425'
					dataname='Path 425'
					d='M419.911,10202.984v-41.353c0-22.841-11.278-30.075-33.835-37.595L356,10109'
					transform='translate(-244.153 -9966.14)'
					fill='none'
					stroke='currentColor'
					strokeMiterlimit='10'
					strokeWidth='10'
				/>
				<line
					id='Line_145'
					dataname='Line 145'
					y1='18.411'
					transform='translate(55.958 113.082)'
					fill='none'
					stroke='currentColor'
					strokeMiterlimit='10'
					strokeWidth='10'
				/>
				<path
					id='Path_426'
					dataname='Path 426'
					d='M340.293,10130.137,384.47,10109'
					transform='translate(-340.293 -9966.14)'
					fill='none'
					stroke='currentColor'
					strokeMiterlimit='10'
					strokeWidth='10'
				/>
				<path
					id='Path_427'
					dataname='Path 427'
					d='M405.67,10099.038c-3.76,0-11.278-7.52-11.278-15.038,0,7.519-11.278,15.038-22.557,15.038s-22.557-7.52-22.557-15.038c0,7.519-7.519,15.038-11.278,15.038v3.759c0,26.991,3.759,52.632,33.835,52.632s33.835-25.641,33.835-52.632h0Z'
					transform='translate(-293.823 -10035.127)'
					fill='none'
					stroke='currentColor'
					strokeLinejoin='round'
					strokeWidth='10'
				/>
				<path
					id='Path_428'
					dataname='Path 428'
					d='M416.708,10143.668s15.038-11.277,15.038-33.834c0-16.608-15.038-33.835-41.354-33.835H375.354c-26.316,0-41.354,17.227-41.354,33.835,0,22.557,15.038,33.834,15.038,33.834'
					transform='translate(-304.86 -10057.202)'
					fill='none'
					stroke='currentColor'
					strokeLinejoin='round'
					strokeWidth='10'
				/>
				<path
					id='Path_429'
					dataname='Path 429'
					d='M340,10093.557c0-14.536,11.782-22.557,26.316-22.557s26.316,8.021,26.316,22.557'
					transform='translate(-288.303 -10071)'
					fill='none'
					stroke='currentColor'
					strokeLinejoin='round'
					strokeWidth='10'
				/>
				<line
					id='Line_146'
					dataname='Line 146'
					y2='8.183'
					transform='translate(78.46 221.5)'
					fill='none'
					stroke='currentColor'
					strokeLinejoin='round'
					strokeWidth='10'
				/>
				<path
					id='Path_430'
					dataname='Path 430'
					d='M367.835,10211.263v-7.518c0-18.8,33.835-63.91,63.911-63.91L409.189,10106h-3.76l-22.557,45.112L360.316,10106h-3.759L334,10139.835c15.038,0,37.892,14.457,48.873,30.075'
					transform='translate(-304.86 -9974.418)'
					fill='none'
					stroke='currentColor'
					strokeLinejoin='round'
					strokeWidth='10'
				/>
			</g>
		</g>
	</svg>
)
export default IconSVG
