import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x32_8_x5F_geography_x5F_globe'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='56px'
		height='64px'
		viewBox='0 0 56 64'
		style={{ enableBackground: 'new 0 0 56 64' }}
	>
		<g>
			<g>
				<g>
					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='16'
						y1='63'
						x2='40'
						y2='63'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='26'
						y1='51'
						x2='26'
						y2='57'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='30'
						y1='51'
						x2='30'
						y2='57'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='56,63 43,63 
				43,57 13,57 13,63 0,63 			'
					/>
					<g>
						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='42.264'
							y1='7.736'
							x2='9.736'
							y2='40.264'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='42.264'
							y1='40.264'
							x2='9.736'
							y2='7.736'
						/>

						<ellipse
							transform='matrix(0.7071 0.7071 -0.7071 0.7071 24.5858 -11.3553)'
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							cx='26'
							cy='24'
							rx='14'
							ry='23'
						/>
						<path
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							d='M48.611,26.844
					c-4.949-1.481-10.542-4.916-15.54-9.915c-5.001-5.001-8.437-10.597-9.917-15.548'
						/>
						<path
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							d='M28.831,46.625
					c-1.482-4.951-4.916-10.543-9.915-15.541c-5-5.002-10.596-8.437-15.547-9.916'
						/>
					</g>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M47,3l-2,2
				c4.94,4.894,8,11.497,8,19c0,14.912-12.088,27-27,27c-7.408,0-14.121-3.168-19-8l-2,2'
					/>
					<circle
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						cx='26'
						cy='24'
						r='23'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
