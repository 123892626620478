import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x34_6_x5F_delegating_x5F_tasks'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<path
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					d='M27,6c0,3.5,1.963,7,5,7
			s5-3.5,5-7s-1-5-5-5S27,2.5,27,6z'
				/>
				<path
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					d='M29,12l-7,4
			c0,0,0,0.344,0,2c0,1.656,1,3,2,3h16c1,0,2-1.344,2-3c0-1.656,0-2,0-2l-7-4'
				/>
			</g>
			<g>
				<path
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					d='M6,48c0,3.5,1.963,7,5,7
			s5-3.5,5-7s-1-5-5-5S6,44.5,6,48z'
				/>
				<path
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					d='M8,54l-7,4
			c0,0,0,0.344,0,2c0,1.656,1,3,2,3h16c1,0,2-1.344,2-3c0-1.656,0-2,0-2l-7-4'
				/>
			</g>
			<g>
				<path
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					d='M48,48c0,3.5,1.963,7,5,7
			s5-3.5,5-7s-1-5-5-5S48,44.5,48,48z'
				/>
				<path
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					d='M50,54l-7,4
			c0,0,0,0.344,0,2c0,1.656,1,3,2,3h16c1,0,2-1.344,2-3c0-1.656,0-2,0-2l-7-4'
				/>
			</g>
			<g>
				<polyline
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					points='57,33 53,37 
			49,33 		'
				/>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='53,37 53,10 42,10 		'
				/>
			</g>
			<g>
				<polyline
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					points='7,33 11,37 
			15,33 		'
				/>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='11,37 11,10 22,10 		'
				/>
			</g>
			<g>
				<polyline
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					points='40,33 32,41 
			28,37 		'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='34'
					y1='30'
					x2='36'
					y2='30'
				/>
				<path
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					d='M41,39
			c0,4.971-4.029,9-9,9s-9-4.029-9-9s4.029-9,9-9'
				/>
			</g>
		</g>
	</svg>
)
export default IconSVG
