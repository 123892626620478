import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_2_x5F_startup_x5F_launch'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<path
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					d='M22,54L10,42
			c0,0,25-41,53-41C63,29,22,54,22,54z'
				/>
				<polyline
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					points='20,52 17,55 
			9,47 12,44 		'
				/>
				<path
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					d='M7,50c-6,6-6,13-6,13
			s7,0,13-6'
				/>
				<circle
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					cx='46'
					cy='18'
					r='4'
				/>
				<circle
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					cx='37'
					cy='27'
					r='4'
				/>
				<circle
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					cx='28'
					cy='36'
					r='4'
				/>
				<polyline
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					points='54,25 37,63 
			27,63 27,55 		'
				/>
				<polyline
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					points='39,10 1,27 1,37 
			9,37 		'
				/>
				<path
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					d='M61,11
			c-4.419,0-8-3.582-8-8'
				/>
			</g>
		</g>
	</svg>
)
export default IconSVG
