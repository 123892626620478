/*
 =================================================
  External script runner
  based on: https://github.com/aaronhayes/react-use-hubspot-form
 =================================================
* */
import { useEffect } from 'react'
import useState from './useState'

const cachedScripts = []

export default src => {
	const [isLoaded, setLoaded] = useState(false, 'isLoaded')
	const [error, setError] = useState(null, 'error')

	useEffect(() => {
		try {
			// We don't want to load the script more than once
			if (cachedScripts.includes(src)) {
				setLoaded(true)
				setError(false)
			} else {
				cachedScripts.push(src)

				let script = document.createElement('script')
				script.src = src
				script.async = true

				const onScriptLoad = () => {
					setLoaded(true)
					setError(false)
				}

				const onScriptError = () => {
					// get rid of the cached script
					const index = cachedScripts.indexOf(src)
					if (index >= 0) cachedScripts.splice(index, 1)

					// remove the script
					script.remove()

					// reset the hook state
					setLoaded(true)
					setError(true)
				}

				script.addEventListener('load', onScriptLoad)
				script.addEventListener('error', onScriptError)

				document.body.appendChild(script)

				return () => {
					script.removeEventListener('load', onScriptLoad)
					script.removeEventListener('error', onScriptError)
				}
			}
		} catch (err) {
			console.log('error using script: ', err)
		}
	}, [src])

	return [isLoaded, error]
}
