import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { H2 } from '../Typography'

const BoardWrap = styled.div`
	${props => props.theme.media.sdesk} {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 100vh;
		padding: 20vh 0;
		${props =>
		props.hasTitle &&
		`
			justify-content: flex-start;
		`};
	}
	.right-title {
		${props => props.theme.media.sdesk} {
			max-width: 25%;
			flex-grow: 1;
			padding-left: 2em;
			margin-right: 1em;
			text-align: right;
		}
	}
	.board-content {
		max-width: 900px;
		margin: 0 auto;
		${props => props.theme.media.sdesk} {
			margin: 0;
			border: ${props => (props.noBorder ? 'none' : 'solid 2px')};
			border-color: ${props => props.theme.colors.grey.light().val};
			border-radius: 5px;
			padding: 5vh 7vw;
		}
	}
`

const Board = ({ titleText, children, ...props }) => {
	return (
		<BoardWrap className='board' {...props}>
			{titleText && (
				<header className='flex'>
					<H2 className='board-title'>{titleText}</H2>
				</header>
			)}
			<div className='board-content'>{children}</div>
		</BoardWrap>
	)
}
Board.propTypes = {
	titleText: PropTypes.string,
}
export default Board
