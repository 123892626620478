import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import includes from 'lodash/includes'
import find from 'lodash/find'
import toLower from 'lodash/toLower'
import words from 'lodash/words'
import trim from 'lodash/trim'

import iconMap from './iconMap'

const I = styled.i`
	display: inline-flex;
	position: relative;
	height: 1em;
	width: 1em;
	vertical-align: middle;
	text-align: center;
	align-items: center;
	box-sizing: border-box;
	color: inherit;
	transition: all ${props => props.theme.times.tranM};
	transform: ${props => (props.rotate ? `rotateZ(${props.rotate})` : '')};
	&.__flip-vert {
		transform: scaleY(-1) ${props => (props.rotate ? `rotateZ(${props.rotate})` : '')};
	}
	&.__flip-horiz {
		transform: scaleX(-1) ${props => (props.rotate ? `rotateZ(${props.rotate})` : '')};
		&.__flip-vert {
			transform: scale3d(-1, -1, 0) ${props => (props.rotate ? `rotateZ(${props.rotate})` : '')};
		}
	}
	svg {
		overflow: visible;
		height: 100%;
		color: inherit;
		fill: inherit;
		display: inline-block;
		box-sizing: content-box;
	}
`

const getIcon = type => {
	let Icon = iconMap[trim(type)]
	return (
		Icon ||
		find(iconMap, (_, key) => {
			return includes(
				key,
				words(type)
					.map(str => trim(toLower(str)))
					.join('_')
			)
		})
	)
}

const Icon = ({ type, flipH, flipV, rotate, className, ...props }) => {
	const Icon = getIcon(type) || iconMap['36_security_padlock']
	return (
		<I
			className={`icon${className ? ` ${className}` : ''}${flipH ? ' __flip-horiz' : ''}${
				flipV ? ' __flip-vert' : ''
			} ${type}`}
			rotate={rotate}
			{...props}
		>
			<Icon />
		</I>
	)
}
Icon.propTypes = {
	type: PropTypes.string,
	flipH: PropTypes.bool,
	flipV: PropTypes.bool,
}
export default Icon
