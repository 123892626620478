import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x33_6_x5F_global_x5F_protection'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='48px'
		height='64px'
		viewBox='0 0 48 64'
		style={{ enableBackground: 'new 0 0 48 64' }}
	>
		<g>
			<polygon
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				points='47,46 24,63 1,46 
		1,1 11,6 14,1 34,1 37,6 47,1 	'
			/>
			<polyline
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				points='5,3 5,44 24,58 
		43,44 43,3 	'
			/>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='11'
				y1='6'
				x2='11'
				y2='11'
			/>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='37'
				y1='6'
				x2='37'
				y2='11'
			/>
			<g>
				<circle
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					cx='24'
					cy='27'
					r='13'
				/>

				<ellipse
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					cx='24'
					cy='27'
					rx='3'
					ry='13'
				/>

				<ellipse
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					cx='24'
					cy='27'
					rx='8'
					ry='13'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='11'
					y1='27'
					x2='37'
					y2='27'
				/>
			</g>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='23'
				y1='8'
				x2='25'
				y2='8'
			/>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='19'
				y1='8'
				x2='21'
				y2='8'
			/>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='27'
				y1='8'
				x2='29'
				y2='8'
			/>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='23'
				y1='46'
				x2='25'
				y2='46'
			/>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='19'
				y1='46'
				x2='21'
				y2='46'
			/>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='27'
				y1='46'
				x2='29'
				y2='46'
			/>
		</g>
	</svg>
)
export default IconSVG
