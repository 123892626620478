/*
 =================================================
  SITE COLORS
 =================================================
  ALL colors for the site should be set here.
  Any of these colors will then be available 
  either inside of a component (via the "useTheme" exported from styled-components)
  or directly within a styled component as: ${props => props.theme.colors}
  See https://github.com/swiftforge/swf-theme for more info on the theme API
* */

export default {
	// primary: '#F16622',
	// secondary: '#BB4127',
	// aux1: '#293847',
	// aux2: '#2799FC',
	// aux3: '#789DC3',
	// aux4: '#ADE2F4',
	// midnight: '#293847',

	// yellow: '#FFEA6B',

	grey: '#809096',
	black: '#000000',
	white: '#FFFFFF',
	slate: '#293847',
	skyBlue: '#3EC6F2',
	dkOrange: '#BB4127',
	orange: '#F16622',
	brown: '#7a3a11',
	text: '#293847',

	lightCtaBg: '#d0e4f9',
	lightCta: '#293847',
	whiteCtaBg: '#d0e4f9',

	lightBlue: '#CDE2E8',

	courseCardBg: '#DAF5F8',
	heroSlate: '#4e6b88',

	navPaneBg: '#4E6E8E',

	darkCtaBg: '#293847',
}
