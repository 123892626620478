import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x33_6_x5F_security_x5F_padlock'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='48px'
		height='64px'
		viewBox='0 0 48 64'
		style={{ enableBackground: 'new 0 0 48 64' }}
	>
		<g>
			<path
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				d='M41,25v-8c0-8.8-7.2-16-16-16h-2
		C14.2,1,7,8.2,7,17v8'
			/>
			<path
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				d='M35,22v-5c0-5.5-4.5-10-10-10h-2
		c-5.5,0-10,4.5-10,10v5'
			/>
			<polygon
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				points='20,40 24,44 33,35 36,38 24,50 17,43 	'
			/>
			<path
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				d='M37,44c0,7.18-5.82,13-13,13
		c-7.18,0-13-5.82-13-13s5.82-13,13-13c1.773,0,3.465,0.355,5.006,0.998'
			/>
			<path
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				d='M47,60c0,1.65-1.35,3-3,3H4c-1.65,0-3-1.35-3-3V28
		c0-1.65,1.35-3,3-3h40c1.65,0,3,1.35,3,3v10V60z'
			/>
			<polyline
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				points='1,27 5,31 5,57 1,61 	'
			/>
			<polyline
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				points='47,27 43,31 43,57 47,61 	'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='47'
				y1='34'
				x2='43'
				y2='34'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='47'
				y1='38'
				x2='43'
				y2='38'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='47'
				y1='42'
				x2='43'
				y2='42'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='47'
				y1='46'
				x2='43'
				y2='46'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='47'
				y1='50'
				x2='43'
				y2='50'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='47'
				y1='54'
				x2='43'
				y2='54'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='5'
				y1='34'
				x2='1'
				y2='34'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='5'
				y1='38'
				x2='1'
				y2='38'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='5'
				y1='42'
				x2='1'
				y2='42'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='5'
				y1='46'
				x2='1'
				y2='46'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='5'
				y1='50'
				x2='1'
				y2='50'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='5'
				y1='54'
				x2='1'
				y2='54'
			/>
		</g>
	</svg>
)
export default IconSVG
