import React from 'react'

const SearchIcon = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='23' height='23' viewBox='0 0 23 23'>
			<defs>
				<clipPath id='search-clip-path'>
					<rect width='23' height='23' fill='none' />
				</clipPath>
			</defs>
			<g id='Search' clipPath='url(#search-clip-path)'>
				<rect dataname='Rectangle 693' width='23' height='23' fill='none' />
				<path
					data-name='Path 208'
					d='M22.9,20.884l-4.753-4.753a9.861,9.861,0,0,0,2.016-6.049A10.02,10.02,0,0,0,10.082,0,10.02,10.02,0,0,0,0,10.082,10.02,10.02,0,0,0,10.082,20.164a9.861,9.861,0,0,0,6.049-2.016L20.884,22.9ZM2.881,10.082a7.131,7.131,0,0,1,7.2-7.2,7.131,7.131,0,0,1,7.2,7.2,7.131,7.131,0,0,1-7.2,7.2A7.131,7.131,0,0,1,2.881,10.082Z'
					transform='translate(0 0)'
					fill='currentColor'
				/>
			</g>
		</svg>
	)
}
SearchIcon.propTypes = {}
export default SearchIcon
