import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x31_9_x5F_testing_x5F_checklist'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='62px'
		height='64px'
		viewBox='0 0 62 64'
		style={{ enableBackground: 'new 0 0 62 64' }}
	>
		<g>
			<g>
				<polygon
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					points='45,63 45,9 37,1 
			1,1 1,63 		'
				/>
				<g>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='14,18 10,22 7,19 			'
					/>
				</g>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='18'
					y1='18'
					x2='28'
					y2='18'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='18'
					y1='22'
					x2='34'
					y2='22'
				/>
				<g>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='14,28 10,32 7,29 			'
					/>
				</g>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='18'
					y1='28'
					x2='40'
					y2='28'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='18'
					y1='32'
					x2='26'
					y2='32'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='18'
					y1='38'
					x2='40'
					y2='38'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='18'
					y1='42'
					x2='30'
					y2='42'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='18'
					y1='48'
					x2='32'
					y2='48'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='18'
					y1='52'
					x2='40'
					y2='52'
				/>
				<g>
					<rect
						x='52'
						y='1'
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						width='4'
						height='5'
					/>

					<polygon
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinecap: 'square',
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='
				57,6 57,57 54,63 51,57 51,6 			'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='57'
						y1='36'
						x2='51'
						y2='36'
					/>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='61,26 61,10 51,10 			'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='51'
						y1='40'
						x2='57'
						y2='40'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='51'
						y1='44'
						x2='57'
						y2='44'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='51'
						y1='48'
						x2='57'
						y2='48'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='51'
						y1='52'
						x2='57'
						y2='52'
					/>
				</g>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='28'
					y1='32'
					x2='40'
					y2='32'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='30'
					y1='18'
					x2='40'
					y2='18'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='34'
					y1='48'
					x2='40'
					y2='48'
				/>
				<polyline
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					points='45,9 37,9 37,1 
					'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='6'
					y1='5'
					x2='8'
					y2='5'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='10'
					y1='5'
					x2='12'
					y2='5'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='14'
					y1='5'
					x2='16'
					y2='5'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='6'
					y1='9'
					x2='32'
					y2='9'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='13'
					y1='37'
					x2='7'
					y2='43'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='7'
					y1='37'
					x2='13'
					y2='43'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='13'
					y1='47'
					x2='7'
					y2='53'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='7'
					y1='47'
					x2='13'
					y2='53'
				/>
			</g>
		</g>
	</svg>
)
export default IconSVG
