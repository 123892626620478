import React from 'react'

import { H2 } from './Typography'

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError(error) {
		return { hasError: true }
	}

	componentDidCatch(error, errorInfo) {
		console.error(error)
		console.log('Caught error details: ', errorInfo)
	}

	render() {
		if (this.state.hasError) {
			return this.props.Err ? this.props.Err : <H2>{this.props.msg || 'Unable to render...'}</H2>
		}

		return this.props.children
	}
}
export default ErrorBoundary
