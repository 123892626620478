import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x34_5_x5F_global_x5F_connections'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='7'
				y1='32'
				x2='14'
				y2='32'
			/>
			<circle
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='4'
				cy='32'
				r='3'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='10'
				y1='19'
				x2='16'
				y2='23'
			/>
			<circle
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='8'
				cy='18'
				r='3'
			/>
			<circle
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='18'
				cy='8'
				r='3'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='19'
				y1='10'
				x2='23'
				y2='16'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='57'
				y1='32'
				x2='50'
				y2='32'
			/>
			<circle
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='60'
				cy='32'
				r='3'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='54'
				y1='19'
				x2='48'
				y2='23'
			/>
			<circle
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='56'
				cy='18'
				r='3'
			/>
			<circle
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='46'
				cy='8'
				r='3'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='32'
				y1='6.999'
				x2='32'
				y2='14'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='45'
				y1='10'
				x2='41'
				y2='16'
			/>
			<circle
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='32'
				cy='4'
				r='3'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='54'
				y1='45'
				x2='48'
				y2='41'
			/>
			<circle
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='56'
				cy='46'
				r='3'
			/>
			<circle
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='46'
				cy='56'
				r='3'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='45'
				y1='54'
				x2='41'
				y2='48'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='10'
				y1='45'
				x2='16'
				y2='41'
			/>
			<circle
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='8'
				cy='46'
				r='3'
			/>
			<circle
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='18'
				cy='56'
				r='3'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='32'
				y1='57'
				x2='32'
				y2='50'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='19'
				y1='54'
				x2='23'
				y2='48'
			/>
			<circle
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='32'
				cy='60'
				r='3'
			/>
			<g>
				<g>
					<circle
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						cx='32'
						cy='32'
						r='15'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='47'
						y1='32'
						x2='17'
						y2='32'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='20'
						y1='23'
						x2='44'
						y2='23'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='20'
						y1='41'
						x2='44'
						y2='41'
					/>
				</g>
				<ellipse
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='32'
					cy='32'
					rx='8'
					ry='15'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='32'
					y1='17'
					x2='32'
					y2='47'
				/>
			</g>
		</g>
	</svg>
)
export default IconSVG
