import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x31_5_x5F_cloud_x5F_search'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<g>
					<g>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M43,41h8c6.627,0,12-5.373,12-12
					c0-5.947-4-11-10-11c0-5-3-9-9-9h-1c-2.728-4.924-7.972-8-14-8c-8.836,0-16,7.164-16,16C6.373,17,1,22.373,1,29
					c0,6.627,5.373,12,12,12h8'
						/>
					</g>
					<g>
						<circle
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							cx='32'
							cy='26'
							r='15'
						/>
						<circle
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							cx='32'
							cy='26'
							r='10'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M27,26c0-2.762,2.238-5,5-5'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M36,40v19c0,2.209-1.791,4-4,4s-4-1.791-4-4V44
					'
						/>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='32'
							y1='48'
							x2='32'
							y2='50'
						/>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='32'
							y1='52'
							x2='32'
							y2='54'
						/>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='32'
							y1='56'
							x2='32'
							y2='58'
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
