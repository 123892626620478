/* eslint-disable react-hooks/exhaustive-deps */
import isNumber from 'lodash/isNumber'
import round from 'lodash/round'
import random from 'lodash/random'

import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'
import { SkeletonTheme } from 'react-loading-skeleton'
import Skeleton from './Skeleton'
import { useState } from '../../../utils'

const Bar = styled.div`
	width: ${props => `${props.width}%`};
	min-width: 5%;
	max-width: 100%;
	height: 100%;
	transition: width ${props => `${props.theme.times.med}ms ${props.theme.times.ease}`};
	* {
		height: 100%;
	}
`

const BarWrap = styled.div`
	width: 100%;
	height: ${props => (props.height ? props.height : '4px')};
	background-color: ${props =>
		props.theme.colors.loader
			? props.theme.colors.loader.light('6').val
			: props.theme.colors.grey.light('5').val};
	box-shadow: inset 0 1px 1px ${props => props.theme.colors.black.tint(30).val};
	${props =>
		props.noBg
			? `
		background: none;
		box-shadow: none;
	`
			: ''}
	overflow: hidden;

	${props => (props.css ? props.css : '')}
`

const ProgressBar = ({ percentDone, isDone, className, ...props }) => {
	const { colors, times } = useTheme()

	const [percent, setPercent] = useState(percentDone || 10, 'percent')
	const [showBar, setShowBar] = useState(true, 'showBar')
	const cancel = useRef(false)

	useEffect(() => {
		if (isNumber(percentDone) && percentDone > percent)
			// progress bar increments are being handled externally
			setPercent(round(percentDone))
	}, [percentDone])

	useEffect(() => {
		// console.log('random percent use effect is firing | percentDone: ', percentDone, ' | percent: ', percent)
		if (!isNumber(percentDone) && percent < 98) {
			// we are using random increments (no outside control)
			setTimeout(() => {
				// increment bar by amount between current percentage and current percentage + 5
				if (!cancel.current)
					setPercent(state => {
						const max = state < 98 ? state + (100 - state) * 0.15 : 99
						const min = state < 98 ? state + 0.2 : state + 0.01
						const newPercent = random(min, max)
						// console.log('min: ', state + 0.2, ' | max: ', max)
						// console.log('new percent: ', newPercent)
						return newPercent
					})

				// perform increments at random time intervals between 200 and 1000 milliseconds
			}, random(200, 1000, false))
		}
	}, [percent])

	useEffect(() => {
		if (isDone)
			setTimeout(() => {
				if (!cancel.current) setShowBar(false)
			}, times.long || 600)
	}, [isDone])

	useEffect(() => {
		return () => (cancel.current = true)
	}, [])

	return (
		showBar && (
			<BarWrap className={`progress-bar${className ? ` ${className}` : ''}`} {...props}>
				<Bar className='bar' width={isDone ? 100 : percent}>
					<SkeletonTheme
						color={colors.loader ? colors.loader.val : colors.aux2.val}
						highlightColor={
							colors.loaderAccent
								? colors.loaderAccent()
								: colors.loader
								? colors.loader.light().val
								: colors.aux2.light().val
						}
					>
						<Skeleton />
					</SkeletonTheme>
				</Bar>
			</BarWrap>
		)
	)
}
ProgressBar.propTypes = {
	percentDone: PropTypes.number,
}
export default ProgressBar
