import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x31_6_x5F_system_x5F_monitoring'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='62px'
		viewBox='0 0 64 62'
		style={{ enableBackground: 'new 0 0 64 62' }}
	>
		<g>
			<g>
				<g>
					<polygon
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='59,61 5,61 1,57 1,55 25,55 27,57 
				37,57 39,55 63,55 63,57 			'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M57,52V24c0-1.657-1.344-3-3-3H10
				c-1.656,0-3,1.343-3,3v28'
					/>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='14,38 16,38 20,34 28,44 36,32 44,42 
				48,38 50,38 			'
					/>
					<g>
						<path
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							d='M49,18
					c0-0.834-0.08-1.646-0.196-2.448C48.26,15.829,47.652,16,47,16c-2.209,0-4-1.79-4-4c0-1.811,1.212-3.324,2.863-3.816
					c-1.111-1.567-2.479-2.936-4.047-4.047C41.324,5.788,39.812,7,38,7c-2.209,0-4-1.791-4-4c0-0.652,0.171-1.26,0.448-1.804
					C33.646,1.08,32.834,1,32,1s-1.646,0.08-2.448,0.196C29.829,1.74,30,2.348,30,3c0,2.209-1.791,4-4,4
					c-1.812,0-3.324-1.212-3.816-2.863c-1.567,1.111-2.936,2.479-4.047,4.047C19.788,8.676,21,10.189,21,12c0,2.21-1.791,4-4,4
					c-0.652,0-1.26-0.172-1.804-0.448C15.08,16.354,15,17.166,15,18'
						/>
						<path
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							d='M29,18
					c0-1.656,1.344-3,3-3c1.656,0,3,1.344,3,3'
						/>
						<path
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							d='M24,18
					c0-4.418,3.582-8,8-8s8,3.582,8,8'
						/>
					</g>
					<rect
						x='11'
						y='25'
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						width='42'
						height='26'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
