import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x31_5_x5F_cloud_x5F_hosting'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<g>
					<g>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M40,41h11c6.627,0,12-5.373,12-12
					c0-5.947-4-11-10-11c0-5-3-9-9-9h-1c-2.728-4.924-7.972-8-14-8c-8.836,0-16,7.164-16,16C6.373,17,1,22.373,1,29
					c0,6.627,5.373,12,12,12h11'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M19,18c0-6.075,5-11,10-11'
						/>
					</g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='15'
						y1='49'
						x2='8'
						y2='49'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M29,32v13c0,2.209-1.791,4-4,4h-2'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='49'
						y1='59'
						x2='55'
						y2='59'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M35,32v23c0,2.209,1.791,4,4,4h2'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='24'
						y1='31'
						x2='40'
						y2='31'
					/>
					<circle
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						cx='5'
						cy='49'
						r='4'
					/>
					<circle
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						cx='19'
						cy='49'
						r='4'
					/>
					<circle
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						cx='45'
						cy='59'
						r='4'
					/>
					<circle
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						cx='59'
						cy='59'
						r='4'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='42'
						y1='31'
						x2='44'
						y2='31'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='46'
						y1='31'
						x2='48'
						y2='31'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='50'
						y1='31'
						x2='52'
						y2='31'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='12'
						y1='31'
						x2='14'
						y2='31'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='16'
						y1='31'
						x2='18'
						y2='31'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='20'
						y1='31'
						x2='22'
						y2='31'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
