import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_3_x5F_thumbs_x5F_up'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='56px'
		viewBox='0 0 64 56'
		style={{ enableBackground: 'new 0 0 64 56' }}
	>
		<g>
			<g>
				<g>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='0,23 19,23 
				19,55 0,55 			'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M26,25'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M19,23h5l11-11l4-8
				c0-1.656,1.343-3,3-3c2.209,0,4,1.791,4,4s-1,6-1,6l-5,11l1,1h16c2.209,0,4,1.791,4,4s-1.791,4-4,4h2c2.209,0,4,1.791,4,4
				s-1.791,4-4,4h-2c2.209,0,4,1.791,4,4s-1.791,4-4,4h-2c2.209,0,4,1.791,4,4s-1.791,4-4,4H29l-4-2h-3'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='16'
						y1='51'
						x2='14'
						y2='51'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='12'
						y1='51'
						x2='10'
						y2='51'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='5'
						y1='23'
						x2='5'
						y2='55'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='57'
						y1='31'
						x2='46'
						y2='31'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='57'
						y1='39'
						x2='47'
						y2='39'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='55'
						y1='47'
						x2='47'
						y2='47'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M48,31
				c-2.209,0-4-1.791-4-4s1.791-4,4-4'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M46,39
				c-2.209,0-4-1.791-4-4s1.791-4,4-4'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M47,47
				c-2.209,0-4-1.791-4-4s1.791-4,4-4'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M49,55
				c-2.209,0-4-1.791-4-4s1.791-4,4-4'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
