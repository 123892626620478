/*
 =================================================
  Uses (in some cases): https://github.com/google-map-react/google-map-react
 =================================================
* */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import GM from 'google-map-react'

import { Link } from './Typography'

const apiKey = process.env.GATSBY_GOOGLE_API_KEY

const Wrap = styled.div`
	width: 100%;
	height: 400px;
`

const M = styled(Link)`
  display: inline-block;
  position: absolute;
  background: ${props => props.theme.colors.white.val};
  color: ${props => props.theme.colors.slate.val};
  font-weight: 600;
  padding: 1em;
  max-width: 100px;
  transform: translate3d(-50%, -100%, 0);
  /* border: solid 2px ${props => props.theme.colors.white.val}; */
  border-radius: 5px;
  &:hover {
    color: ${props => props.theme.colors.orange.val};
  }
  &:before {
    content: '';
    width: 20px;
    height: 20px;
    background: ${props => props.theme.colors.white.val};
    display: block;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%) rotateZ(45deg);
    z-index:0;
  }
  .content {
    z-index: 1;
    text-align: center;
  }
`

const Marker = ({ children, url }) => (
	<M className='marker' to={url}>
		<div className='content'>{children}</div>
	</M>
)

const GoogleMap = ({ lon: lng, lat, url, name, code }) => {
	const config = {
		center: {
			lng,
			lat,
		},
		zoom: 17,
	}
	const viewOnGMapsUrl =
		url || `https://maps.google.com/maps?ll=${lat},${lng}&z=17&t=m&hl=en-US&gl=US`
	const urlName = name && name.replace(/ /g, '+')
	return (
		<Wrap>
			{apiKey && urlName && lat && lng ? (
				<iframe
					width='600'
					height='450'
					frameBorder={0}
					style={{ border: 0 }}
					aria-hidden={false}
					title={name}
					src={`https://www.google.com/maps/embed/v1/place?q=${urlName}&center=${lat},${lng}&key=${apiKey}&zoom=17`}
				/>
			) : code ? (
				<div className='embed' dangerouslySetInnerHTML={{ __html: code }} />
			) : (
				<GM {...config}>
					<Marker url={viewOnGMapsUrl}>{name}</Marker>
				</GM>
			)}
		</Wrap>
	)
}
GoogleMap.propTypes = {
	lon: PropTypes.number,
	lat: PropTypes.number,
	name: PropTypes.string,
}
export default GoogleMap
