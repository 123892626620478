import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_1_x5F_appreciations'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<polyline
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					points='23,27 15,27 
					15,35 7,27 1,27 1,1 39,1 39,27 36,27 		'
				/>
				<g>
					<rect
						x='1'
						y='39'
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						width='15'
						height='24'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M19,61l4,2h17
						c1.656,0,3-1.344,3-3c0-1.656-1.344-3-3-3h1c1.656,0,3-1.344,3-3c0-1.656-1.344-3-3-3h2c1.656,0,3-1.344,3-3c0-1.656-1.344-3-3-3
						h-1c1.656,0,3-1.344,3-3c0-1.656-1.344-3-3-3H29l4-11v-2c0-1.656-1.344-3-3-3c-1.656,0-3,1.344-3,3l-3,6l-6,7h-2'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='12'
						y1='58'
						x2='12'
						y2='60'
					/>
				</g>
				<polyline
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					points='42,9 63,9 63,33 
					57,33 49,41 49,33 36,33 		'
				/>
				<g>
					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='15'
						y1='14'
						x2='17'
						y2='14'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='19'
						y1='14'
						x2='21'
						y2='14'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='23'
						y1='14'
						x2='25'
						y2='14'
					/>
				</g>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='58'
					y1='15'
					x2='42'
					y2='15'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='58'
					y1='21'
					x2='42'
					y2='21'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='58'
					y1='27'
					x2='42'
					y2='27'
				/>

				<rect
					x='7'
					y='9'
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					width='26'
					height='10'
				/>
			</g>
		</g>
	</svg>
)
export default IconSVG
