import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_3_x5F_business_x5F_network'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='60px'
		viewBox='0 0 64 60'
		style={{ enableBackground: 'new 0 0 64 60' }}
	>
		<g>
			<g>
				<g>
					<g>
						<g>
							<polygon
								style={{ fill: 'none', stroke: 'red', strokeWidth: 2, strokeMiterlimit: 10 }}
								points='59,59 5,59 1,55 1,53 25,53 27,55 
						37,55 39,53 63,53 63,55 					'
							/>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								d='M9,15H8c-1.656,0-3,1.343-3,3v32'
							/>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								d='M59,50V18c0-1.657-1.344-3-3-3h-1'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='27'
								y1='47'
								x2='29'
								y2='47'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='23'
								y1='47'
								x2='25'
								y2='47'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='31'
								y1='47'
								x2='33'
								y2='47'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='35'
								y1='47'
								x2='37'
								y2='47'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='39'
								y1='47'
								x2='41'
								y2='47'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='59'
								y1='47'
								x2='43'
								y2='47'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='21'
								y1='47'
								x2='5'
								y2='47'
							/>
						</g>
					</g>
					<circle
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						cx='32'
						cy='21'
						r='20'
					/>
					<ellipse
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						cx='32'
						cy='21'
						rx='8'
						ry='20'
					/>
					<ellipse
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						cx='32'
						cy='21'
						rx='20'
						ry='8'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
