import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x31_9_x5F_secure_x5F_login'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<rect
					x='1'
					y='1'
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					width='62'
					height='62'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='1'
					y1='9'
					x2='63'
					y2='9'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='4'
					y1='5'
					x2='6'
					y2='5'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='8'
					y1='5'
					x2='10'
					y2='5'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='12'
					y1='5'
					x2='14'
					y2='5'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='17'
					y1='1'
					x2='17'
					y2='9'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='20'
					y1='5'
					x2='60'
					y2='5'
				/>
				<rect
					x='5'
					y='13'
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					width='54'
					height='46'
				/>
				<g>
					<rect
						x='11'
						y='34'
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						width='14'
						height='12'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M13,34v-6c0-1.104,0.895-2,2-2h6
				c1.104,0,2,0.896,2,2v6'
					/>
				</g>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='26,26 53,26 53,34 28,34 		'
				/>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='28,38 53,38 53,46 28,46 		'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='30'
					y1='30'
					x2='32'
					y2='30'
				/>
				<g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='34'
						y1='30'
						x2='36'
						y2='30'
					/>
				</g>
				<g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='38'
						y1='30'
						x2='40'
						y2='30'
					/>
				</g>
				<g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='42'
						y1='30'
						x2='44'
						y2='30'
					/>
				</g>
				<g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='46'
						y1='30'
						x2='48'
						y2='30'
					/>
				</g>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='30'
					y1='42'
					x2='32'
					y2='42'
				/>
				<g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='34'
						y1='42'
						x2='36'
						y2='42'
					/>
				</g>
				<g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='38'
						y1='42'
						x2='40'
						y2='42'
					/>
				</g>
				<g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='42'
						y1='42'
						x2='44'
						y2='42'
					/>
				</g>
				<g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='46'
						y1='42'
						x2='48'
						y2='42'
					/>
				</g>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='18'
					y1='37'
					x2='18'
					y2='39'
				/>
			</g>
		</g>
	</svg>
)
export default IconSVG
