import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x34_4_x5F_escape_x5F_your_x5F_comfort_x5F_zone'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='2'
				y1='50.999'
				x2='0'
				y2='50.999'
			/>
			<polyline
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				points='13,63.999 13,61 
		16,57.999 18,58.999 20,58.999 24,54.999 27,54.999 27,50.999 4,50.999 	'
			/>
			<g>
				<circle
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					cx='38'
					cy='5'
					r='4'
				/>
				<polyline
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					points='33,15 25,17 
			19,23 16,20 23,13 34,10 		'
				/>
				<polyline
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					points='34,29.999 42,32 
			48,42 52,40 47,29 37,24 39,17 48,16 55,9 52,5.999 46,11.999 39,11.999 		'
				/>
				<polyline
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					points='35,28 32,38 
			21,47 18,44 26,36 29,27 30.034,15.742 		'
				/>
			</g>
			<path
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				d='M54.002,16.997C59.357,19.858,63,25.504,63,32
		c0,9.389-7.611,17-17,17c-4.705,0-8.963-1.911-12.041-4.999'
			/>
		</g>
	</svg>
)
export default IconSVG
