import { graphql, useStaticQuery } from 'gatsby'
import toLower from 'lodash/toLower'
import uniq from 'lodash/uniq'
import get from 'lodash/get'
import compact from 'lodash/compact'

import { useStoreActions } from '../store'
import { parsePageURI, isProxy } from '../utils'

const getParentType = (node, defaultVal) => {
	const { group1, group2 } = node
	let parent = group2 || group1
	if (parent) parent = parent[0]
	return get(parent, 'breadcrumb') || defaultVal
}

const buildIndex = ({ title = '', slug = '', name = '', breadcrumb, description = '' }) =>
	toLower(`${title || name || slug} ${description} ${breadcrumb ? breadcrumb : ''}`)

const BuildIndexes = () => {
	const {
		pages,
		posts,
		campuses,
		courses,
		profiles,
		// instructors,
		jobs,
	} = useStaticQuery(graphql`
		query siteIndexesQuery {
			pages: allContentfulPage {
				edges {
					node {
						id: contentful_id
						title: pageTitle
						breadcrumb
						slug
						group1: pagegroup {
							id: contentful_id
							slug
							breadcrumb
						}
						group2: pagegroup2 {
							id: contentful_id
							slug
							breadcrumb
							group: pagegroup {
								id: contentful_id
								slug
							}
						}
					}
				}
			}
			posts: allContentfulBlog {
				edges {
					node {
						id: contentful_id
						title
						breadcrumb
						slug
						tags
						group1: pagegroup {
							id: contentful_id
							slug
							breadcrumb
						}
						group2: pagegroup2 {
							id: contentful_id
							slug
							breadcrumb
							group: pagegroup {
								id: contentful_id
								slug
							}
						}
					}
				}
			}
			campuses: allContentfulCampus {
				edges {
					node {
						id: contentful_id
						slug
						title: name
					}
				}
			}
			courses: allDoceboCourses(filter: { name: { ne: "proxy" } }) {
				edges {
					node {
						id: courseId
						title: name
						slug
						isPublished
						category {
							name
						}
						topic
						tags
					}
				}
			}
			profiles: allContentfulProfile {
				edges {
					node {
						id: contentful_id
						title: name
						orgTitle
						slug
						group1: pagegroup {
							id: contentful_id
							slug
							breadcrumb
						}
						group2: pagegroup2 {
							id: contentful_id
							slug
							breadcrumb
							title
							group: pagegroup {
								id: contentful_id
								slug
							}
						}
					}
				}
			}
			instructors: allDoceboInstructors(filter: { name: { ne: "proxy" } }) {
				edges {
					node {
						id: userId
						title: name
						orgTitle
						slug
					}
				}
			}
			jobs: allJazzJobs {
				edges {
					node {
						id: jobId
						title
					}
				}
			}
		}
	`)

	const {
		updateSearchIndex,
		updateCourseTags,
		updateCourseTopics,
		updateBlogTags,
	} = useStoreActions(actions => actions.Meta)

	// Build global search index

	const pagesArry = [
		...pages.edges.map(({ node }) => ({
			...node,
			breadcrumb: `${getParentType(node, 'Page')} - ${node.breadcrumb || node.title}`,
		})),
		...posts.edges.map(({ node }) => ({
			...node,
			breadcrumb: `${getParentType(node, 'Blog Post')} - ${node.breadcrumb}`,
		})),
		...campuses.edges.map(({ node }) => ({
			...node,
			breadcrumb: `Campus - ${node.title}`,
			slug: `campuses/${node.slug}`,
		})),
		...profiles.edges.map(({ node }) => ({
			...node,
			breadcrumb: `${(getParentType(node), 'Profile')} - ${node.title} | ${node.orgTitle}`,
		})),
		...courses.edges
			.filter(({ node }) => node.isPublished)
			.map(({ node }) => ({
				...node,
				breadcrumb: `Course - ${node.title}`,
				slug: `courses/${node.slug}`,
			})),
		// ...instructors.edges.map(({ node }) => ({
		// 	...node,
		// 	breadcrumb: `Instructor - ${node.title} | ${node.orgTitle}`,
		// 	slug: `instructors/${node.slug}`,
		// })),
		...jobs.edges.map(({ node }) => ({
			...node,
			breadcrumb: `Job Posting - ${node.title}`,
			slug: `careers/${node.id}`,
		})),
	]

	const searchIndex = pagesArry
		.filter(({ title }) => !isProxy(title))
		.map(page => ({
			title:
				get(page, 'breadcrumb') || get(page, 'title') || get(page, 'name', 'ACI Learning Page...'),
			indexStr: buildIndex(page),
			uri: parsePageURI(page),
		}))

	updateSearchIndex(searchIndex)

	// Build course topics

	const topics = compact(
		uniq(
			courses.edges
				.reduce((arry, { node }) => (node.topic ? [...arry, ...node.topic.split(',')] : arry), [])
				.filter(topic => topic !== 'proxy')
		)
	)

	updateCourseTopics(topics)

	// Build course tags

	const courseTags = uniq(
		...courses.edges.map(({ node }) => node.tags).filter(({ name }) => name !== 'proxy')
	)

	updateCourseTags(courseTags)

	// Build blog tags

	const blogTags = uniq(
		posts.edges.reduce((arry, { node }) => [...arry, ...(node.tags || [])], [])
	).filter(tag => !isProxy(tag))

	updateBlogTags(blogTags)

	return null
}
BuildIndexes.propTypes = {}
export default BuildIndexes
