import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x32_8_x5F_certificate_x5F_degree'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='52px'
		height='64px'
		viewBox='0 0 52 64'
		style={{ enableBackground: 'new 0 0 52 64' }}
	>
		<g>
			<g>
				<g>
					<g>
						<path
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							d='M44,13h1
					c3.313,0,6-2.686,6-6s-2.687-6-6-6'
						/>
					</g>
					<g>
						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='19'
							y1='13'
							x2='21'
							y2='13'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='23'
							y1='13'
							x2='25'
							y2='13'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='15'
							y1='13'
							x2='17'
							y2='13'
						/>
					</g>
					<g>
						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='8'
							y1='19'
							x2='31'
							y2='19'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='8'
							y1='23'
							x2='31'
							y2='23'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='8'
							y1='27'
							x2='31'
							y2='27'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='8'
							y1='31'
							x2='31'
							y2='31'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='8'
							y1='35'
							x2='31'
							y2='35'
						/>
					</g>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M27,57h12V7
				c0-3.313,2.686-6,6-6H7C3.686,1,1,3.687,1,7v50h12'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='24,52 24,63 
				20,59 16,63 16,52 			'
					/>
					<circle
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						cx='20'
						cy='47'
						r='6'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='8'
						y1='47'
						x2='10'
						y2='47'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='29'
						y1='47'
						x2='31'
						y2='47'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='43'
						y1='12'
						x2='43'
						y2='47'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
