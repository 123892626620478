import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_5_x5F_workgroup_x5F_people'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='56px'
		height='64px'
		viewBox='0 0 56 64'
		style={{ enableBackground: 'new 0 0 56 64' }}
	>
		<g>
			<g>
				<g>
					<g>
						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='28'
							y1='64'
							x2='28'
							y2='43'
						/>
						<circle
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							cx='28'
							cy='6'
							r='5'
						/>
						<path
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							d='M39,22v-1
					c0-3.313-2.687-6-6-6H23c-3.314,0-6,2.687-6,6v1'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='11'
							y1='64'
							x2='11'
							y2='47'
						/>
						<circle
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							cx='11'
							cy='14'
							r='4'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M17,64V44h4V27c0-2.762-2.238-5-5-5H6
					c-2.762,0-5,2.238-5,5v17h4v20'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='45'
							y1='64'
							x2='45'
							y2='47'
						/>
						<circle
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							cx='45'
							cy='14'
							r='4'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M51,64V44h4V27c0-2.762-2.238-5-5-5H40
					c-2.762,0-5,2.238-5,5v17h4v20'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='28'
							y1='18'
							x2='28'
							y2='20'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='28'
							y1='22'
							x2='28'
							y2='24'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='45'
							y1='25'
							x2='45'
							y2='27'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='11'
							y1='25'
							x2='11'
							y2='27'
						/>
					</g>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='21'
						y1='44'
						x2='21'
						y2='64'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='35'
						y1='44'
						x2='35'
						y2='64'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
