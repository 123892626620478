import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x33_6_x5F_cyber_x5F_security'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M41,43c0,1.1-0.9,2-2,2H25c-1.1,0-2-0.9-2-2V31
			c0-1.1,0.9-2,2-2h14c1.1,0,2,0.9,2,2V43z'
				/>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M25,29v-4c0-3.3,2.7-6,6-6h2c3.3,0,6,2.7,6,6v4'
				/>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='32'
					cy='35'
					r='1'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='32'
					y1='36'
					x2='32'
					y2='41'
				/>
			</g>
			<g>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='16'
					y1='32'
					x2='7'
					y2='32'
				/>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='4'
					cy='32'
					r='3'
				/>
			</g>
			<g>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='48'
					y1='32'
					x2='57'
					y2='32'
				/>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='60'
					cy='32'
					r='3'
				/>
			</g>
			<g>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M44,39h4c2.2,0,4,1.8,4,4v6'
				/>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='52'
					cy='52'
					r='3'
				/>
			</g>
			<g>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M20,39h-4c-2.2,0-4,1.8-4,4v6'
				/>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='12'
					cy='52'
					r='3'
				/>
			</g>
			<g>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M44,25h4c2.2,0,4-1.8,4-4v-6'
				/>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='52'
					cy='12'
					r='3'
				/>
			</g>
			<g>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M20,25h-4c-2.2,0-4-1.8-4-4v-6'
				/>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='12'
					cy='12'
					r='3'
				/>
			</g>
			<circle
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='26'
				cy='52'
				r='3'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='26'
				y1='55'
				x2='26'
				y2='64'
			/>
			<circle
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='38'
				cy='52'
				r='3'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='38'
				y1='55'
				x2='38'
				y2='64'
			/>
			<circle
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='26'
				cy='12'
				r='3'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='26'
				y1='9'
				x2='26'
				y2='0'
			/>
			<circle
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='38'
				cy='12'
				r='3'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='38'
				y1='9'
				x2='38'
				y2='0'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='44'
				y1='32'
				x2='46'
				y2='32'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='20'
				y1='32'
				x2='18'
				y2='32'
			/>
		</g>
	</svg>
)
export default IconSVG
