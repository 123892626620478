import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x31_6_x5F_cloud_x5F_file_x5F_access'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<g>
					<polygon
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='47,63 17,63 17,23 41,23 47,29 			'
					/>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='47,29 41,29 41,23 			'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M50,41h1c6.627,0,12-5.373,12-12
				c0-5.947-4-11-10-11c0-5-3-9-9-9h-1c-2.728-4.924-7.972-8-14-8c-8.836,0-16,7.164-16,16C6.373,17,1,22.373,1,29
				c0,6.627,5.373,12,12,12h1'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='27'
						y1='53'
						x2='29'
						y2='53'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='31'
						y1='53'
						x2='33'
						y2='53'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='35'
						y1='53'
						x2='37'
						y2='53'
					/>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='43,32 43,59 21,59 21,27 38,27 			'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
