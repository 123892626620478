import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x31_5_x5F_cloud_x5F_security'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<g>
					<g>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M51,41c6.627,0,12-5.373,12-12
					c0-5.947-4-11-10-11c0-5-3-9-9-9h-1c-2.728-4.924-7.972-8-14-8c-8.836,0-16,7.164-16,16C6.373,17,1,22.373,1,29
					c0,6.627,5.373,12,12,12'
						/>
					</g>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M48,60c0,1.656-1.344,3-3,3H19
				c-1.656,0-3-1.344-3-3V38c0-1.656,1.344-3,3-3h26c1.656,0,3,1.344,3,3V60z'
					/>
					<g>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='31'
							y1='41'
							x2='33'
							y2='41'
						/>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='35'
							y1='41'
							x2='37'
							y2='41'
						/>
						<line
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							x1='27'
							y1='41'
							x2='29'
							y2='41'
						/>
					</g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='20'
						y1='57'
						x2='26'
						y2='51'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='26'
						y1='57'
						x2='32'
						y2='51'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='32'
						y1='57'
						x2='38'
						y2='51'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='38'
						y1='57'
						x2='44'
						y2='51'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='19'
						y1='51'
						x2='48'
						y2='51'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='16'
						y1='57'
						x2='45'
						y2='57'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M38,32v-5c0-3.313-2.687-6-6-6
				c-3.314,0-6,2.687-6,6v5'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M44,35v-8c0-6.627-5.373-12-12-12
				s-12,5.373-12,12v8'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
