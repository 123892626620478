import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x33_1_x5F_employer_x5F_avatar'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='54px'
		height='64px'
		viewBox='0 0 54 64'
		style={{ enableBackground: 'new 0 0 54 64' }}
	>
		<g>
			<g>
				<g>
					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='11'
						y1='56'
						x2='11'
						y2='64'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='43'
						y1='56'
						x2='43'
						y2='64'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M24,7c0,3,3,10,12,10l0,0
				c0,7.18-1,14-9,14s-9-6.82-9-14v-2c0-2.209,1.791-4,4-4'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M21,32c-3,0-9-2-9-2
				s2-8,2-13c0-4,0-6,0-6c0-5.523,4.477-10,10-10h6c5.523,0,10,4.477,10,10c0,0,0,3,0,6c0,5,2,13,2,13s-6,2-9,2'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='21,29 21,38 
				27,61 33,38 33,29 			'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M33,36
				c0,2.21-2.687,4-6,4c-3.314,0-6-1.79-6-4'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='27,61 18,47 
				12,47 21,34 			'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='27,61 36,47 
				42,47 33,34 			'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M53,64V53c0-6.075-3-9-9-11l-8-4'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M1,64V53c0-6.075,3-9,9-11l8-4'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
