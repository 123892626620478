import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x35_1_x5F_drivers_x5F_installation'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='60px'
		viewBox='0 0 64 60'
		style={{ enableBackground: 'new 0 0 64 60' }}
	>
		<g>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='20'
				y1='54.792'
				x2='25'
				y2='44.792'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='39'
				y1='44.792'
				x2='44'
				y2='54.792'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='50'
				y1='59'
				x2='64'
				y2='59'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='22'
				y1='59'
				x2='0'
				y2='59'
			/>
			<polyline
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				points='17,59 17,55 47,55 47,59 24,59 	'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='50'
				y1='55'
				x2='52'
				y2='55'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='54'
				y1='55'
				x2='56'
				y2='55'
			/>
			<polyline
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				points='1,34 1,1 29,1 	'
			/>
			<polyline
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				points='32,27 32,1 63,1 63,37 1,37 1,45 63,45 
		63,40 	'
			/>
			<polyline
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				points='37,21.792 32,26.792 27,21.792 	'
			/>
			<path
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				d='M28,16.792c0-3.866,3.134-7,7-7s7,3.134,7,7
		c3.866,0,7,3.238,7,7.104S45.866,31,42,31H21c-3.313,0-6-2.791-6-6.104s2.687-6.052,6-6.052c0-2.761,2.238-5.026,5-5.026'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='6'
				y1='7'
				x2='18'
				y2='7'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='6'
				y1='11'
				x2='8'
				y2='11'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='10'
				y1='11'
				x2='12'
				y2='11'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='6'
				y1='15'
				x2='8'
				y2='15'
			/>
		</g>
	</svg>
)
export default IconSVG
