import React from 'react'

const SearchIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='11.771'
			height='22.541'
			viewBox='0 0 11.771 22.541'
		>
			<defs>
				<clipPath id='clip-path'>
					<rect width='11.771' height='22.541' fill='none' />
				</clipPath>
			</defs>
			<g id='Facebook' clipPath='url(#clip-path)'>
				<path
					id='Path_1'
					data-name='Path 1'
					d='M87.639,22.541V12.272h3.506l.5-4.007H87.639V5.76c0-1.127.376-2,2-2h2.129V.125C91.27.125,90.018,0,88.641,0c-3.005,0-5.134,1.878-5.134,5.26V8.265H80v4.007h3.506V22.541Z'
					transform='translate(-80)'
					fill='currentColor'
					fillRule='evenodd'
				/>
			</g>
		</svg>
	)
}
SearchIcon.propTypes = {}
export default SearchIcon
