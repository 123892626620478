import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_2_x5F_features_x5F_list'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='56px'
		height='64px'
		viewBox='0 0 56 64'
		style={{ enableBackground: 'new 0 0 56 64' }}
	>
		<g>
			<g>
				<g>
					<circle
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						cx='16'
						cy='16'
						r='15'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M20,31v20c0,2.209-1.791,4-4,4s-4-1.791-4-4V31'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='16'
						y1='40'
						x2='16'
						y2='42'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='16'
						y1='44'
						x2='16'
						y2='46'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='16'
						y1='48'
						x2='16'
						y2='50'
					/>
				</g>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='32,7 55,7 55,54 46,63 16,63 16,58 		
			'
				/>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='46,63 46,54 55,54 		'
				/>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='23,16 23,23 9,23 9,9 20,9 		'
				/>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='23,11 16,18 13,15 		'
				/>
				<rect
					x='24'
					y='34'
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					width='4'
					height='4'
				/>
				<rect
					x='24'
					y='42'
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					width='4'
					height='4'
				/>
				<rect
					x='24'
					y='50'
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					width='4'
					height='4'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='50'
					y1='34'
					x2='31'
					y2='34'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='50'
					y1='38'
					x2='31'
					y2='38'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='50'
					y1='42'
					x2='31'
					y2='42'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='50'
					y1='46'
					x2='31'
					y2='46'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='41'
					y1='54'
					x2='31'
					y2='54'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='50'
					y1='50'
					x2='31'
					y2='50'
				/>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='34,18 49,18 49,28 31,28 		'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='50'
					y1='14'
					x2='48'
					y2='14'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='46'
					y1='14'
					x2='44'
					y2='14'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='42'
					y1='14'
					x2='40'
					y2='14'
				/>
			</g>
		</g>
	</svg>
)
export default IconSVG
