import React from 'react'
const Blocks = ({ className, ...props }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 173.335 173.511'
		className={`bg-blocks${className ? ` ${className}` : ''}`}
		{...props}
	>
		<defs>
			<style>{`
    .svg-blocks-a{opacity:0.583;}
    .svg-blocks-b{opacity:1;}
    .svg-blocks-c{opacity:0.298;}
    .svg-blocks-d{opacity:0.17;}
    .svg-blocks-e{opacity:0.489;}
    `}</style>
		</defs>
		<g className='svg-blocks-a' transform='translate(-288.939 -376.858)'>
			<rect
				className='svg-blocks-b'
				width='21.677'
				height='21.677'
				transform='translate(354.153 376.946)'
			/>
			<rect
				className='svg-blocks-c'
				width='21.677'
				height='21.677'
				transform='translate(288.939 528.692)'
			/>
			<rect
				className='svg-blocks-b'
				width='21.677'
				height='21.677'
				transform='translate(310.617 506.921)'
			/>
			<rect
				className='svg-blocks-d'
				width='21.677'
				height='21.677'
				transform='translate(310.617 485.244)'
			/>
			<rect
				className='svg-blocks-d'
				width='21.677'
				height='21.677'
				transform='translate(310.575 441.716)'
			/>
			<rect
				className='svg-blocks-b'
				width='21.677'
				height='21.677'
				transform='translate(332.165 441.716)'
			/>
			<rect
				className='svg-blocks-d'
				width='21.677'
				height='21.677'
				transform='translate(332.294 463.48)'
			/>
			<rect className='e' width='21.677' height='21.677' transform='translate(332.252 419.955)' />
			<rect
				className='svg-blocks-c'
				width='21.677'
				height='21.677'
				transform='translate(397.243 420.212)'
			/>
			<rect
				className='svg-blocks-d'
				width='21.677'
				height='21.677'
				transform='translate(397.243 398.535)'
			/>
			<rect
				className='svg-blocks-c'
				width='21.677'
				height='21.677'
				transform='translate(440.597 398.535)'
			/>
			<rect
				className='svg-blocks-c'
				width='21.677'
				height='21.677'
				transform='translate(418.92 376.858)'
			/>
			<rect
				className='svg-blocks-b'
				width='21.677'
				height='21.677'
				transform='translate(418.92 441.89)'
			/>
			<rect
				className='svg-blocks-b'
				width='21.677'
				height='21.677'
				transform='translate(397.243 463.566)'
			/>
			<rect
				className='svg-blocks-d'
				width='21.677'
				height='21.677'
				transform='translate(397.243 485.244)'
			/>
			<rect className='e' width='21.677' height='21.677' transform='translate(375.697 506.921)' />
			<rect
				className='svg-blocks-c'
				width='21.677'
				height='21.677'
				transform='translate(353.883 485.244)'
			/>
			<rect
				className='svg-blocks-d'
				width='21.677'
				height='21.677'
				transform='translate(353.84 441.716)'
			/>
		</g>
	</svg>
)
export default Blocks
