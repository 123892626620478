import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_2_x5F_workflow_x5F_planning'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='56px'
		height='64px'
		viewBox='0 0 56 64'
		style={{ enableBackground: 'new 0 0 56 64' }}
	>
		<g>
			<g>
				<rect
					x='15'
					y='27'
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					width='26'
					height='10'
				/>
				<path
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					d='M12,30H8
			c-1.105,0-2-0.896-2-2V15'
				/>
				<polyline
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					points='10,19 6,15 2,19 
					'
				/>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M44,30h4c1.104,0,2-0.896,2-2V15'
				/>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='46,19 50,15 54,19 		'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='28'
					y1='24'
					x2='28'
					y2='15'
				/>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='32,19 28,15 24,19 		'
				/>
				<path
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					d='M12,34H8
			c-1.105,0-2,0.896-2,2v13'
				/>
				<polyline
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					points='10,45 6,49 2,45 
					'
				/>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M44,34h4c1.104,0,2,0.896,2,2v13'
				/>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='46,45 50,49 54,45 		'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='28'
					y1='40'
					x2='28'
					y2='49'
				/>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='32,45 28,49 24,45 		'
				/>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='6'
					cy='6'
					r='5'
				/>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='28'
					cy='6'
					r='5'
				/>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='50'
					cy='6'
					r='5'
				/>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='6'
					cy='58'
					r='5'
				/>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='28'
					cy='58'
					r='5'
				/>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='50'
					cy='58'
					r='5'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='27'
					y1='32'
					x2='29'
					y2='32'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='23'
					y1='32'
					x2='25'
					y2='32'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='31'
					y1='32'
					x2='33'
					y2='32'
				/>
			</g>
		</g>
	</svg>
)
export default IconSVG
