import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x33_6_x5F_secure_x5F_payment'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='56px'
		viewBox='0 0 64 56'
		style={{ enableBackground: 'new 0 0 64 56' }}
	>
		<g>
			<g>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M53,25c0,1.1-0.9,2-2,2H37c-1.1,0-2-0.9-2-2V13
			c0-1.1,0.9-2,2-2h14c1.1,0,2,0.9,2,2V25z'
				/>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M37,11V7c0-3.3,2.7-6,6-6h2c3.3,0,6,2.7,6,6v4'
				/>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='44'
					cy='17'
					r='1'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='44'
					y1='18'
					x2='44'
					y2='23'
				/>
			</g>
			<g>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M8,43H4c-1.656,0-3-1.344-3-3V14
			c0-1.656,1.344-3,3-3h28'
				/>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M56,23h4c1.656,0,3,1.344,3,3v26
			c0,1.656-1.344,3-3,3H14c-1.656,0-3-1.344-3-3V26c0-1.656,1.344-3,3-3h18'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='32'
					y1='15'
					x2='1'
					y2='15'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='1'
					y1='23'
					x2='8'
					y2='23'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='32'
					y1='27'
					x2='11'
					y2='27'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='63'
					y1='27'
					x2='56'
					y2='27'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='11'
					y1='35'
					x2='63'
					y2='35'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='16'
					y1='41'
					x2='46'
					y2='41'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='16'
					y1='45'
					x2='32'
					y2='45'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='48'
					y1='41'
					x2='50'
					y2='41'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='52'
					y1='41'
					x2='54'
					y2='41'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='56'
					y1='41'
					x2='58'
					y2='41'
				/>
			</g>
		</g>
	</svg>
)
export default IconSVG
