import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Icon } from '../../UI'

const Stars = styled.div`
	.rating-stars {
		display: flex;
		justify-content: center;
		.star {
			height: 25px;
			width: 25px;
			margin: 20px 10px;
			color: rgb(241, 102, 34);
			${props => props.theme.media.sdesk} {
				margin: 20px;
				height: 50px;
				width: 50px;
			}
			svg {
				width: 100%;
				height: auto;
			}
		}
	}
`

const RatingStars = ({ rating }) => {
	const ratings = new Array(rating || 1).fill('')
	return (
		<Stars>
			<div className={`rating-stars`}>
				{ratings.map((_, idx) => (
					<Icon type='star' className='star' key={idx} />
				))}
			</div>
		</Stars>
	)
}

RatingStars.propTypes = {
	rating: PropTypes.number,
}
export default RatingStars
