import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x31_6_x5F_cloud_x5F_access'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='54px'
		viewBox='0 0 64 54'
		style={{ enableBackground: 'new 0 0 64 54' }}
	>
		<g>
			<g>
				<g>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinecap: 'round',
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='
				36,33 32,29 28,33 			'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M32,53v-5c0-3.866,3.134-7,7-7h12
				c6.627,0,12-5.373,12-12c0-5.947-4-11-10-11c0-5-3-9-9-9h-1c-2.728-4.924-7.972-8-14-8c-8.836,0-16,7.164-16,16
				C6.373,17,1,22.373,1,29c0,6.627,5.373,12,12,12h12c3.866,0,7-3.134,7-7v-5'
					/>

					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinecap: 'round',
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='
				28,49 32,53 36,49 			'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M19,18c0-6.075,5-11,10-11'
					/>
				</g>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='21'
					y1='35'
					x2='23'
					y2='35'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='13'
					y1='35'
					x2='15'
					y2='35'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='17'
					y1='35'
					x2='19'
					y2='35'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='49'
					y1='35'
					x2='51'
					y2='35'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='41'
					y1='35'
					x2='43'
					y2='35'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='45'
					y1='35'
					x2='47'
					y2='35'
				/>
			</g>
		</g>
	</svg>
)
export default IconSVG
