import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_6_x5F_company_x5F_structure'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='60px'
		viewBox='0 0 64 60'
		style={{ enableBackground: 'new 0 0 64 60' }}
	>
		<g>
			<g>
				<g>
					<g>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M37,6.5c0,3.5-1.963,6.5-5,6.5s-5-3-5-6.5
					S28,1,32,1S37,3,37,6.5z'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M35,12l7,4c0,0,0,0.344,0,2c0,1.657-1,3-2,3H24
					c-1,0-2-1.343-2-3c0-1.656,0-2,0-2l7-4'
						/>
					</g>
					<g>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M16,44.5c0,3.5-1.963,6.5-5,6.5s-5-3-5-6.5
					S7,39,11,39S16,41,16,44.5z'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M14,50l7,4c0,0,0,0.344,0,2c0,1.657-1,3-2,3H3
					c-1,0-2-1.343-2-3c0-1.656,0-2,0-2l7-4'
						/>
					</g>
					<g>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M58,44.5c0,3.5-1.963,6.5-5,6.5s-5-3-5-6.5
					s1-5.5,5-5.5S58,41,58,44.5z'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M56,50l7,4c0,0,0,0.344,0,2c0,1.657-1,3-2,3H45
					c-1,0-2-1.343-2-3c0-1.656,0-2,0-2l7-4'
						/>
					</g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='32'
						y1='26'
						x2='32'
						y2='36'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='32'
						y1='36'
						x2='22'
						y2='44'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='32'
						y1='36'
						x2='42'
						y2='44'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
