import React from 'react'
import { SkeletonTheme as Skeleton } from 'react-loading-skeleton'
import { useTheme } from 'styled-components'

import * as swfUtils from '../../utils'

export * from './Typography'

export * from './Loaders'

export { default as ErrorBoundary } from './ErrorBoundary'
export { default as Vimeo } from './Vimeo'
export { default as YT } from './YouTube'
export { default as GMap } from './GoogleMap'

export { default as Board } from './Board'
export { default as Block } from './Block'
export { default as Card } from './Card'
export { default as Table } from './Table'
export { default as RT } from './RT'
export { default as Modal } from './Modal'
export { default as RatingStars } from './RatingStars'
export { default as Trunc } from './Truncate'
export { default as Tag } from './Tag'

export { default as Accordion } from './Accordion'
export { default as BulletList } from './BulletList'
export { default as Hubspot } from './Hubspot'
export { default as Rating } from './Rating'

export * from './BG'
export * from './Accordion'

export const utils = swfUtils

export { default as Icon } from './Icons'
/**
 *  SKELETON THEME
 *  This provides defaults for skeleton loaders
 * */
export const SkeletonTheme = ({ children }) => {
	const { colors } = useTheme()
	const { loader, loaderAccent, grey } = colors
	const color = loader ? loader.color.val : grey.light().val
	const highlightColor = loaderAccent ? loaderAccent.val : grey.light('5').val
	const skeletonTheme = {
		color,
		highlightColor,
	}
	return <Skeleton {...skeletonTheme}>{children}</Skeleton>
}
