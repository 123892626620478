import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x31_9_x5F_user_x5F_interaction'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='60px'
		height='64px'
		viewBox='0 0 60 64'
		style={{ enableBackground: '0 0 60 64' }}
	>
		<g>
			<g>
				<g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='43'
						y1='35'
						x2='43'
						y2='38'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='38'
						y1='34'
						x2='38'
						y2='37'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='33'
						y1='32'
						x2='33'
						y2='36'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M27,64v-4c0-0.553,0.447-1,1-1h19
				c0.553,0,1,0.447,1,1v4'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M45,33
				c1.656,0,3,1.344,3,3v7c0,7-2,11-2,13v3'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M40,31
				c1.656,0,3,1.344,3,3v2'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M35,29
				c1.656,0,3,1.344,3,3v2'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinecap: 'round',
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='
				M27,41V20c0-1.656,1.344-3,3-3c1.656,0,3,1.344,3,3v12'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M29,59v-3l-6-8
				c0,0-3.293-12.293-4-13c-1-1,0-3,2-3s3,3,3,3l3,6'
					/>
				</g>
				<path
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					d='M27.004,26.328
			C24.637,25.205,23,22.794,23,20c0-3.866,3.134-7,7-7c3.866,0,7,3.134,7,7c0,2.793-1.635,5.203-4,6.326'
				/>
				<path
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					d='M20.778,26
			C19.653,24.273,19,22.213,19,20c0-6.075,4.925-11,11-11c6.075,0,11,4.925,11,11c0,2.214-0.654,4.274-1.779,6'
				/>
				<path
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					d='M15,20
			c0-8.284,6.716-15,15-15c8.284,0,15,6.716,15,15'
				/>
				<path
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					d='M15,39H2
			c-0.552,0-1-0.447-1-1V2c0-0.553,0.448-1,1-1h56c0.552,0,1,0.447,1,1v36c0,0.553-0.448,1-1,1h-7'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='5'
					y1='39'
					x2='5'
					y2='4'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='55'
					y1='36'
					x2='55'
					y2='1'
				/>
			</g>
		</g>
	</svg>
)
export default IconSVG
