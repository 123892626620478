import React from 'react'
const IconSVG = ({ props }) => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 64'>
		<defs></defs>
		<g id='Layer_2' data-name='Layer 2'>
			<g id='FUTURO_ICONS' data-name='FUTURO ICONS'>
				<g id='LINE_ICONS' data-name='LINE ICONS'>
					<g id='_06._BUSINESS_MANAGEMENT' data-name='06. BUSINESS MANAGEMENT'>
						<g id='_06_cv_resume' data-name='06_cv_resume'>
							<polyline
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
									strokeLinejoin: 'round',
								}}
								points='35 5 47 5 47 63 1 63 1 5 13 5'
							/>
							<rect
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
									strokeLinejoin: 'round',
								}}
								x='13'
								y='1'
								width='22'
								height='10'
							/>
							<polyline
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
									strokeLinejoin: 'round',
								}}
								points='38 9 41 9 41 57 7 57 7 9 10 9'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
									strokeMiterlimit: '10',
								}}
								x1='13'
								y1='41'
								x2='35'
								y2='41'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
									strokeMiterlimit: '10',
								}}
								x1='13'
								y1='45'
								x2='35'
								y2='45'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
									strokeMiterlimit: '10',
								}}
								x1='13'
								y1='49'
								x2='25'
								y2='49'
							/>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
									strokeMiterlimit: '10',
								}}
								d='M29,22.5C29,26,27,29,24,29s-5-3-5-6.5S20,17,24,17,29,19,29,22.5Z'
							/>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
									strokeMiterlimit: '10',
								}}
								d='M27,28l7,4v2c0,1.66-1,3-2,3H16c-1,0-2-1.34-2-3V32l7-4'
							/>
							<polyline
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: '2px',
									strokeLinejoin: 'round',
								}}
								points='17 4 17 7 31 7 31 4'
							/>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
