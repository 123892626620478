import React from 'react'

const ProfileLoginIcon = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='23' height='23' viewBox='0 0 23 23'>
			<defs>
				<clipPath id='profile-clip-path'>
					<rect width='23' height='23' fill='none' />
				</clipPath>
			</defs>
			<g clipPath='url(#profile-clip-path)'>
				<path
					dataname='Union 1'
					d='M0,23V20.125c0-3.163,5.175-5.751,11.5-5.751S23,16.962,23,20.125V23ZM5.749,5.751A5.75,5.75,0,1,1,11.5,11.5,5.751,5.751,0,0,1,5.749,5.751Z'
					fill='currentColor'
				/>
			</g>
		</svg>
	)
}
ProfileLoginIcon.propTypes = {}
export default ProfileLoginIcon
