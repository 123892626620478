import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x32_8_x5F_online_x5F_library'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<g>
					<g>
						<g>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								d='M19.005,10.506C15.344,13.439,13,17.945,13,23
						C6.373,23,1,28.373,1,35c0,6.628,5.373,12,12,12h3'
							/>
							<path
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								d='M48,47h3c6.627,0,12-5.372,12-12
						c0-5.947-4-11-10-11c0-4.375-2.297-7.984-6.891-8.818'
							/>
						</g>
					</g>
					<g>
						<g>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='39'
								y1='14'
								x2='26'
								y2='14'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='39'
								y1='18'
								x2='26'
								y2='18'
							/>
							<line
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								x1='39'
								y1='22'
								x2='26'
								y2='22'
							/>
						</g>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M19,35h27V1H21c-1.105,0-2,0.895-2,2v34
					c0,1.104,0.895,2,2,2h26'
						/>
					</g>
					<g>
						<polyline
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='27,51 23,47 
					19,51 				'
						/>
						<polyline
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='45,51 41,47 
					37,51 				'
						/>
						<polyline
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='36,59 32,63 
					28,59 				'
						/>
						<polyline
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='23,47 23,57 
					0,57 				'
						/>
						<polyline
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='41,47 41,57 
					64,57 				'
						/>

						<line
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							x1='32'
							y1='46'
							x2='32'
							y2='63'
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
