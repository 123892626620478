import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x31_5_x5F_touchscreen_x5F_technology'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='58px'
		height='64px'
		viewBox='0 0 58 64'
		style={{ enableBackground: 'new 0 0 58 64' }}
	>
		<g>
			<g>
				<g>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M20,64V42
				c0-4.971,4.029-9,9-9c4.971,0,9,4.029,9,9v22'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M34,42
				c0,2.762-2.238,5-5,5c-2.762,0-5-2.238-5-5'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='25'
						y1='55'
						x2='33'
						y2='55'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='25'
						y1='59'
						x2='33'
						y2='59'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='26'
						y1='15'
						x2='8'
						y2='15'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='30'
						y1='15'
						x2='28'
						y2='15'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='33'
						y1='30'
						x2='33'
						y2='12'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='19'
						y1='15'
						x2='19'
						y2='4'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='29,12 29,9 
				42,9 			'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='29'
						y1='30'
						x2='29'
						y2='22'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='36,19 45,19 
				45,4 			'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='25,30 25,19 
				30,19 			'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='44'
						y1='1'
						x2='46'
						y2='1'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='18'
						y1='1'
						x2='20'
						y2='1'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='48'
						y1='9'
						x2='50'
						y2='9'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='52'
						y1='9'
						x2='54'
						y2='9'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='56'
						y1='9'
						x2='58'
						y2='9'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='54'
						y1='25'
						x2='56'
						y2='25'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='4'
						y1='15'
						x2='6'
						y2='15'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='0'
						y1='15'
						x2='2'
						y2='15'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='2'
						y1='5'
						x2='4'
						y2='5'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='10'
						y1='1'
						x2='12'
						y2='1'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M21,32'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='11,18 11,23 
				21,23 21,30 			'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='11,12 11,5 6,5 
							'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='52,25 37,25 
				37,30 			'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='22'
						y1='19'
						x2='20'
						y2='19'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='18'
						y1='19'
						x2='16'
						y2='19'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='33,6 33,1 22,1 
							'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='49,22 49,19 
				56,19 			'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='41'
						y1='39'
						x2='43'
						y2='39'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='41'
						y1='43'
						x2='43'
						y2='43'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='41'
						y1='47'
						x2='43'
						y2='47'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='15'
						y1='39'
						x2='17'
						y2='39'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='15'
						y1='43'
						x2='17'
						y2='43'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='15'
						y1='47'
						x2='17'
						y2='47'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='18'
						y1='27'
						x2='10'
						y2='27'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='8'
						y1='27'
						x2='6'
						y2='27'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M17,59h-6
				c-2.209,0-4-1.791-4-4V30'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M51,30v25
				c0,2.209-1.791,4-4,4h-6'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='24'
						y1='42'
						x2='24'
						y2='40'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='34'
						y1='42'
						x2='34'
						y2='40'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
