import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_6_x5F_business_x5F_graph'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='60px'
		viewBox='0 0 64 60'
		style={{ enableBackground: 'new 0 0 64 60' }}
	>
		<g>
			<g>
				<g>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='20,6 63,6 63,44 22,44 			'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='22'
						y1='40'
						x2='60'
						y2='40'
					/>
					<g>
						<polyline
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							points='27,37 27,32 23,32 23,37 				'
						/>
						<polyline
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							points='43,37 43,25 39,25 39,37 				'
						/>
						<polyline
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							points='51,37 51,19 47,19 47,37 				'
						/>
						<polyline
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							points='59,37 59,14 55,14 55,37 				'
						/>
						<polyline
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							points='35,37 35,29 31,29 31,37 				'
						/>
					</g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='12'
						y1='60'
						x2='12'
						y2='39'
					/>
					<circle
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						cx='12'
						cy='6'
						r='5'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M5,60V36H1V22c0-3.866,3.134-7,7-7h27v6H19v39'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='12'
						y1='18'
						x2='12'
						y2='20'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='12'
						y1='22'
						x2='12'
						y2='24'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
