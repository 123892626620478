import React from 'react'

const SearchIcon = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='23' height='23' viewBox='0 0 23 23'>
			<defs>
				<clipPath id='ig-clip-path'>
					<rect width='23' height='23' fill='none' />
				</clipPath>
			</defs>
			<g clipPath='url(#ig-clip-path)'>
				<path
					dataname='Path 3'
					d='M11.27,2a34.558,34.558,0,0,1,4.508.125,5.808,5.808,0,0,1,2.129.376,4.4,4.4,0,0,1,2.129,2.129,5.808,5.808,0,0,1,.376,2.129c0,1.127.125,1.5.125,4.508a34.558,34.558,0,0,1-.125,4.508,5.808,5.808,0,0,1-.376,2.129,4.4,4.4,0,0,1-2.129,2.129,5.808,5.808,0,0,1-2.129.376c-1.127,0-1.5.125-4.508.125a34.558,34.558,0,0,1-4.508-.125,5.808,5.808,0,0,1-2.129-.376A4.4,4.4,0,0,1,2.5,17.907a5.808,5.808,0,0,1-.376-2.129c0-1.127-.125-1.5-.125-4.508a34.558,34.558,0,0,1,.125-4.508A5.808,5.808,0,0,1,2.5,4.633a4.5,4.5,0,0,1,.877-1.252A2.117,2.117,0,0,1,4.633,2.5a5.808,5.808,0,0,1,2.129-.376A34.558,34.558,0,0,1,11.27,2m0-2A37,37,0,0,0,6.637.125a7.731,7.731,0,0,0-2.755.5,4.9,4.9,0,0,0-2,1.252,4.9,4.9,0,0,0-1.252,2,5.706,5.706,0,0,0-.5,2.755A37,37,0,0,0,0,11.27,37,37,0,0,0,.125,15.9a7.731,7.731,0,0,0,.5,2.755,4.9,4.9,0,0,0,1.252,2,4.9,4.9,0,0,0,2,1.252,7.731,7.731,0,0,0,2.755.5,37,37,0,0,0,4.633.125,37,37,0,0,0,4.633-.125,7.731,7.731,0,0,0,2.755-.5,5.254,5.254,0,0,0,3.256-3.256,7.731,7.731,0,0,0,.5-2.755c0-1.252.125-1.628.125-4.633a37,37,0,0,0-.125-4.633,7.731,7.731,0,0,0-.5-2.755,4.9,4.9,0,0,0-1.252-2,4.9,4.9,0,0,0-2-1.252A7.731,7.731,0,0,0,15.9.125,37,37,0,0,0,11.27,0m0,5.51a5.668,5.668,0,0,0-5.76,5.76,5.76,5.76,0,1,0,5.76-5.76m0,9.517A3.69,3.69,0,0,1,7.514,11.27,3.69,3.69,0,0,1,11.27,7.514a3.69,3.69,0,0,1,3.757,3.757,3.69,3.69,0,0,1-3.757,3.757M17.281,3.882A1.377,1.377,0,1,0,18.659,5.26a1.39,1.39,0,0,0-1.377-1.377'
					fill='currentColor'
					fillRule='evenodd'
				/>
			</g>
		</svg>
	)
}
SearchIcon.propTypes = {}
export default SearchIcon
