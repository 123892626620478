import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x35_5_x5F_electronic_x5F_signature'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='56px'
		height='64px'
		viewBox='0 0 56 64'
		style={{ enableBackground: 'new 0 0 56 64' }}
	>
		<g>
			<polyline
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				points='9,38 9,1 15,1 	'
			/>
			<polyline
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				points='26,1 47,1 55,9 55,63 20,63 	'
			/>
			<g>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='8,51 17,51 17,63 1,63 1,51 6,51 		'
				/>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M15,51v-4c0-3.313-2.686-6-6-6s-6,2.687-6,6v4'
				/>
			</g>
			<polygon
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				points='23,1 23,16 19,12 15,16 15,1 	'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='30'
				y1='53'
				x2='50'
				y2='53'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='20'
				y1='57'
				x2='46'
				y2='57'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='34'
				y1='49'
				x2='36'
				y2='49'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='30'
				y1='49'
				x2='32'
				y2='49'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='38'
				y1='49'
				x2='50'
				y2='49'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='48'
				y1='57'
				x2='50'
				y2='57'
			/>
			<polyline
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				points='55,9 47,9 47,1 	'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='14'
				y1='33'
				x2='30'
				y2='33'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='14'
				y1='29'
				x2='50'
				y2='29'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='14'
				y1='25'
				x2='50'
				y2='25'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='50'
				y1='15'
				x2='38'
				y2='15'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='14'
				y1='57'
				x2='12'
				y2='57'
			/>
		</g>
	</svg>
)
export default IconSVG
