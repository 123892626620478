import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x34_5_x5F_international_x5F_business'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='60px'
		viewBox='0 0 64 60'
		style={{ enableBackground: 'new 0 0 64 60' }}
	>
		<g>
			<g>
				<g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='11'
						y1='38'
						x2='11'
						y2='59'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='11'
						y1='16'
						x2='11'
						y2='18'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='5'
						y1='35'
						x2='14'
						y2='35'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='5'
						y1='37'
						x2='5'
						y2='59'
					/>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M17,59V21c0-2.209,1.791-4,4-4h16v-4H5
				c-2.209,0-4,1.791-4,4v20h4V20'
					/>
					<circle
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						cx='11'
						cy='5'
						r='4'
					/>
				</g>
			</g>
			<g>
				<circle
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					cx='40'
					cy='40'
					r='19'
				/>

				<ellipse
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					cx='40'
					cy='40'
					rx='13'
					ry='19'
				/>

				<ellipse
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					cx='40'
					cy='40'
					rx='7'
					ry='19'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='40'
					y1='21'
					x2='40'
					y2='59'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='54'
					y1='27'
					x2='26'
					y2='27'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='55'
					y1='52'
					x2='25'
					y2='52'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='59'
					y1='40'
					x2='21'
					y2='40'
				/>
			</g>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='0'
				y1='59'
				x2='64'
				y2='59'
			/>
		</g>
	</svg>
)
export default IconSVG
