import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x34_5_x5F_global_x5F_services'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M21,41'
				/>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M13,40.999h9l11,4h9c1.656,0,3,1.343,3,3
			s-1.344,3-3,3H31c-1.656,0-3,1.343-3,3'
				/>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M44,49.999L60,45c1.656,0,3,1.343,3,3
			c0,0.997-1,1.999-1,1.999l-25,13h-2l-17-6h-5'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='10'
					y1='54.999'
					x2='8'
					y2='54.999'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='6'
					y1='54.999'
					x2='4'
					y2='54.999'
				/>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M0,38.999h12c0.553,0,1,0.448,1,1.001v18
			c0,0.553-0.447,0.999-1,0.999H0'
				/>
			</g>
			<g>
				<circle
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					cx='38'
					cy='20'
					r='19'
				/>

				<ellipse
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					cx='38'
					cy='20'
					rx='13'
					ry='19'
				/>

				<ellipse
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					cx='38'
					cy='20'
					rx='7'
					ry='19'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='38'
					y1='0.999'
					x2='38'
					y2='38.999'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='52'
					y1='6.999'
					x2='24'
					y2='6.999'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='53'
					y1='31.999'
					x2='23'
					y2='31.999'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='57'
					y1='19.999'
					x2='19'
					y2='19.999'
				/>
			</g>
		</g>
	</svg>
)
export default IconSVG
