import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x32_0_x5F_computer_x5F_monitor'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='58px'
		viewBox='0 0 64 58'
		style={{ enableBackground: 'new 0 0 64 58' }}
	>
		<g>
			<g>
				<g>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M63,4c0-1.657-1.344-3-3-3H4
				C2.344,1,1,2.343,1,4v40c0,1.657,1.344,3,3,3h56c1.656,0,3-1.343,3-3V4z'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='38'
						y1='47'
						x2='40'
						y2='57'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='24'
						y1='57'
						x2='26'
						y2='47'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='19'
						y1='57'
						x2='45'
						y2='57'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='31'
						y1='43'
						x2='33'
						y2='43'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='31'
						y1='33'
						x2='33'
						y2='33'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='35'
						y1='33'
						x2='37'
						y2='33'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='39'
						y1='33'
						x2='41'
						y2='33'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='27'
						y1='33'
						x2='29'
						y2='33'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='23'
						y1='33'
						x2='25'
						y2='33'
					/>
					<rect
						x='5'
						y='5'
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						width='54'
						height='34'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
