import React from 'react'

const SearchIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='22.541'
			height='22.541'
			viewBox='0 0 22.541 22.541'
		>
			<path
				dataname='Path 527'
				d='M36.8,106.012H18.774a2.258,2.258,0,0,0-2.258,2.258v18.025a2.258,2.258,0,0,0,2.258,2.258H36.8a2.258,2.258,0,0,0,2.258-2.258V108.27A2.258,2.258,0,0,0,36.8,106.012ZM24.147,123.3H21.382v-8.721h2.764Zm-1.324-9.888a1.635,1.635,0,1,1,1.635-1.635A1.635,1.635,0,0,1,22.823,113.409ZM34.411,117.8v5.5H31.593v-4.7s.146-1.74-1.411-1.8-1.549,1.611-1.549,1.611V123.3H25.8v-8.754h2.836v1.028s.09-1.339,2.96-1.234S34.411,117.8,34.411,117.8Z'
				transform='translate(-16.516 -106.012)'
				fill='currentColor'
			/>
		</svg>
	)
}
SearchIcon.propTypes = {}
export default SearchIcon
