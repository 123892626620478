import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_1_x5F_legal_x5F_documents'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='60px'
		height='64px'
		viewBox='0 0 60 64'
		style={{ enableBackground: '0 0 60 64' }}
	>
		<g>
			<g>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='59,9 51,9 51,1 		'
				/>
				<polyline
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					points='29,55 17,55 
			17,1 51,1 59,9 59,55 47,55 		'
				/>
				<g>
					<g>
						<polyline
							style={{
								fill: 'none',
								stroke: 'currentColor',
								strokeWidth: 2,
								strokeLinejoin: 'round',
								strokeMiterlimit: 10,
							}}
							points='44,51 44,63 
					38,58 32,63 32,51 				'
						/>
					</g>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M51,38c0-1.455-1.035-2.666-2.41-2.94
				c-0.244-0.884-0.593-1.722-1.038-2.501C47.831,32.104,48,31.573,48,31c0-1.656-1.344-3-3-3c-0.573,0-1.104,0.169-1.559,0.448
				c-0.779-0.445-1.617-0.794-2.501-1.038C40.666,26.035,39.455,25,38,25c-1.455,0-2.666,1.035-2.94,2.41
				c-0.884,0.244-1.722,0.593-2.501,1.038C32.103,28.169,31.573,28,31,28c-1.656,0-3,1.344-3,3c0,0.573,0.169,1.104,0.448,1.559
				c-0.445,0.779-0.794,1.617-1.038,2.501C26.035,35.334,25,36.545,25,38s1.035,2.666,2.41,2.94
				c0.244,0.884,0.593,1.722,1.038,2.501C28.169,43.896,28,44.427,28,45c0,1.656,1.344,3,3,3c0.573,0,1.103-0.169,1.559-0.448
				c0.779,0.445,1.617,0.794,2.501,1.038C35.334,49.965,36.545,51,38,51c1.455,0,2.666-1.035,2.94-2.41
				c0.884-0.244,1.722-0.593,2.501-1.038C43.896,47.831,44.427,48,45,48c1.656,0,3-1.344,3-3c0-0.573-0.169-1.104-0.448-1.559
				c0.445-0.779,0.794-1.617,1.038-2.501C49.965,40.666,51,39.455,51,38z'
					/>
					<circle
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						cx='38'
						cy='38'
						r='6'
					/>
				</g>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='5'
					y1='55'
					x2='11'
					y2='55'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='5'
					y1='39'
					x2='11'
					y2='39'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='5'
					y1='6'
					x2='11'
					y2='6'
				/>
				<path
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					d='M11,4c0-1.656-1.344-3-3-3
			C6.344,1,5,2.344,5,4v54l3,5l3-5V4z'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='22'
					y1='6'
					x2='34'
					y2='6'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='22'
					y1='13'
					x2='54'
					y2='13'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='22'
					y1='17'
					x2='54'
					y2='17'
				/>

				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='22'
					y1='21'
					x2='54'
					y2='21'
				/>
				<polyline
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					points='1,29 1,10 11,10 
					'
				/>
			</g>
		</g>
	</svg>
)
export default IconSVG
