import React from 'react'
import styled from 'styled-components'

const Wrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  svg {
    opacity: 0.30;
  }
`

const BG = ({ className, ...props }) => <Wrap className={`bg ${className || ''}`} {...props} />

export default BG