import React from 'react'

const SearchIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='22.541'
			height='18.283'
			viewBox='0 0 22.541 18.283'
		>
			<defs>
				<clipPath id='twit-clip-path'>
					<rect width='22.541' height='18.283' fill='none' />
				</clipPath>
			</defs>
			<g id='Twitter' clipPath='url(#twit-clip-path)'>
				<path
					dataname='Path 2'
					d='M45.138,20.283A13.032,13.032,0,0,0,58.287,7.134V6.508a10.179,10.179,0,0,0,2.254-2.379,10.4,10.4,0,0,1-2.63.751,4.872,4.872,0,0,0,2-2.5A11.486,11.486,0,0,1,57.035,3.5,4.472,4.472,0,0,0,53.653,2,4.7,4.7,0,0,0,49.02,6.633a2.441,2.441,0,0,0,.125,1,12.947,12.947,0,0,1-9.517-4.884A4.8,4.8,0,0,0,39,5.131a4.975,4.975,0,0,0,2,3.882,4.222,4.222,0,0,1-2.129-.626h0a4.577,4.577,0,0,0,3.757,4.508,3.861,3.861,0,0,1-1.252.125,2.131,2.131,0,0,1-.877-.125,4.744,4.744,0,0,0,4.383,3.256,9.455,9.455,0,0,1-5.76,2A3.467,3.467,0,0,1,38,18.029a11.82,11.82,0,0,0,7.138,2.254'
					transform='translate(-38 -2)'
					fill='currentColor'
					fillRule='evenodd'
				/>
			</g>
		</svg>
	)
}
SearchIcon.propTypes = {}
export default SearchIcon
