import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_3_x5F_accounts_x5F_storage'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<g>
					<rect
						x='1'
						y='37'
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						width='62'
						height='26'
					/>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='55,17 57,17 63,37 			'
					/>
					<polyline
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='1,37 7,17 9,17 			'
					/>
					<g>
						<g>
							<polyline
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								points='14,14 14,5 24,5 30,1 50,1 50,14 
											'
							/>
						</g>
						<g>
							<polyline
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								points='12,21 12,17 52,17 52,21 					'
							/>
						</g>
						<g>
							<polyline
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								points='10,27 10,24 54,24 54,27 					'
							/>
						</g>
						<g>
							<polyline
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								points='8,34 8,30 56,30 56,34 					'
							/>
						</g>
					</g>
					<g>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M46,46v3v2c0,1.656-1.343,3-3,3H21
					c-1.656,0-3-1.343-3-3v-5H46z'
						/>
					</g>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='13'
						y1='50'
						x2='11'
						y2='50'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='53'
						y1='50'
						x2='51'
						y2='50'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='23'
						y1='50'
						x2='41'
						y2='50'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='37'
						y1='7'
						x2='47'
						y2='7'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='17'
						y1='11'
						x2='47'
						y2='11'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
