import React from 'react'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'

import { P, H1, H2, H3, H4, H5, H6, Strong, HR } from '../Typography'

const RT = styled.div`
	p {
		margin: 0;
		padding: 0.5em 0;
	}
	a {
		border-color: inherit;
		&:hover {
			border-color: transparent;
		}
	}
	b {
		font-weight: 700;
	}
	h2 {
		font-size: 1.5rem;
		font-weight: 700;
		${props => props.theme.media.sdesk} {
			font-size: 1.7rem;
			font-weight: 600;
		}
	}
	h3 {
		font-size: 1.2rem;
		${props => props.theme.media.sdesk} {
			font-size: 1.36rem;
		}
	}
	img {
		max-width: 100%;
	}
	blockquote {
		/* font-weight: bold; */
		color: ${props => props.theme.colors.grey.light(0).val};
		& > * {
			color: inherit;
			display: inline-block;
		}
		&:before {
			content: '“';
		}
		&:after {
			content: '”';
		}
	}
`

const options = {
	renderNode: {
		[BLOCKS.PARAGRAPH]: (_, children) => <P>{children}</P>,
		[BLOCKS.HEADING_1]: (_, children) => <H1>{children}</H1>,
		[BLOCKS.HEADING_2]: (_, children) => <H2>{children}</H2>,
		[BLOCKS.HEADING_3]: (_, children) => <H3>{children}</H3>,
		[BLOCKS.HEADING_4]: (_, children) => <H4>{children}</H4>,
		[BLOCKS.HEADING_5]: (_, children) => <H5>{children}</H5>,
		[BLOCKS.HEADING_6]: (_, children) => <H6>{children}</H6>,
		[BLOCKS.HR]: () => <HR />,
		[MARKS.BOLD]: (_, children) => <Strong>{children}</Strong>,
		[BLOCKS.EMBEDDED_ASSET]: node => {
			let url = node.data?.target?.fields?.file
			if (url) url = url['en-US']?.url
			let alt = node.data?.target?.fields?.description
			if (alt) alt = alt['en-US']

			return url ? <img src={url} alt={alt} /> : null
		},
	},
}

const RichText = ({ content, children: html, className }) => {
	const contentFulRTContent = get(content, 'json')
	let rtContent
	if (contentFulRTContent) rtContent = documentToReactComponents(contentFulRTContent, options)

	return html ? (
		<RT
			className={`rt${className ? ` ${className}` : ''}`}
			dangerouslySetInnerHTML={{ __html: html }}
		/>
	) : contentFulRTContent ? (
		<RT className={`rt${className ? ` ${className}` : ''}`}>{rtContent}</RT>
	) : null
}
RichText.propTypes = {
	children: PropTypes.string,
	content: PropTypes.shape({
		json: PropTypes.shape({}),
	}),
}
export default RichText
