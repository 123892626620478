import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_2_x5F_goal_x5F_target'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M60.992,21.002C62.29,24.42,63,28.127,63,32
			c0,17.121-13.879,31-31,31C14.879,63,1,49.121,1,32S14.879,1,32,1c3.872,0,7.578,0.71,10.995,2.006'
				/>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='32,32 54,10 63,1 		'
				/>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='64,7 57,7 57,0 		'
				/>
				<polyline
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					points='60,11 53,11 53,4 		'
				/>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='32'
					cy='32'
					r='25'
				/>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='32'
					cy='32'
					r='19'
				/>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='32'
					cy='32'
					r='13'
				/>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='32'
					cy='32'
					r='7'
				/>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='32'
					cy='32'
					r='1'
				/>
				<polyline
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					points='12,56 5,63 1,63 
			7,51 		'
				/>
				<polyline
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					points='52,56 59,63 
			63,63 57,51 		'
				/>
			</g>
		</g>
	</svg>
)
export default IconSVG
