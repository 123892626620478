import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x34_6_x5F_launch_x5F_optimization'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='60px'
		viewBox='0 0 64 60'
		style={{ enableBackground: 'new 0 0 64 60' }}
	>
		<g>
			<polyline
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				points='25,9 25,6 22,6 
		22,1 32,1 32,6 29,6 29,9 	'
			/>
			<g>
				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='6'
					y1='13'
					x2='9'
					y2='16'
				/>

				<rect
					x='1.672'
					y='9.379'
					transform='matrix(-0.7071 0.7071 -0.7071 -0.7071 15.8137 16.4497)'
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					width='5.657'
					height='4.243'
				/>
			</g>
			<g>
				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='48'
					y1='13'
					x2='45'
					y2='16'
				/>

				<rect
					x='46.672'
					y='9.379'
					transform='matrix(0.7071 0.7071 -0.7071 0.7071 22.6299 -31.6335)'
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					width='5.657'
					height='4.243'
				/>
			</g>
			<path
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				d='M51.007,41.001C47.979,51.4,38.377,59,27,59
		C13.193,59,2,47.808,2,34C2,20.193,13.193,9,27,9c11.377,0,20.979,7.601,24.007,18.002'
			/>
			<g>
				<line
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					x1='27'
					y1='34'
					x2='59'
					y2='34'
				/>
				<polygon
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					points='63,38 54,38 
			50,34 54,30 63,30 59,34 		'
				/>
			</g>
			<circle
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='27'
				cy='34'
				r='21'
			/>
			<circle
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='27'
				cy='34'
				r='1'
			/>
			<circle
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				cx='27'
				cy='34'
				r='2'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='27'
				y1='16'
				x2='27'
				y2='18'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='27'
				y1='50'
				x2='27'
				y2='52'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='10'
				y1='33'
				x2='10'
				y2='35'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='38'
				y1='22'
				x2='40'
				y2='22'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='14'
				y1='22'
				x2='16'
				y2='22'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='38'
				y1='46'
				x2='40'
				y2='46'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='14'
				y1='46'
				x2='16'
				y2='46'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='55'
				y1='41'
				x2='55'
				y2='43'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='55'
				y1='45'
				x2='55'
				y2='47'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='55'
				y1='21'
				x2='55'
				y2='23'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='55'
				y1='25'
				x2='55'
				y2='27'
			/>
		</g>
	</svg>
)
export default IconSVG
