import isDate from 'lodash/isDate'
import isPlainObject from 'lodash/isPlainObject'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import trim from 'lodash/trim'
import toLower from 'lodash/toLower'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import includes from 'lodash/includes'
import replace from 'lodash/replace'
// import parseISO from 'date-fns/parseISO'
// import formatISO from 'date-fns/formatISO'
import addDays from 'date-fns/addDays'
import subDays from 'date-fns/subDays'
import formatDate from 'date-fns/format'

export { default as useState } from './useState'
export { default as useScript } from './useScript'

export const getDateFormatted = (date, formatting, options = {}) => {
	if (isPlainObject(formatting)) {
		// eslint-disable-next-line
		options = formatting
		// eslint-disable-next-line
		formatting = null
	}

	const { days, defaultTxt } = options

	const emptyTxt = defaultTxt || 'unknown date'

	if (!date || date === 'unknown') return emptyTxt

	try {
		let dateParsed = new Date(date)
		if (!isDate(date)) {
			// dateParsed = parseISO(date)
			// console.log('parsed ISO:', dateParsed)
			// dateParsed = formatISO(dateParsed)
			// console.log('formatted ISO: ', dateParsed)
		}

		if (isNumber(days)) {
			if (days > 0) dateParsed = addDays(dateParsed, days)
			if (days < 0) dateParsed = subDays(dateParsed, days)
		}

		const formattedDate = formatDate(dateParsed, formatting || 'MMM do, yyyy') || emptyTxt
		return formattedDate
	} catch (err) {
		console.error(err)
		return isString(date) ? date : emptyTxt
	}
}

export const isExternalLink = link => (link ? link.substring(0, 4) === 'http' : false)
export const isInternalLink = link => (link ? link.substring(0, 1) === '/' : false)
export const isAnchorLink = link => (link ? link.substring(0, 1) === '#' : false)

export const truncText = (str, opts = {}) => {
	const { length = 255, split = false } = opts
	// console.log('truncText - beginning with str', str, ' and length:', length)
	if (str.length <= length) return str

	let truncedStr = `${str.slice(0, length)}...`

	if (split) {
		const splitLength = (length - 3) / 2
		truncedStr = `${str.substring(0, splitLength)}...${str.substring(str.length - splitLength)}`
	}

	return truncedStr
}

export const parseTags = tags => (tags ? tags.map(tag => toLower(trim(tag))) : tags)

export const parsePageGroups = page => {
	const uriArry = []
	const getSlug = p => {
		const group = p ? p.group || p.group2 || p.pagegroup2 || p.group1 || p.pagegroup : null
		uriArry.push({
			slug: get(p, 'slug', 'no-slug-found'),
			title: get(p, 'breadcrumb', get(p, 'title', 'no-title-found')),
			id: get(p, 'id', get(p, 'contentful_id', 'no-id-found')),
		})
		if (group && group.length) {
			getSlug(group[0])
		}
	}
	getSlug(page)
	return uriArry
}

export const parsePageURI = page => {
	const uriArray = isArray(page)
		? page.map(p => (get(p, 'slug') ? p.slug : p))
		: parsePageGroups(page).map(({ slug }) => slug)
	return `/${uriArray.reverse().join('/')}`
}

export const isProxy = str => includes(toLower(str), 'proxy')

export const parseQueryParams = search => {
	const queryString = replace(search, '?', '')

	if (queryString.length)
		return queryString.split('&').reduce((obj, curr) => {
			const [query, param] = curr.split('=')
			// eslint-disable-next-line no-param-reassign
			obj[query] = param
			return obj
		}, {})

	return {}
}

export const socIconFromLink = url =>
	includes(toLower(url), 'facebook')
		? 'facebook'
		: includes(toLower(url), 'instagram')
		? 'instagram'
		: includes(toLower(url), 'twitter')
		? 'twitter'
		: includes(toLower(url), 'linkedin')
		? 'linkedin'
		: null

export const createBreadCrumbSchema = (breadcrumbs, siteUrl = 'https://acilearning/') =>
	breadcrumbs
		? breadcrumbs.map(({ title }, idx) => ({
				'@type': 'BreadcrumbList',
				'itemListElement': [
					{
						'@type': 'ListItem',
						'position': idx + 1,
						'item': {
							'name': title,
							'@id': `${siteUrl}/${breadcrumbs
								.slice(0, idx + 1)
								.map(({ slug }) => slug)
								.join('/')}`,
						},
					},
				],
		  }))
		: []
