import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x33_6_x5F_secure_x5F_data_x5F_folder'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='60px'
		viewBox='0 0 64 60'
		style={{ enableBackground: 'new 0 0 64 60' }}
	>
		<g>
			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='34'
				y1='53'
				x2='4'
				y2='53'
			/>
			<path
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				d='M59,8V5H23l-4-4H1v49
		c0,1.657,1.343,3,3,3c1.657,0,3-1.343,3-3V11h56v42h-5'
			/>
			<g>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M55,57c0,1.1-0.9,2-2,2H39c-1.1,0-2-0.9-2-2V45
			c0-1.1,0.9-2,2-2h14c1.1,0,2,0.9,2,2V57z'
				/>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M39,43v-4c0-3.3,2.7-6,6-6h2c3.3,0,6,2.7,6,6v4'
				/>
				<circle
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					cx='46'
					cy='49'
					r='1'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='46'
					y1='50'
					x2='46'
					y2='55'
				/>
			</g>

			<rect
				x='13'
				y='17'
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				width='44'
				height='8'
			/>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='16'
				y1='21'
				x2='18'
				y2='21'
			/>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='20'
				y1='21'
				x2='22'
				y2='21'
			/>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='24'
				y1='21'
				x2='26'
				y2='21'
			/>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='28'
				y1='21'
				x2='30'
				y2='21'
			/>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='32'
				y1='21'
				x2='34'
				y2='21'
			/>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='36'
				y1='21'
				x2='38'
				y2='21'
			/>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='40'
				y1='21'
				x2='42'
				y2='21'
			/>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='12'
				y1='31'
				x2='30'
				y2='31'
			/>

			<line
				style={{
					fill: 'none',
					stroke: 'currentColor',
					strokeWidth: 2,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
				x1='12'
				y1='35'
				x2='30'
				y2='35'
			/>
		</g>
	</svg>
)
export default IconSVG
