import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_6_x5F_user_x5F_profile'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<g>
					<g>
						<g>
							<circle
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								cx='32'
								cy='32'
								r='31'
							/>
						</g>
					</g>
					<g>
						<g>
							<g>
								<line
									style={{
										fill: 'none',
										stroke: 'currentColor',
										strokeWidth: 2,
										strokeMiterlimit: 10,
									}}
									x1='26'
									y1='44'
									x2='26'
									y2='38'
								/>
								<line
									style={{
										fill: 'none',
										stroke: 'currentColor',
										strokeWidth: 2,
										strokeMiterlimit: 10,
									}}
									x1='38'
									y1='44'
									x2='38'
									y2='38'
								/>
								<path
									style={{
										fill: 'none',
										stroke: 'currentColor',
										strokeWidth: 2,
										strokeMiterlimit: 10,
									}}
									d='M43,24h-1c-1.104,0-2-0.896-2-2v-2
							c0-1.104-0.896-2-2-2v-2l-4,2h-8c-1.104,0-2,0.896-2,2v2c0,1.104-0.896,2-2,2'
								/>
								<line
									style={{
										fill: 'none',
										stroke: 'currentColor',
										strokeWidth: 2,
										strokeMiterlimit: 10,
									}}
									x1='22'
									y1='24'
									x2='21'
									y2='24'
								/>
								<path
									style={{
										fill: 'none',
										stroke: 'currentColor',
										strokeWidth: 2,
										strokeMiterlimit: 10,
									}}
									d='M21,16v11l1,1c0,5,3,12,10,12s10-7,10-12l1-1
							V14c0-2.209-1.791-4-4-4H28c-1.656,0-3,1.344-3,3h-1C22.344,13,21,14.344,21,16z'
								/>
								<polygon
									style={{
										fill: 'none',
										stroke: 'currentColor',
										strokeWidth: 2,
										strokeMiterlimit: 10,
									}}
									points='28,52 19,48 24,44 32,46 32,48 
													'
								/>
								<polygon
									style={{
										fill: 'none',
										stroke: 'currentColor',
										strokeWidth: 2,
										strokeMiterlimit: 10,
									}}
									points='36,52 45,48 40,44 32,46 32,48 
													'
								/>
								<line
									style={{
										fill: 'none',
										stroke: 'currentColor',
										strokeWidth: 2,
										strokeMiterlimit: 10,
									}}
									x1='32'
									y1='57'
									x2='32'
									y2='59'
								/>
								<line
									style={{
										fill: 'none',
										stroke: 'currentColor',
										strokeWidth: 2,
										strokeMiterlimit: 10,
									}}
									x1='32'
									y1='53'
									x2='32'
									y2='55'
								/>
								<path
									style={{
										fill: 'none',
										stroke: 'currentColor',
										strokeWidth: 2,
										strokeMiterlimit: 10,
									}}
									d='M19,48l-3,1c-6,2-7,4-7,4'
								/>
								<path
									style={{
										fill: 'none',
										stroke: 'currentColor',
										strokeWidth: 2,
										strokeMiterlimit: 10,
									}}
									d='M45,48l3,1c6,2,7,4,7,4'
								/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
