import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x33_1_x5F_salesman_x5F_avatar'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='58px'
		height='64px'
		viewBox='0 0 58 64'
		style={{ enableBackground: 'new 0 0 58 64' }}
	>
		<g>
			<g>
				<g>
					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='13'
						y1='56'
						x2='13'
						y2='64'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='45'
						y1='56'
						x2='45'
						y2='64'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='23'
						y1='34'
						x2='23'
						y2='29'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='35'
						y1='34'
						x2='35'
						y2='29'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M18,15V7
				c0-1.656,1.344-3,3-3h1c0-1.656,1.344-3,3-3h11c2.209,0,4,1.791,4,4v10h-1c-1.105,0-2-0.896-2-2v-2c0-1.104-0.896-2-2-2V7l-4,2
				h-8c-1.105,0-2,0.896-2,2v2c0,1.104-0.896,2-2,2H18z'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M40,15v3l-1,1
				c0,5-3,12-10,12s-10-7-10-12l-1-1v-3'
					/>
					<polygon
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='25,42 16,37 
				21,34 29,36 29,38 			'
					/>
					<polygon
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='33,42 42,37 
				37,34 29,36 29,38 			'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='29'
						y1='48'
						x2='29'
						y2='50'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='29'
						y1='44'
						x2='29'
						y2='46'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='29'
						y1='52'
						x2='29'
						y2='54'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='29'
						y1='56'
						x2='29'
						y2='58'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M41,38l5,3
				c6,2,11,4.925,11,11v12'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M17,38l-5,3
				C6,43,1,45.925,1,52v12'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
