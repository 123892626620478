import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_2_x5F_venture_x5F_capital'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M21,41'
				/>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M13,41h9l11,4h9c1.656,0,3,1.343,3,3
			s-1.344,3-3,3H31c-1.656,0-3,1.343-3,3'
				/>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M44,50l16-5c1.656,0,3,1.343,3,3c0,0.997-1,2-1,2
			L37,63h-2l-17-6h-4'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='10'
					y1='55'
					x2='8'
					y2='55'
				/>
				<line
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					x1='6'
					y1='55'
					x2='4'
					y2='55'
				/>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M0,39h12c0.553,0,1,0.447,1,1v18
			c0,0.553-0.447,1-1,1H0'
				/>
				<g>
					<path
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						d='M34,33c0,1.656,1.344,3,3,3c1.656,0,3-1.344,3-3
				c0-1.656-3-3-3-3s-3-1.344-3-3c0-1.656,1.344-3,3-3c1.656,0,3,1.344,3,3'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='37'
						y1='24'
						x2='37'
						y2='21'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='37'
						y1='36'
						x2='37'
						y2='39'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='43'
						y1='30'
						x2='45'
						y2='30'
					/>
					<line
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						x1='29'
						y1='30'
						x2='31'
						y2='30'
					/>
				</g>
				<polygon
					style={{
						fill: 'none',
						stroke: 'currentColor',
						strokeWidth: 2,
						strokeLinejoin: 'round',
						strokeMiterlimit: 10,
					}}
					points='45,3 41,1 37,3 
			33,1 29,3 33,11 41,11 		'
				/>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M41,15c3.313,0,14,4,14,21v8'
				/>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M19,38v-2c0-18,10.687-21,14-21'
				/>
				<path
					style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
					d='M43,13c0,1.104-0.896,2-2,2h-8
			c-1.104,0-2-0.896-2-2l0,0c0-1.104,0.896-2,2-2h8C42.104,11,43,11.896,43,13L43,13z'
				/>
			</g>
		</g>
	</svg>
)
export default IconSVG
