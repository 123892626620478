import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x30_1_x5F_idea_x5F_generation'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='64px'
		viewBox='0 0 64 64'
		style={{ enableBackground: 'new 0 0 64 64' }}
	>
		<g>
			<g id='_x30_1.idea_x5F_geneartion_1_'>
				<g>
					<g>
						<circle
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							cx='32'
							cy='26'
							r='9'
						/>
						<g>
							<g>
								<line
									style={{
										fill: 'none',
										stroke: 'currentColor',
										strokeWidth: 2,
										strokeMiterlimit: 10,
									}}
									x1='22'
									y1='44'
									x2='42'
									y2='44'
								/>
								<line
									style={{
										fill: 'none',
										stroke: 'currentColor',
										strokeWidth: 2,
										strokeMiterlimit: 10,
									}}
									x1='22'
									y1='48'
									x2='42'
									y2='48'
								/>
								<polyline
									style={{
										fill: 'none',
										stroke: 'currentColor',
										strokeWidth: 2,
										strokeMiterlimit: 10,
									}}
									points='22,52 28,52 30,55 34,55 36,52 
							42,52 						'
								/>
								<path
									style={{
										fill: 'none',
										stroke: 'currentColor',
										strokeWidth: 2,
										strokeMiterlimit: 10,
									}}
									d='M39,52V39c5-2,8-7.101,8-13
							c0-8.284-6.716-15-15-15s-15,6.716-15,15c0,5.965,3,11,8,13v13'
								/>
							</g>
							<polyline
								style={{
									fill: 'none',
									stroke: 'currentColor',
									strokeWidth: 2,
									strokeMiterlimit: 10,
								}}
								points='20,26 26,26 30,22 34,30 38,26 
						44,26 					'
							/>
						</g>
					</g>
					<g>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M27,6l5-5C18.753,1,7.446,9.309,3.008,21'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M58,27l5,5
					c0-13.248-8.311-24.555-20.003-28.993'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M37,58l-5,5c13.247,0,24.554-8.309,28.992-20'
						/>
						<path
							style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
							d='M6,37l-5-5
					c0,13.246,8.308,24.552,19.997,28.991'
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
