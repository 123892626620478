import { action } from 'easy-peasy'

const View = {
	navPane: null,
	isMobileNavOpen: false,
	isLoginMenuOpen: false,
	hasGlobalNav: false,

	updateNavPane: action((state, paneId) => {
		state.navPane = paneId
	}),
	toggleMobileNav: action((state, override) => {
		state.isMobileNavOpen = typeof override === 'boolean' ? override : !state.isMobileNavOpen
	}),
	toggleLoginMenu: action((state, override) => {
		state.isLoginMenuOpen = typeof override === 'boolean' ? override : !state.isLoginMenuOpen
	}),
	toggleGlobalNav: action((state, override) => {
		state.hasGlobalNav = typeof override === 'boolean' ? override : !state.hasGlobalNav
	}),
}

const Meta = {
	searchIndex: null,
	courseTags: null,
	topics: null,
	currentTopic: null,
	blogTags: null,
	blogSearchStr: null,
	newsSearchStr: null,
	currentBlogTags: [],
	jobSearchStr: null,

	updateSearchIndex: action((state, searchIndex) => {
		if (state.searchIndex) return
		state.searchIndex = searchIndex
	}),

	updateCourseTags: action((state, tags) => {
		if (state.courseTags) return
		state.courseTags = tags
	}),
	updateCourseTopics: action((state, topics) => {
		if (state.topics) return
		state.topics = topics
	}),
	updateCurrentCourseTopic: action((state, topic) => {
		if (!topic && state.currentTopic) state.currentTopic = null
		else if (topic) state.currentTopic = topic
	}),

	updateBlogTags: action((state, tags) => {
		if (state.blogTags) return
		state.blogTags = tags
	}),
	addCurrentBlogTag: action((state, tag) => {
		if (tag) state.currentBlogTags.push(tag)
	}),
	removeCurrentBlogTag: action((state, tag) => {
		if (!tag) return
		const idx = state.currentBlogTags.indexOf(tag)
		if (idx > -1) {
			const tags = state.currentBlogTags
			tags.splice(idx, 1)
			state.currentBlogTags = tags
		}
	}),
	clearCurrentBlogTags: action(state => {
		if (state.currentBlogTags.length > 0) state.currentBlogTags = []
	}),

	updateBlogSearchStr: action((state, str) => {
		if (str && str.length > 2) {
			state.blogSearchStr = str
		} else if (str.length === 0 && state.blogSearchStr) state.blogSearchStr = null
	}),

	updateNewsSearchStr: action((state, str) => {
		if (str && str.length > 2) {
			state.newsSearchStr = str
		} else if (str.length === 0 && state.newsSearchStr) state.newsSearchStr = null
	}),

	updateJobSearchStr: action((state, str) => {
		if (str && str.length > 2) {
			state.jobSearchStr = str
		} else if (str.length === 0 && state.jobSearchStr) state.jobSearchStr = null
	}),
}

export default {
	View,
	Meta,
}
