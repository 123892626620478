import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x33_1_x5F_operator_x5F_avatar'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='58px'
		height='64px'
		viewBox='0 0 58 64'
		style={{ enableBackground: 'new 0 0 58 64' }}
	>
		<g>
			<g>
				<g>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M35,28v5c0,1.656,3,3,3,3
				l8,4c6,2,11,4.925,11,11v13'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M23,28v5c0,1.656-3,3-3,3
				l-8,4C6,42,1,44.925,1,51v13'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='11'
						y1='56'
						x2='11'
						y2='64'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='47'
						y1='56'
						x2='47'
						y2='64'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M42,11
				c0-5.523-4.477-10-10-10v2l-6-2c-5.523,0-10,4.477-10,10'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M20,14v3
				c0,7.18,3,14,9,14s9-6.82,9-14v-3c0-1.656-1.344-3-3-3h-6l-6-3v3C21.344,11,20,12.344,20,14z'
					/>
					<polyline
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						points='38,36 35,36 
				31,40 27,40 23,36 20,36 			'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='29'
						y1='51'
						x2='29'
						y2='53'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='29'
						y1='47'
						x2='29'
						y2='49'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='29'
						y1='43'
						x2='29'
						y2='45'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='29'
						y1='55'
						x2='29'
						y2='57'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='29'
						y1='59'
						x2='29'
						y2='61'
					/>

					<line
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						x1='34'
						y1='52'
						x2='42'
						y2='52'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M19,23
				c-3.314,0-6-2.687-6-6s2.686-6,6-6'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M39,23
				c3.313,0,6-2.687,6-6s-2.687-6-6-6'
					/>
					<path
						style={{
							fill: 'none',
							stroke: 'currentColor',
							strokeWidth: 2,
							strokeLinejoin: 'round',
							strokeMiterlimit: 10,
						}}
						d='M43,22v3
				c0,1.104-0.896,2-2,2H30c-1.105,0-2-0.896-2-2'
					/>
				</g>
			</g>
		</g>
	</svg>
)
export default IconSVG
