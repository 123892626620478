import { createGlobalStyle } from 'styled-components'
import slickStyles from 'slick-carousel/slick/slick.css'
// import slickTheme from 'slick-carousel/slick/slick-theme.css'
import fonts from '../fonts'

const globalStyles = createGlobalStyle`
  ${fonts}
  ${slickStyles}
  
  html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: 'Fira Sans, Helvetica, Arial, sans-serif';
    height: 100%;
  }
  body {
    color: #293847;
    &.no-scroll {
      margin: 0;
      height: 100%;
      overflow: hidden;
    }
  }
  body,h1,h2,h3,h4,h6 {
    line-height: 1.2em;
  }
  .cta {
    
  }
  a {
    text-decoration: none;
    color: inherit;
    border-bottom: solid 1px #fff;
    &:hover {
      color: inherit;
      border-bottom: solid 1px transparent;
    }
  }
  .flex {
      display: flex;
      &.center {
        align-items: center;
        justify-content: center;
      }
    }
    .flex-tablet {
      ${props => props.theme.media.tablet} {
        display: flex;
        &.center {
          align-items: center;
          justify-content: center;
        }
      }
    }
    .flex-desk {
      ${props => props.theme.media.sdesk} {
        display: flex;
        &.center {
          align-items: center;
          justify-content: center;
        }
      }
    }
`

export default globalStyles
