module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ACI","short_name":"ACI","start_url":"/","background_color":"#ADE2F4","theme_color":"#ADE2F4","display":"minimal-ui","icon":"src/images/aci-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"13e2d1d83de349e1604e059931719ce9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5FRCWPG","routeChangeEventName":"aciLearning-page-change","includeInDevelopment":false,"defaultDataLayer":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
