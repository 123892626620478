import React from 'react'
const IconSVG = ({ props }) => (
	<svg
		version='1.1'
		id='_x35_5_x5F_ebanking_x5F_security'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		width='64px'
		height='60px'
		viewBox='0 0 64 60'
		style={{ enableBackground: 'new 0 0 64 60' }}
	>
		<g>
			<g>
				<g>
					<polygon
						style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
						points='59,59 5,59 1,55 1,53 25,53 27,55 
				37,55 39,53 63,53 63,55 			'
					/>
				</g>
			</g>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='57'
				y1='24'
				x2='57'
				y2='50'
			/>
			<polyline
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				points='12,21 5,21 5,15 59,15 59,21 52,21 	'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='7'
				y1='50'
				x2='7'
				y2='24'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='14'
				y1='21'
				x2='50'
				y2='21'
			/>
			<polyline
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				points='11,50 11,25 15,25 15,50 	'
			/>
			<polyline
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				points='45,50 45,25 19,25 19,50 	'
			/>
			<polyline
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				points='49,50 49,25 53,25 53,50 	'
			/>
			<polyline
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				points='55,15 55,11 35,1 29,1 9,11 9,15 	'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='9'
				y1='11'
				x2='55'
				y2='11'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='33'
				y1='7'
				x2='31'
				y2='7'
			/>
			<rect
				x='27'
				y='38'
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				width='10'
				height='7'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='35'
				y1='7'
				x2='37'
				y2='7'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='27'
				y1='7'
				x2='29'
				y2='7'
			/>
			<path
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				d='M35,38v-4c0-1.657-1.343-3-3-3
		c-1.657,0-3,1.343-3,3v4'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='23'
				y1='30'
				x2='23'
				y2='28'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='41'
				y1='30'
				x2='41'
				y2='28'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='23'
				y1='50'
				x2='23'
				y2='48'
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: 2, strokeMiterlimit: 10 }}
				x1='41'
				y1='50'
				x2='41'
				y2='48'
			/>
		</g>
	</svg>
)
export default IconSVG
